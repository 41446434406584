import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import { FlexCenteredCol, FlexCenteredRow, FlexCol } from './Layout';

export const WrapperModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    flex-direction: column;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
  }
`;

export const InnerModal = styled(FlexCenteredCol)`
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 22px 16px 4px;
  overflow: scroll;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    width: 100%;
    height: 100%;
    margin: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    border-radius: 16px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    max-height: 800px;
    border-radius: 16px;
  }
`;

export const CustomRoomModalWrapper = styled(FlexCenteredRow)`
  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    flex-direction: column;
    height: 100%;
    scroll: auto;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    border-radius: 12px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    width: 80vw;
    height: 88vh;
    max-height: 800px;
    border-radius: 12px;
  }
`;

export const CustomRoomInnerModal = styled(FlexCol)`
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 22px 16px 4px;
  overflow: scroll;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    width: 100%;
    height: 100%;
    margin: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    height: 80vh;
    border-radius: 12px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    height: 90vh;
    max-width: 640px;
    min-height: 840px;
    max-height: 840px;
    border-radius: 12px;
  }
`;

export const CustomRoomDetailModal = styled(FlexCol)`
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 22px 16px 4px;
  overflow: scroll;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    width: 100%;
    height: 100%;
    margin: 0;
    display: none;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    min-width: 344px;
    max-width: 640px;
    min-height: 80vh;
    max-height: 80vh;
    border-radius: 12px;
    margin: 0 0 0 8px;
    padding: 0 8px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    height: 90vh;
    min-width: 344px;
    max-width: 640px;
    min-height: 840px;
    max-height: 840px;
    border-radius: 12px;
    margin: 0 0 0 8px;
    padding: 0 8px;
  }
`;
