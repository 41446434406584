import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormControl,
  Snackbar,
  TextField,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import {
  ComponentContainer,
  Flex,
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
  WrapperContainer,
} from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import {
  CREATE_OR_UPDATE_PG_CONFIG,
  UPDATE_ACCOMMODATION,
  USER_UPDATE_INFO,
} from '../../graphql/mutation';
import {
  GET_ACCOMMODATION_PG_CONFIG,
  GET_SINGLE_ACCOMMODATION,
} from '../../graphql/query';
import { deserialize1024, deserialize512 } from '../../utils/utils';
import { User } from 'typedefs/gql';
import authHandler from 'helpers/authHandler';
import { wait } from 'helpers';

const MediaFlex = styled(Flex)`
  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin: 16px 0;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    flex-wrap: wrap;
    flex-direction: column;
    width: 700px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

const MediaFormControl = styled(FormControl)`
  @media screen and (max-width: 768px) {
    /* Desktop Style Sheet */
    width: auto;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    width: 636px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    width: 440px;
  }

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 8px;
  background-color: #eee;
`;

const MediaTextareaAutosize = styled(TextareaAutosize)`
  font-family: 'Consolas, sans-serif';
  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    min-width: 320px;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    width: 636px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    width: 434px;
  }
`;

const EditForm = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // 간격 8px
  & > * {
    margin-right: 8px;
  }
  & > div > * {
    margin-right: 8px;
  }
`;

const Accommodation = () => {
  const { id: accommodationId } = useParams<{ id: string }>();

  const [user, setUser] = useState<Pick<User, 'nickname' | 'name'>>({
    nickname: authHandler.user?.nickname,
    name: authHandler.user?.name,
  });

  const { loading, data, error, refetch } = useQuery(GET_SINGLE_ACCOMMODATION, {
    variables: {
      accommodationId: accommodationId,
    },
  });
  const { data: pgConfigData, error: pgConfigError } = useQuery(
    GET_ACCOMMODATION_PG_CONFIG,
    {
      variables: {
        accommodationId: accommodationId,
      },
    },
  );

  const [emitUpdateAccommodation] = useMutation(UPDATE_ACCOMMODATION);
  const [emitCreateOrUpdatePgConfig] = useMutation(CREATE_OR_UPDATE_PG_CONFIG);
  const [emitUserUpdateInfo] = useMutation(USER_UPDATE_INFO);

  const accommodationInit = {
    id: '',
    name: '',
    contact: '',
    csContact: '',
  };

  const accommodationDetails = [
    { key: '지점명', value: 'name' },
    { key: '기본주소', value: 'address1' },
    { key: '상세주소', value: 'address2' },
    { key: '연락처', value: 'contact' },
    { key: '문의용 연락처', value: 'csContact' },
    { key: '사업주 성명', value: 'registererName' },
    { key: '사업자 등록번호', value: 'registrationNumber' },
    { key: '체크인 시간', value: 'defaultCheckIn' },
    { key: '체크아웃 시간', value: 'defaultCheckOut' },
    { key: '지점 암호화 키', value: 'encryptionKey' },
  ];

  const itemStyles = {
    padding: '16px 24px 24px 24px',
    margin: '8px',
    backgroundColor: '#d3d3d8',
    borderRadius: '12px',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
  };

  const [isSnack, setIsSnack] = useState(false);
  const [inputValues, setInputValues] = useState(accommodationInit);
  const [accommodationDefaultConfig, setCompareValues] =
    useState(accommodationInit);
  const [inputName, setInputName] = useState('');
  const [pgConfigInput, setPgConfigInput] = useState({
    isLive: false,
    mbrNo: '',
    apiKey: '',
  });

  useEffect(() => {
    const { data } = pgConfigData?.getAccommodationPgConfig || {};
    let parsedConfig;
    if (data) {
      try {
        parsedConfig = {
          isLive: true,
          ...JSON.parse(data),
        };
      } catch (error) {
        parsedConfig = {
          isLive: false,
          mbrNo: '',
          apiKey: '',
        };
      }

      setPgConfigInput(parsedConfig);
    }
  }, [pgConfigData]);

  const getAccommodationValue = (name: any) => {
    // @ts-ignore
    return inputValues?.[name] || '';
  };

  const handleAccommodationFocus = (name: any) => (e: any) =>
    setInputName(name);

  const handleAccommodationChange = (event: any) => {
    let inputVal: string = event.target.value;

    if (inputName?.match(/contact|registrationNumber/gi)) {
      inputVal = inputVal?.replace(/-/gi, '');
    }

    setInputValues({ ...inputValues, [inputName]: inputVal });
  };

  const handlePgConfigChange = (event: any) => {
    const { name, value } = event.target;

    setPgConfigInput({ ...pgConfigInput, [name]: value });
  };

  const onClickUpdateAccommodation = async (e: any) => {
    let changedAccommodationConfig: any = {
      id: accommodationDefaultConfig?.id,
    };

    Object?.keys(inputValues)?.forEach((element) => {
      if (accommodationDefaultConfig[element] !== inputValues[element]) {
        if (element !== 'cardData') {
          changedAccommodationConfig[element] = inputValues[element];
        }
        if (element !== 'cleanCardData') {
          changedAccommodationConfig[element] = inputValues[element];
        }
      }
    });

    delete changedAccommodationConfig.cardData;

    delete changedAccommodationConfig.cleanCardData;

    const result = await emitUpdateAccommodation({
      variables: {
        input: changedAccommodationConfig,
      },
    });

    if (result) {
      setIsSnack(true);
      await refetch();
    }
  };

  const onSavePgConfigInput = useCallback(async () => {
    const { data } = await emitCreateOrUpdatePgConfig({
      variables: {
        input: {
          accommodationId: accommodationId,
          data: JSON.stringify({
            mbrNo: pgConfigInput.mbrNo,
            apiKey: pgConfigInput.apiKey,
          }),
        },
      },
    });

    if (data) {
      setIsSnack(true);
    }
  }, [pgConfigInput]);

  useEffect(() => {
    const cardData = data?.getSingleAccommodation?.cardData;
    const cleanCardData = data?.getSingleAccommodation?.cleanCardData;

    let deserializeMaster;
    let deserializeClean;

    if (cardData) {
      deserializeMaster = deserialize512?.(cardData);
      if (deserializeMaster?.length === 128) {
        deserializeMaster = deserialize1024(cardData);
      }
    }

    if (cleanCardData) {
      deserializeClean = deserialize512?.(cleanCardData);
      if (deserializeClean?.length === 128) {
        deserializeClean = deserialize1024(cleanCardData);
      }
    }

    setInputValues({
      ...data?.getSingleAccommodation,
      cardData: JSON.stringify(deserializeMaster),
      cleanCardData: JSON.stringify(deserializeClean),
    });
    setCompareValues(data?.getSingleAccommodation);
  }, [data]);

  const onUserEdit = useCallback(
    async (editedUser) => {
      try {
        const userUpdateInfoResult = await emitUserUpdateInfo({
          variables: {
            input: {
              name: editedUser.name,
              nickname: editedUser.nickname,
            },
          },
          onCompleted: () => {
            setUser({
              ...user,
              nickname: editedUser.nickname,
            });
          },
        });

        if (userUpdateInfoResult?.data?.updateInfo) {
          authHandler.user = {
            ...authHandler.user,
            name: userUpdateInfoResult.data.updateInfo?.name,
            nickname: userUpdateInfoResult.data.updateInfo?.nickname,
          };
          await authHandler.refreshAccessToken();
          setIsSnack(true);

          await wait(1000);
          window.location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [emitUserUpdateInfo, user],
  );

  return (
    <WrapperContainer>
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            height: 'inherit',
            overflow: 'scroll',
          }}
        >
          {data?.getSingleAccommodation ? (
            <ComponentContainer style={{ background: 'none' }}>
              <Typography
                variant="h5"
                style={{ width: '100%', margin: '32px 0 16px 16px' }}
              >
                {`${inputValues?.name} 지점 정보`}
              </Typography>
              <MediaFlex>
                <FlexCol style={itemStyles}>
                  <Typography variant="body2" style={{ marginBottom: '32px' }}>
                    사용자 정보 수정
                  </Typography>
                  <EditForm
                    onSubmit={async (e) => {
                      e.preventDefault();
                      await onUserEdit(user);
                    }}
                  >
                    <TextField
                      label="이름"
                      multiline
                      size="small"
                      variant="outlined"
                      value={user.name}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          name: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      label="닉네임"
                      multiline
                      size="small"
                      variant="outlined"
                      value={user.nickname}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          nickname: e.target.value,
                        });
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        width: '120px',
                        height: '32px',
                      }}
                    >
                      <Typography variant="body2">수정</Typography>
                    </Button>
                  </EditForm>
                </FlexCol>
              </MediaFlex>
              <MediaFlex>
                <FlexCol style={itemStyles}>
                  {accommodationDetails.map((detail, idx) => {
                    if (idx < 5)
                      return (
                        <FlexCol key={detail?.key} style={{ width: '100%' }}>
                          <Typography>{detail?.key}</Typography>
                          <MediaFormControl
                            key={detail?.key}
                            style={{ margin: '8px 0' }}
                          >
                            <TextField
                              multiline
                              size="small"
                              value={getAccommodationValue(detail?.value)}
                              onFocus={handleAccommodationFocus(detail?.value)}
                              onChange={handleAccommodationChange}
                              variant="outlined"
                            />
                          </MediaFormControl>
                        </FlexCol>
                      );
                  })}
                </FlexCol>

                <FlexCol style={itemStyles}>
                  {accommodationDetails.map((detail, idx) => {
                    if (idx >= 5)
                      return (
                        <FlexCol key={detail?.key} style={{ width: '100%' }}>
                          <Typography>{detail?.key}</Typography>
                          <MediaFormControl
                            key={detail?.key}
                            style={{ margin: '8px 0' }}
                          >
                            <TextField
                              multiline
                              size="small"
                              value={getAccommodationValue(detail?.value)}
                              onFocus={handleAccommodationFocus(detail?.value)}
                              onChange={handleAccommodationChange}
                              variant="outlined"
                            />
                          </MediaFormControl>
                        </FlexCol>
                      );
                  })}
                </FlexCol>
              </MediaFlex>

              <MediaFlex>
                <FlexCol style={itemStyles}>
                  <Typography>마스터 카드 데이터</Typography>
                  <MediaTextareaAutosize
                    maxRows={16}
                    value={getAccommodationValue('cardData')}
                    onFocus={handleAccommodationFocus('cardData')}
                    onChange={handleAccommodationChange}
                  />
                </FlexCol>

                <FlexCol style={itemStyles}>
                  <Typography>청소 카드 데이터</Typography>
                  <MediaTextareaAutosize
                    maxRows={16}
                    value={getAccommodationValue('cleanCardData')}
                    onFocus={handleAccommodationFocus('cleanCardData')}
                    onChange={handleAccommodationChange}
                  />
                </FlexCol>
              </MediaFlex>

              <MediaFlex>
                <FlexCol style={itemStyles}>
                  <Typography>부킹엔진 PG 설정</Typography>
                  <FlexRow>
                    <Typography>
                      URL 활성화 상태:{' '}
                      {pgConfigInput.isLive ? '활성' : '비활성'}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: '80px',
                        height: '24px',
                        marginLeft: 16,
                      }}
                      disabled={pgConfigInput.isLive}
                      onClick={onSavePgConfigInput}
                    >
                      <Typography>활성화</Typography>
                    </Button>
                  </FlexRow>
                  <FlexCol style={{ width: '100%' }}>
                    <Typography>MbrNo</Typography>
                    <MediaFormControl style={{ margin: '8px 0' }}>
                      <TextField
                        multiline
                        size="small"
                        value={pgConfigInput.mbrNo}
                        name="mbrNo"
                        onChange={handlePgConfigChange}
                        variant="outlined"
                      />
                    </MediaFormControl>
                  </FlexCol>
                  <FlexCol style={{ width: '100%' }}>
                    <Typography>Api Key</Typography>
                    <MediaFormControl style={{ margin: '8px 0' }}>
                      <TextField
                        multiline
                        size="small"
                        value={pgConfigInput.apiKey}
                        name="apiKey"
                        onChange={handlePgConfigChange}
                        variant="outlined"
                      />
                    </MediaFormControl>
                  </FlexCol>
                  <FlexRow style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: '192px',
                        height: '48px',
                        margin: '32px 0 0 0',
                      }}
                      onClick={onSavePgConfigInput}
                    >
                      <Typography>저장</Typography>
                    </Button>
                  </FlexRow>
                </FlexCol>
              </MediaFlex>

              <FlexCenteredRow style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: '192px',
                    height: '48px',
                    margin: '32px 0 0 0',
                  }}
                  onClick={onClickUpdateAccommodation}
                >
                  <Typography>설정</Typography>
                </Button>
              </FlexCenteredRow>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnack}
                autoHideDuration={2000}
                onClose={() => setIsSnack(false)}
                key={'top-center'}
              >
                <Alert onClose={() => setIsSnack(false)} severity="success">
                  설정이 완료되었습니다
                </Alert>
              </Snackbar>
            </ComponentContainer>
          ) : (
            <ComponentContainer
              style={{ justifyContent: 'center', height: '100%' }}
            >
              <Typography align="center" variant="h4">
                지점이 없습니다.
              </Typography>
            </ComponentContainer>
          )}
        </FlexCenteredCol>
      )}
    </WrapperContainer>
  );
};

export default Accommodation;

