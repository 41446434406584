import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

const client = new S3Client({
  region: 'ap-northeast-2',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ID || '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET || '',
  },
});

export default client;

