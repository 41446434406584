import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { buildAttachmentCdnSrc } from '../../commons/constants';
import {
  Flex,
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
} from '../../components/Layout';

const RowLine = styled.div`
  border: 1px solid #eee;
  margin: 8px;
`;

const CustomFlex = styled(Flex)`
  margin: 8px 0;
  padding: 16px 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #d3d3d8;
`;

const CustomInnerFlex = styled(Flex)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomFormControl = styled(FormControl)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const HardwareInput = styled(TextField)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomTextField = styled(TextField)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomSelect = styled(Select)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    console.log(e.target);
  };
  return { value, onChange };
};

const TextInput = ({
  value = '',
  onChange,
}: {
  value?: string;
  onChange: (event: any) => void;
}) => {
  return (
    <HardwareInput
      fullWidth
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
    />
  );
};

const KioskBtnConfig = ({
  guideText = '',
  btnLogo = false,
  btnColor = '',
  innerTextFontSize = '',
  innerText = '',
  disableInnerTextFontSize = '',
  disableInnerText = '',
  onChangeBtnColor,
  onChangeBtnLogo,
  onChangeInnerFontSize,
  onChangeInnerText,
  onChangeDisableInnerFontSize,
  onChangeDisableInnerText,
}: {
  guideText?: string;
  btnLogo?: boolean;
  btnColor?: string;
  innerTextFontSize?: string;
  innerText?: string;
  disableInnerTextFontSize: string;
  disableInnerText: string;
  onChangeBtnColor: (event: any) => void;
  onChangeBtnLogo: (event: any) => void;
  onChangeInnerFontSize: (event: any) => void;
  onChangeInnerText: (event: any) => void;
  onChangeDisableInnerFontSize: (event: any) => void;
  onChangeDisableInnerText: (event: any) => void;
}) => {
  return (
    <FlexCol style={{ flex: 1, marginRight: '12px' }}>
      <Typography>{`${guideText} 버튼`}</Typography>
      <FlexRow
        style={{
          alignItems: 'center',
          width: 'inherit',
          height: 48,
          margin: '12px 0 0 0',
        }}
      >
        <Typography
          align="center"
          style={{
            minWidth: 90,
            wordBreak: 'keep-all',
            whiteSpace: 'pre-line',
          }}
        >
          {`아이콘\nON/OFF`}
        </Typography>
        <Checkbox checked={btnLogo} onChange={onChangeBtnLogo} />
      </FlexRow>

      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 48 }}>
        <Typography align="center" style={{ minWidth: 90 }}>
          버튼 색상
        </Typography>
        <input
          type="color"
          value={btnColor}
          onChange={onChangeBtnColor}
          style={{ width: '100%' }}
        />
      </FlexRow>
      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 48 }}>
        <Typography align="center" style={{ minWidth: 90 }}>
          색상 초기화
        </Typography>
        <input
          onClick={() => {
            onChangeBtnColor({ target: { value: undefined } });
          }}
          type="button"
          value={'초기화'}
        />
      </FlexRow>
      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 48 }}>
        <Typography
          align="center"
          style={{
            minWidth: 90,
            wordBreak: 'keep-all',
            whiteSpace: 'pre-line',
          }}
        >
          {`기본 문구\n크기\n(rem 단위)`}
        </Typography>
        <TextInput value={innerTextFontSize} onChange={onChangeInnerFontSize} />
      </FlexRow>

      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 72 }}>
        <Typography align="center" style={{ minWidth: 90 }}>
          기본 문구
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          multiline
          rows={2}
          InputProps={{ disableUnderline: true }}
          margin="dense"
          value={innerText}
          onChange={onChangeInnerText}
        />
      </FlexRow>
      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 72 }}>
        <Typography
          align="center"
          style={{
            minWidth: 90,
            wordBreak: 'keep-all',
            whiteSpace: 'pre-line',
          }}
        >
          {`비활성화\n문구\n크기`}
        </Typography>
        <TextInput
          value={disableInnerTextFontSize}
          onChange={onChangeDisableInnerFontSize}
        />
      </FlexRow>

      <FlexRow style={{ alignItems: 'center', width: 'inherit', height: 72 }}>
        <Typography
          align="center"
          style={{
            minWidth: 90,
            wordBreak: 'keep-all',
            whiteSpace: 'pre-line',
          }}
        >
          {`비활성화\n문구`}
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          multiline
          rows={2}
          InputProps={{ disableUnderline: true }}
          margin="dense"
          value={disableInnerText}
          onChange={onChangeDisableInnerText}
        />
      </FlexRow>
    </FlexCol>
  );
};

const PopupTextInput = ({
  guideText = '',
  value = '',
  onChange,
  fontSizeValue = '',
  onChangeFontSize,
}: {
  guideText?: string;
  value?: string;
  onChange: (event: any) => void;
  fontSizeValue?: string;
  onChangeFontSize: (event: any) => void;
}) => {
  return (
    <FlexCenteredCol style={{ marginTop: '32px' }}>
      <RowLine />

      <Typography
        style={{ margin: '16px 0 8px 0' }}
      >{`${guideText} 폰트 크기 (rem 단위)`}</Typography>
      <TextInput value={fontSizeValue} onChange={onChangeFontSize} />

      <Typography
        style={{ margin: '16px 0 8px 0' }}
      >{`${guideText} (${value?.length} / 255)`}</Typography>
      <TextField
        fullWidth
        variant="filled"
        multiline
        rows={2}
        InputProps={{ disableUnderline: true }}
        margin="dense"
        placeholder="최대 120자 까지 작성 가능합니다"
        value={value || ''}
        onChange={onChange}
      />
    </FlexCenteredCol>
  );
};

const KioskMainArticles = ({
  accommodation,
  mainInputValues,
  handleMainChange,
  handleImageChange,
  kioskArticle,
  createKioskMainArticle,
  updateKioskMainArticle,
}: {
  accommodation: any;
  mainInputValues: any;
  handleMainChange: (prop: any, additionalProp?: any) => (event: any) => void;
  handleImageChange: (event: any) => void;
  kioskArticle: any;
  createKioskMainArticle: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  updateKioskMainArticle: () => Promise<void>;
}) => {
  const { id: mainId, attachments: mainAttachments } =
    !!kioskArticle && kioskArticle;

  return (
    <Fragment>
      <Typography
        variant="h5"
        style={{ height: '48px', margin: '24px 0 0 32px' }}
      >
        메인 화면 설정
      </Typography>

      <>
        {!kioskArticle ? (
          <FlexCenteredCol
            style={{
              alignItems: 'center',
              height: '200px',
            }}
          >
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              메인 화면 설정이 없습니다.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: '96px',
                height: '40px',
                marginTop: '64px',
              }}
              onClick={async () => {
                await createKioskMainArticle({
                  variables: {
                    accommodationId: accommodation?.id,
                  },
                });
              }}
            >
              <Typography>+ 생성</Typography>
            </Button>
          </FlexCenteredCol>
        ) : (
          <>
            <CustomFlex style={{ flexDirection: 'column' }}>
              <FlexRow style={{ width: '100%' }}>
                <FlexCol style={{ flex: 1, marginRight: '16px' }}>
                  <Typography>메인 화면 상단 문구</Typography>
                  <TextInput
                    value={mainInputValues.mainTitle || ''}
                    onChange={handleMainChange('mainTitle')}
                  />
                </FlexCol>
                <FlexCol style={{ flex: 1, marginRight: '16px' }}>
                  <Typography>메인 화면 하단 1 문구</Typography>
                  <TextInput
                    value={mainInputValues.subTitle1 || ''}
                    onChange={handleMainChange('subTitle1')}
                  />
                </FlexCol>
                <FlexCol style={{ flex: 1 }}>
                  <Typography>메인 화면 하단 2 문구</Typography>
                  <TextInput
                    value={mainInputValues.subTitle2 || ''}
                    onChange={handleMainChange('subTitle2')}
                  />
                </FlexCol>
              </FlexRow>

              <FlexRow style={{ width: '100%', marginTop: '16px' }}>
                <FlexCol style={{ flex: 1, marginRight: '16px' }}>
                  <Typography>상단 문구 폰트 크기</Typography>
                  <TextInput
                    value={
                      mainInputValues?.content?.['mainTitleSize'] ||
                      mainInputValues?.mainTitleSize
                    }
                    onChange={handleMainChange('mainTitleSize')}
                  />
                </FlexCol>
                <FlexCol style={{ flex: 1, marginRight: '16px' }}>
                  <Typography>하단 1 폰트 크기</Typography>
                  <TextInput
                    value={
                      mainInputValues?.content?.['subTitle1Size'] ||
                      mainInputValues?.subTitle1Size
                    }
                    onChange={handleMainChange('subTitle1Size')}
                  />
                </FlexCol>
                <FlexCol style={{ flex: 1 }}>
                  <Typography>하단 2 폰트 크기</Typography>
                  <TextInput
                    value={
                      mainInputValues?.content?.['subTitle2Size'] ||
                      mainInputValues?.subTitle2Size
                    }
                    onChange={handleMainChange('subTitle2Size')}
                  />
                </FlexCol>
              </FlexRow>

              {/* 각 버튼 메인 문구 */}
              <FlexRow
                style={{
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <KioskBtnConfig
                  guideText="예약 체크인"
                  btnLogo={mainInputValues?.useReservationBtnLogo}
                  btnColor={mainInputValues?.reservationBtnColor}
                  innerTextFontSize={mainInputValues?.reservationInnerFontSize}
                  innerText={mainInputValues?.reservation}
                  disableInnerTextFontSize={
                    mainInputValues?.reservationDisableInnerFontSize
                  }
                  disableInnerText={
                    mainInputValues?.reservationDisableInnerText
                  }
                  onChangeBtnLogo={handleMainChange('useReservationBtnLogo')}
                  onChangeBtnColor={handleMainChange('reservationBtnColor')}
                  onChangeInnerFontSize={handleMainChange(
                    'reservationInnerFontSize',
                  )}
                  onChangeInnerText={handleMainChange('reservation')}
                  onChangeDisableInnerFontSize={handleMainChange(
                    'reservationDisableInnerFontSize',
                  )}
                  onChangeDisableInnerText={handleMainChange(
                    'reservationDisableInnerText',
                  )}
                />
                <KioskBtnConfig
                  guideText="현장 결제"
                  btnLogo={mainInputValues?.useCheckInBtnLogo}
                  btnColor={mainInputValues?.checkInBtnColor}
                  innerTextFontSize={mainInputValues?.checkInInnerFontSize}
                  innerText={mainInputValues?.checkIn}
                  disableInnerTextFontSize={
                    mainInputValues?.checkInDisableInnerFontSize
                  }
                  disableInnerText={mainInputValues?.checkInDisableInnerText}
                  onChangeBtnLogo={handleMainChange('useCheckInBtnLogo')}
                  onChangeBtnColor={handleMainChange('checkInBtnColor')}
                  onChangeInnerFontSize={handleMainChange(
                    'checkInInnerFontSize',
                  )}
                  onChangeInnerText={handleMainChange('checkIn')}
                  onChangeDisableInnerFontSize={handleMainChange(
                    'checkInDisableInnerFontSize',
                  )}
                  onChangeDisableInnerText={handleMainChange(
                    'checkInDisableInnerText',
                  )}
                />
                <KioskBtnConfig
                  guideText="체크아웃"
                  btnLogo={mainInputValues?.useCheckOutBtnLogo}
                  btnColor={mainInputValues?.checkOutBtnColor}
                  innerTextFontSize={mainInputValues?.checkOutInnerFontSize}
                  innerText={mainInputValues?.checkOut}
                  disableInnerTextFontSize={
                    mainInputValues?.checkOutDisableInnerFontSize
                  }
                  disableInnerText={mainInputValues?.checkOutDisableInnerText}
                  onChangeBtnLogo={handleMainChange('useCheckOutBtnLogo')}
                  onChangeBtnColor={handleMainChange('checkOutBtnColor')}
                  onChangeInnerFontSize={handleMainChange(
                    'checkOutInnerFontSize',
                  )}
                  onChangeInnerText={handleMainChange('checkOut')}
                  onChangeDisableInnerFontSize={handleMainChange(
                    'checkOutDisableInnerFontSize',
                  )}
                  onChangeDisableInnerText={handleMainChange(
                    'checkOutDisableInnerText',
                  )}
                />
                <KioskBtnConfig
                  guideText="대실"
                  btnLogo={mainInputValues?.useRentBtnLogo}
                  btnColor={mainInputValues?.rentBtnColor}
                  innerTextFontSize={mainInputValues?.rentInnerFontSize}
                  innerText={mainInputValues?.rent}
                  disableInnerTextFontSize={
                    mainInputValues?.rentDisableInnerFontSize
                  }
                  disableInnerText={mainInputValues?.rentDisableInnerText}
                  onChangeBtnLogo={handleMainChange('useRentBtnLogo')}
                  onChangeBtnColor={handleMainChange('rentBtnColor')}
                  onChangeInnerFontSize={handleMainChange('rentInnerFontSize')}
                  onChangeInnerText={handleMainChange('rent')}
                  onChangeDisableInnerFontSize={handleMainChange(
                    'rentDisableInnerFontSize',
                  )}
                  onChangeDisableInnerText={handleMainChange(
                    'rentDisableInnerText',
                  )}
                />
                <KioskBtnConfig
                  guideText="숙박"
                  btnLogo={mainInputValues?.useLodgeBtnLogo}
                  btnColor={mainInputValues?.lodgeBtnColor}
                  innerTextFontSize={mainInputValues?.lodgeInnerFontSize}
                  innerText={mainInputValues?.lodge}
                  disableInnerTextFontSize={
                    mainInputValues?.lodgeDisableInnerFontSize
                  }
                  disableInnerText={mainInputValues?.lodgeDisableInnerText}
                  onChangeBtnLogo={handleMainChange('useLodgeBtnLogo')}
                  onChangeBtnColor={handleMainChange('lodgeBtnColor')}
                  onChangeInnerFontSize={handleMainChange('lodgeInnerFontSize')}
                  onChangeInnerText={handleMainChange('lodge')}
                  onChangeDisableInnerFontSize={handleMainChange(
                    'lodgeDisableInnerFontSize',
                  )}
                  onChangeDisableInnerText={handleMainChange(
                    'lodgeDisableInnerText',
                  )}
                />
              </FlexRow>

              {/* 메뉴 버튼 > 보충 문구 */}
              <FormGroup style={{ marginTop: '16px' }}>
                <Typography>메뉴 버튼 &gt; 보충 문구 ON/OFF (구 UI)</Typography>
                <CustomFlex
                  style={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '4px 16px',
                    backgroundColor: '#eee',
                    borderRadius: '4px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mainInputValues?.useReservationSub}
                        onChange={handleMainChange('useReservationSub')}
                      />
                    }
                    label={'예약 체크인'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mainInputValues?.useOnSitePaymentSub}
                        onChange={handleMainChange('useOnSitePaymentSub')}
                      />
                    }
                    label={'현장판매'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mainInputValues?.useCheckOutSub}
                        onChange={handleMainChange('useCheckOutSub')}
                      />
                    }
                    label={'체크아웃'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mainInputValues?.useRentSub}
                        onChange={handleMainChange('useRentSub')}
                      />
                    }
                    label={'대실'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mainInputValues?.useLodgeSub}
                        onChange={handleMainChange('useLodgeSub')}
                      />
                    }
                    label={'숙박'}
                  />
                </CustomFlex>
              </FormGroup>

              {/* 예약 체크인 > 지원되는 OTA 표시 */}
              <FormGroup style={{ marginTop: '16px' }}>
                <Typography>
                  예약 체크인 &gt; 지원되는 OTA 표시 (구 UI)
                </Typography>
                <CustomFlex
                  style={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '4px 16px',
                    backgroundColor: '#eee',
                    borderRadius: '4px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!mainInputValues?.['listInReservationSub']?.find(
                            (val: any) => val === 'yanolja',
                          )
                        }
                        onChange={handleMainChange(
                          'listInReservationSub',
                          'yanolja',
                        )}
                      />
                    }
                    label={'야놀자'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!mainInputValues?.['listInReservationSub']?.find(
                            (val: any) => val === 'yeogieottae',
                          )
                        }
                        onChange={handleMainChange(
                          'listInReservationSub',
                          'yeogieottae',
                        )}
                      />
                    }
                    label={'여기어때'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!mainInputValues?.['listInReservationSub']?.find(
                            (val: any) => val === 'naver',
                          )
                        }
                        onChange={handleMainChange(
                          'listInReservationSub',
                          'naver',
                        )}
                      />
                    }
                    label={'네이버'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!mainInputValues?.['listInReservationSub']?.find(
                            (val: any) => val === 'hotelga',
                          )
                        }
                        onChange={handleMainChange(
                          'listInReservationSub',
                          'hotelga',
                        )}
                      />
                    }
                    label={'호텔가'}
                  />
                  <Button
                    variant="contained"
                    style={{ height: '32px' }}
                    onClick={handleMainChange('onDeleteReservationListAll')}
                  >
                    지우기
                  </Button>
                </CustomFlex>
              </FormGroup>

              <PopupTextInput
                guideText="예약 팝업 문구"
                value={mainInputValues?.reservationPopup}
                onChange={handleMainChange('reservationPopup')}
                fontSizeValue={mainInputValues?.reservationPopupFontSize}
                onChangeFontSize={handleMainChange('reservationPopupFontSize')}
              />
              <PopupTextInput
                guideText="체크인 팝업 문구"
                value={mainInputValues?.checkInPopup}
                onChange={handleMainChange('checkInPopup')}
                fontSizeValue={mainInputValues?.checkInPopupFontSize}
                onChangeFontSize={handleMainChange('checkInPopupFontSize')}
              />
              <PopupTextInput
                guideText="대실 팝업 문구"
                value={mainInputValues?.rentPopup}
                onChange={handleMainChange('rentPopup')}
                fontSizeValue={mainInputValues?.rentPopupFontSize}
                onChangeFontSize={handleMainChange('rentPopupFontSize')}
              />
              <PopupTextInput
                guideText="숙박 팝업 문구"
                value={mainInputValues?.lodgePopup}
                onChange={handleMainChange('lodgePopup')}
                fontSizeValue={mainInputValues?.lodgePopupFontSize}
                onChangeFontSize={handleMainChange('lodgePopupFontSize')}
              />
              <PopupTextInput
                guideText="체크아웃 팝업 문구"
                value={mainInputValues?.checkOutPopup}
                onChange={handleMainChange('checkOutPopup')}
                fontSizeValue={mainInputValues?.checkOutPopupFontSize}
                onChangeFontSize={handleMainChange('checkOutPopupFontSize')}
              />

              <FlexCol style={{ marginTop: '32px' }}>
                <Typography>
                  메인 화면 사진 목록 ( 135 : 59 | 1080 * 472 에 최적화 )
                </Typography>
                <FlexCenteredRow style={{ marginTop: '1vh', padding: '24px' }}>
                  <img
                    src={buildAttachmentCdnSrc(mainId, mainAttachments)}
                    width="33%"
                    alt="main-menu-layout"
                  />
                </FlexCenteredRow>
                <FlexCenteredRow style={{ width: '100%', marginTop: '16px' }}>
                  <input type="file" onChange={handleImageChange} />
                </FlexCenteredRow>
              </FlexCol>
            </CustomFlex>

            <FlexCenteredCol style={{ alignItems: 'center', height: '96px' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '184px', height: '56px' }}
                onClick={updateKioskMainArticle}
              >
                <Typography>메인 화면 변경</Typography>
              </Button>
            </FlexCenteredCol>
          </>
        )}
      </>
    </Fragment>
  );
};

export default KioskMainArticles;
