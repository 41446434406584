//@ts-nocheck
import { BaseSyntheticEvent, Fragment, useEffect, useState } from 'react';
import {
  Input,
  Button,
  FormControl,
  IconButton,
  Typography,
  InputLabel,
  TextField,
  Snackbar,
} from '@material-ui/core';
import {
  ComponentContainer,
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
  FlexSpaceBetweenRow,
  WrapperContainer,
} from '../../components/Layout';
import { Close, Delete } from '@material-ui/icons';
import {
  deserialize1024,
  deserialize512,
  isEmpty,
  isLegacyCardData,
  isSerializedData,
} from '../../utils/utils';
import {
  OptionButton,
  RoomCard,
  RoomInnerCard,
  RoomInnerModal,
  RoomInnerModalHeader,
  RoomModal,
} from './UI';
import RoomModifyModal from './RoomModifyModal';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router';
import { InnerModal, WrapperModal } from '../../components/Modal';
import SessionOut from '../../components/SessionOut';
import Loading from '../../components/Loading';
import useRoomControl, { roomInfoInit } from '../../hooks/useRoomControl';
import useAccommodationControl, {
  accommodationInfoInit,
} from '../../hooks/useAccommodationControl';
import { RoomModalHeader } from './RoomModal';

type ModalState =
  | 'CREATE_ROOM'
  | 'CREATE_ROOM_A_TO_B'
  | 'RCU_SYNC'
  | 'RCU_SYNC_A_TO_B'
  | 'DUPLICATE_ROOMS';

const Room = () => {
  const { id: accommodationId } = useParams();

  // ## React States

  const [selectedModal, setSelectedModal] = useState<ModalState>('');
  const [selectedControl, setSelectedControl] = useState('');

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateHotelCodeModal, setOpenUpdateHotelCodeModal] =
    useState(false);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [isSnack, setIsSnack] = useState<boolean>(false);
  const [snack, _setSnack] = useState<string>(false);

  const [accommodation, setAccommodation] = useState({});
  const [roomType, setRoomType] = useState({});
  const [kiosk, setKiosk] = useState({});
  const [inputName, setInputName] = useState('');
  const [sortType, setSortType] = useState('');

  // ## React Hooks

  const {
    loading: roomsLoading,
    data: roomsData,
    error: roomsError,
    accommodationInfo,
    setAccommodationInfo,
    refetch,
    createHotelCode,
    updateHotelCode,
  } = useAccommodationControl({
    accommodationId,
    accommodation,
    onSuccess: () => {
      setSnack('success');
      refetch();
    },
    onError: () => {
      setSnack('error');
      refetch();
    },
  });

  const {
    roomInfo,
    roomsWithRoomType,
    roomsWithFloor,
    createRoom: _createRoom,
    updateRoom: _updateRoom,
    deleteRoom: _deleteRoom,
    duplicatedData,
    setRoomInfo,
    setDuplicatedData,
    onClickCreateRoomAToB,
    onClickSortRcuId,
    onClickSortRcuIdAToB,
    onClickSyncKeyAndCardDataAll,
    onClickSyncKeyAndCardDataOne,
    onClickConvertRcuIdToDoorLockId,
    onClickMatchRoomWithRcuId,
    onClickDownloadEncryptionKeys,
    onClickDownloadRooms,
    onClickSaveModifyRoom,
    onClickSyncMasterKeyCard,
    onClickSyncCleanKeyCard,
    onClickResetKeyCardAll,
    onClickSearchDuplicateData,
  } = useRoomControl({
    roomsData,
    accommodation,
    accommodationId: accommodation?.id,
    roomTypeId: roomType?.id,
    kioskId: kiosk?.id,
    onSuccess: () => {
      setSnack('success');
    },
    onError: () => {
      setSnack('error');
    },
  });

  // ## Function Declarations

  const setSnack = (msg: string) => {
    _setSnack(msg);
    setIsSnack(true);
  };

  const onClose = async () => {
    setOpenUpdateModal(false);
    setOpenDeleteModal(false);
    setOpenUpdateHotelCodeModal(false);
    setRoomInfo(roomInfoInit);
    setAccommodationInfo(accommodationInfoInit);
    await refetch();
  };

  // Accommodation Data Handler

  const getAccommodationValue = (inputNameValue: 'hotelCode') => {
    return accommodationInfo[inputNameValue] || '';
  };

  const handleAccommodationChange = (event: any) => {
    const inputVal = event.target.value;
    setAccommodationInfo({ ...accommodationInfo, [inputName]: inputVal });
  };

  // Room Data Handler

  const getRoomValue = (
    inputNameValue: 'id' | 'name' | 'number' | 'order' | 'key' | 'cardData',
  ) => {
    return roomInfo[inputNameValue] || '';
  };

  const handleRoomChange = (event: BaseSyntheticEvent) => {
    const inputVal = event.target.value;
    setRoomInfo({ ...roomInfo, [inputName]: inputVal });
  };

  const handleRoomFocus = (name: string) => (e) => {
    setInputName(name);
  };

  // Side-Effects

  useEffect(() => {
    if (roomsWithRoomType) {
      const allRooms: any[] = roomsWithRoomType
        ?.map((roomType) => roomType?.rooms)
        ?.flat()
        ?.map((room) => {
          return {
            id: room?.id,
            name: room?.name,
            key: room?.key,
          };
        });

      // console.log('ALL ROOMS ::', allRooms);

      const filterRooms = allRooms?.map((room) => room?.id);

      // console.log('ALL ROOM IDS ::', filterRooms);

      const onlyKeys = [...new Set(filterRooms)];

      // console.log('ONLY ROOM IDS ::', onlyKeys);

      // const rooms: any[] = roomsWithRoomType
      //   ?.map((roomType) => roomType?.rooms)
      //   .flat()
      //   .map((room) => {
      //     const { name } = room;
      //     const key = room?.key?.includes('$')
      //       ? JSON.stringify(
      //           room?.key
      //             ?.split('$')?.[0]
      //             ?.split('-')
      //             .join('')
      //             ?.match(/.{1,2}/g)
      //             ?.map((i) => parseInt(i, 16)),
      //         )
      //       : JSON.stringify(
      //           room?.key
      //             ?.split('-')
      //             .join('')
      //             ?.match(/.{1,2}/g)
      //             ?.map((i) => parseInt(i, 16)),
      //         );
      //     const deserialize512Data =
      //       !isEmpty(room?.cardData) && deserialize512(room?.cardData);
      //     const deserialize1024Data =
      //       !isEmpty(room?.cardData) && deserialize1024(room?.cardData);
      //     // const cardData = !isEmpty(room?.cardData)
      //     //   ? deserialize512Data?.length === 64
      //     //     ? JSON.stringify(deserialize512Data)
      //     //     : JSON.stringify(deserialize1024Data)
      //     //   : '';
      //     const cardData = !isEmpty(room?.cardData)
      //       ? deserialize512Data?.length === 64
      //         ? deserialize512Data
      //         : deserialize1024Data[0]
      //             ?.slice(0, 6)
      //             ?.map((i) =>
      //               i?.toString(16)?.length === 1
      //                 ? `0${i?.toString(16)?.toUpperCase()}`
      //                 : i?.toString(16)?.toUpperCase(),
      //             )
      //       : '';
      //     // return {
      //     //   name: name,
      //     //   // isSame: key === cardData,
      //     //   // key: room?.key?.slice(0, 8),
      //     //   // key: key,
      //     //   cardData: cardData,
      //     // };
      //     return cardData.toString();
      //   });
      // console.log('ALL ROOMS', rooms);
      // const filRoom = rooms?.map((room) => {
      //   return {
      //     name: room?.name,
      //     key: JSON.stringify(JSON.parse(room?.key)?.slice(5, 8)),
      //   };
      // });
      // console.log(filRoom);
      // console.log(
      //   'isSame',
      //   rooms?.filter((i) => i?.isSame === true),
      // );
      // console.log(
      //   'isNotSame',
      //   rooms?.filter((i) => i?.isSame === false),
      // );
      let duplicatedRooms = [];
      // rooms?.forEach((outerRoom) => {
      //   rooms?.forEach((innerRoom) => {
      //     if (outerRoom?.name !== innerRoom?.name) {
      //       if (outerRoom?.key === innerRoom?.key) {
      //         duplicatedRooms?.push();
      //       }
      //     }
      //   });
      // });
      // roomInKeys?.forEach((prev) => {
      //   roomInKeys?.forEach((next) => {
      //     console.log(prev);
      //     if (prev !== next) duplicatedRooms?.push();
      //   });
      // });
      // console.log(duplicatedRooms);
      // console.log('중복 확인', duplicatedRooms);
      // console.log(
      //   rooms?.filter((i) => i?.isSame === false && !isEmpty(i?.cardData)),
      // );
    }
  }, [roomsWithRoomType]);

  useEffect(() => {
    const accommodation = roomsData?.['getSingleAccommodation'];
    const roomTypes: any[] = accommodation?.roomTypes;
    const kiosks = accommodation?.kiosks;

    const lastRoomType = roomTypes?.[roomTypes?.length - 1];
    const filteredRoomType = roomTypes?.[0];
    const kiosk = kiosks?.[0];

    setAccommodation(accommodation);
    setRoomType(lastRoomType);
    setKiosk(kiosk);

    if (accommodation) {
      setSortType('floor');
    }
  }, [roomsData]);

  return (
    <WrapperContainer>
      {roomsError ? (
        <SessionOut />
      ) : roomsLoading ? (
        <Loading />
      ) : (
        <ComponentContainer style={{ background: 'none' }}>
          {/* 경고 Modal */}
          <WrapperModal open={openDeleteModal}>
            <InnerModal style={{ minWidth: '464px', minHeight: '400px' }}>
              <FlexCol style={{ padding: '32px 0 0 0' }}>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ color: '#ce0000' }}
                >
                  경고
                </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    letterSpacing: '-2px',
                    margin: '64px 0 0 0',
                    color: '#000000',
                  }}
                >
                  {`${selectedRoom?.name}`}
                </Typography>
                <Typography
                  align="center"
                  variant="h5"
                  style={{ margin: '32px 0 48px 0', color: '#000000' }}
                >
                  {`정말 삭제하시겠습니까?`}
                </Typography>
              </FlexCol>
              <FlexCenteredRow style={{ margin: '12px 0 0 0' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={async () => {
                    try {
                      const result = await _deleteRoom({
                        id: selectedRoom?.id,
                      });

                      if (result) {
                        setSnack('success');
                        setSelectedRoom(null);
                        setOpenDeleteModal(false);
                      }
                    } catch (err) {
                      setSnack('error');
                      setSelectedRoom(null);
                      setOpenDeleteModal(false);
                    }
                  }}
                  style={{
                    width: '192px',
                    height: '56px',
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="h6">확인</Typography>
                </Button>

                <Button
                  variant="contained"
                  style={{ margin: '0 0 0 32px' }}
                  onClick={() => {
                    setSelectedRoom(null);
                    setOpenDeleteModal(false);
                  }}
                  style={{
                    width: '192px',
                    height: '56px',
                    borderRadius: '8px',
                    margin: '0 0 0 32px',
                  }}
                >
                  <Typography variant="h6">취소</Typography>
                </Button>
              </FlexCenteredRow>
            </InnerModal>
          </WrapperModal>

          {/* 종합 Modal */}
          <WrapperModal open={selectedModal}>
            <>
              {selectedModal === 'CREATE_ROOM' && (
                <InnerModal>
                  <RoomModalHeader
                    title={`객실 생성`}
                    onClose={() => {
                      setSelectedModal('');
                      setRoomInfo(roomInfoInit);
                    }}
                  />

                  <FlexCenteredRow style={{ padding: '32px' }}>
                    <TextField
                      label="객실명 (전부 기입하세요)"
                      value={getRoomValue('name')}
                      onFocus={handleRoomFocus('name')}
                      onChange={handleRoomChange}
                      variant="outlined"
                    />
                  </FlexCenteredRow>

                  <FlexCenteredRow style={{ height: '64px' }}>
                    <Button
                      variant="contained"
                      style={{ width: '120px', height: '48px' }}
                      onClick={async () => {
                        setSelectedModal('');

                        const result = await _createRoom({
                          name: roomInfo.name,
                        });

                        if (result) {
                          setSnack('success');
                          await refetch();
                        }
                      }}
                    >
                      <Typography variant="h6">설정</Typography>
                    </Button>
                  </FlexCenteredRow>
                </InnerModal>
              )}
              {selectedModal === 'CREATE_ROOM_A_TO_B' && (
                <InnerModal>
                  <RoomModalHeader
                    title={`A -> B 객실 생성`}
                    onClose={() => {
                      setSelectedModal('');
                      setRoomInfo(roomInfoInit);
                    }}
                  />

                  <FlexCenteredRow style={{ padding: '32px' }}>
                    <TextField
                      label="객실 처음 값"
                      value={getRoomValue('startNumber')}
                      onFocus={handleRoomFocus('startNumber')}
                      onChange={handleRoomChange}
                      variant="outlined"
                      style={{ width: '120px' }}
                    />
                    <FlexCenteredRow
                      style={{ padding: '24px' }}
                    >{`->`}</FlexCenteredRow>
                    <TextField
                      label="객실 끝 값"
                      value={getRoomValue('endNumber')}
                      onFocus={handleRoomFocus('endNumber')}
                      onChange={handleRoomChange}
                      variant="outlined"
                      style={{ width: '120px' }}
                    />
                  </FlexCenteredRow>

                  <FlexCenteredRow style={{ height: '64px' }}>
                    <Button
                      variant="contained"
                      style={{ width: '120px', height: '48px' }}
                      onClick={onClickCreateRoomAToB}
                    >
                      <Typography variant="h6">생성</Typography>
                    </Button>
                  </FlexCenteredRow>
                </InnerModal>
              )}
              {selectedModal === 'RCU_SYNC' && (
                <InnerModal>
                  <RoomModalHeader
                    title={`RCU ID 동기화`}
                    onClose={() => {
                      setSelectedModal('');
                      setRoomInfo(roomInfoInit);
                    }}
                  />

                  <FlexCenteredRow style={{ padding: '32px' }}>
                    <TextField
                      label="RCU ID 시작 값"
                      value={getRoomValue('startRCUId')}
                      onFocus={handleRoomFocus('startRCUId')}
                      onChange={handleRoomChange}
                      variant="outlined"
                    />
                  </FlexCenteredRow>

                  <FlexCenteredRow style={{ height: '64px' }}>
                    <Button
                      variant="contained"
                      style={{ width: '120px', height: '48px' }}
                      onClick={async () => {
                        setSelectedModal('');
                        await onClickSortRcuId(roomInfo?.startRCUId);
                      }}
                    >
                      <Typography variant="h6">설정</Typography>
                    </Button>
                  </FlexCenteredRow>
                </InnerModal>
              )}
              {selectedModal === 'RCU_SYNC_A_TO_B' && (
                <InnerModal>
                  <RoomModalHeader
                    title={`A -> B RCU ID 동기화`}
                    onClose={() => {
                      setSelectedModal('');
                      setRoomInfo(roomInfoInit);
                    }}
                  />

                  <FlexCol style={{ height: '120px', padding: '32px' }}>
                    <FlexCenteredRow>
                      <TextField
                        label={`${roomInfo?.startRoomName} RCU ID`}
                        value={getRoomValue('startRCUId')}
                        onFocus={handleRoomFocus('startRCUId')}
                        onChange={handleRoomChange}
                        variant="outlined"
                        style={{ width: '144px' }}
                      />
                      <FlexCenteredRow
                        style={{ padding: '24px' }}
                      >{`~`}</FlexCenteredRow>
                      <TextField
                        label={`${roomInfo?.lastRoomName} RCU ID`}
                        value={getRoomValue('lastRCUId')}
                        onFocus={handleRoomFocus('lastRCUId')}
                        onChange={handleRoomChange}
                        variant="outlined"
                        style={{ width: '144px' }}
                      />
                    </FlexCenteredRow>
                    <FlexCenteredRow
                      style={{ height: '96px', margin: '32px 0 0 0' }}
                    >
                      <Button
                        variant="contained"
                        style={{ width: '120px', height: '48px' }}
                        onClick={async () => {
                          setSelectedModal('');
                          await onClickSortRcuIdAToB();
                        }}
                      >
                        <Typography variant="h6">설정</Typography>
                      </Button>
                    </FlexCenteredRow>
                  </FlexCol>
                </InnerModal>
              )}
              {selectedModal === 'DUPLICATE_ROOMS' && duplicatedData?.length && (
                <InnerModal>
                  <RoomModalHeader
                    title={`데이터 중복된 객실 목록`}
                    onClose={() => {
                      setSelectedModal('');
                      setRoomInfo(roomInfoInit);
                      setDuplicatedData(null);
                    }}
                  />

                  <FlexCol style={{ padding: '48px' }}>
                    <FlexCenteredCol style={{ margin: '16px 0 0 0' }}>
                      {duplicatedData.map((data, dataIdx) => {
                        return (
                          <FlexRow style={{ margin: '8px' }}>
                            <FlexCenteredRow>
                              <Typography
                                variant="h6"
                                style={{
                                  fontFamily: 'roboto',
                                  lineHeight: 1.67,
                                }}
                              >
                                그룹 {dataIdx}&nbsp;&nbsp;-&nbsp;
                              </Typography>
                            </FlexCenteredRow>

                            <Typography variant="h6">
                              {data.map(
                                (item, idx, arr) =>
                                  `${item}${
                                    idx !== arr.length - 1 ? ', ' : ''
                                  }`,
                              )}
                            </Typography>
                          </FlexRow>
                        );
                      })}
                    </FlexCenteredCol>
                  </FlexCol>
                </InnerModal>
              )}
            </>
          </WrapperModal>

          {/* 호텔 코드 생성 및 수정 Modal */}
          <RoomModal open={openUpdateHotelCodeModal}>
            <RoomInnerModal>
              <RoomModalHeader
                title={`${accommodation?.name} 호텔코드 수정`}
                onClose={() => {
                  setOpenUpdateHotelCodeModal(!openUpdateHotelCodeModal);
                  setAccommodationInfo(accommodationInfoInit);
                }}
              ></RoomModalHeader>

              <FlexCol style={{ height: 'inherit', padding: '56px' }}>
                <Typography>지점 옵션 목록</Typography>

                {accommodation?.options?.map((option) => (
                  <Typography
                    key={option?.key}
                    style={{ marginTop: '24px', marginLeft: '64px' }}
                  >
                    {option?.key} | {option?.value}
                  </Typography>
                ))}

                <FlexCol style={{ height: '480px', padding: '64px' }}>
                  <InputLabel>호텔 코드</InputLabel>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '480px',
                      marginTop: '8px',
                      marginBottom: '16px',
                    }}
                  >
                    <Input
                      value={getAccommodationValue('hotelCode')}
                      onFocus={handleRoomFocus('hotelCode')}
                      onChange={handleAccommodationChange}
                    />
                  </FormControl>
                </FlexCol>
              </FlexCol>

              <FlexCenteredRow style={{ height: '120px' }}>
                <Button
                  variant="contained"
                  style={{ width: '120px', height: '56px' }}
                  onClick={async () => {
                    await updateHotelCode({
                      hotelCode: accommodationInfo?.hotelCode,
                    });
                  }}
                >
                  <Typography variant="h6">수정</Typography>
                </Button>
              </FlexCenteredRow>
            </RoomInnerModal>
          </RoomModal>

          {/* 객실 수정 Modal */}
          <RoomModal
            open={openUpdateModal}
            onClose={() => {
              setOpenUpdateModal(!openUpdateModal);
              setRoomInfo(roomInfoInit);
            }}
          >
            <RoomModifyModal
              roomInfo={roomInfo}
              setRoomInfo={setRoomInfo}
              onClose={() => {
                setOpenUpdateModal(!openUpdateModal);
                setRoomInfo(roomInfoInit);
              }}
              getValue={getRoomValue}
              onFocus={handleRoomFocus}
              onChange={handleRoomChange}
              onSyncCardDataAndKey={onClickSyncKeyAndCardDataOne}
              onSave={onClickSaveModifyRoom}
            />
          </RoomModal>

          {!roomsLoading &&
            (kiosk ? (
              <FlexCol style={{ overflow: 'scroll' }}>
                {!isEmpty(accommodation) && (
                  <Typography
                    variant="h5"
                    style={{ width: '90%', margin: '32px 0 16px 32px' }}
                  >
                    {accommodation?.name} 지점 내 객실 설정
                  </Typography>
                )}

                {/* 지점 및 객실 관련 특수 Button */}
                <FlexRow
                  style={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    margin: '8px 16px',
                  }}
                >
                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      const result = onClickSyncMasterKeyCard();

                      if (result) setOpenUpdateModal(!openUpdateModal);
                    }}
                  >
                    <Typography>{`마스터 카드 동기화`}</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      const result = onClickSyncCleanKeyCard();

                      if (result) setOpenUpdateModal(!openUpdateModal);
                    }}
                  >
                    <Typography>{`청소 카드 동기화`}</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={async () => {
                      const isExistHotelCode = accommodation?.options?.find(
                        (option) => option?.key === 'hotelCode',
                      );

                      if (isExistHotelCode) {
                        setOpenUpdateHotelCodeModal(true);
                      } else {
                        await createHotelCode();
                      }
                    }}
                  >
                    {accommodation?.options?.find(
                      (option) => option?.key === 'hotelCode',
                    ) ? (
                      <Typography>{`호텔 코드 설정`}</Typography>
                    ) : (
                      <Typography>{`호텔 코드 생성`}</Typography>
                    )}
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={() => {
                      setSelectedModal('CREATE_ROOM');
                    }}
                  >
                    <Typography>{`+ 객실 생성`}</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={() => {
                      setSelectedModal('CREATE_ROOM_A_TO_B');
                    }}
                  >
                    <Typography>A -&gt; B 객실 생성</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={() => {
                      setSelectedModal('RCU_SYNC');
                    }}
                  >
                    <Typography>RCU ID 동기화</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={() => {
                      setSelectedControl('first');
                    }}
                  >
                    <Typography>A -&gt; B RCU ID 동기화</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={onClickConvertRcuIdToDoorLockId}
                  >
                    <Typography>RCU Door 동기화</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={onClickMatchRoomWithRcuId}
                  >
                    <Typography>객실명 ID 동기화</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={onClickSyncKeyAndCardDataAll}
                  >
                    <Typography>객실 키 동기화</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (sortType === 'floor') {
                        setSortType('roomType');
                      } else {
                        setSortType('floor');
                      }
                    }}
                  >
                    <Typography>
                      {sortType === 'floor' ? '층 정렬' : '객실 타입 정렬'}
                    </Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickDownloadEncryptionKeys();
                    }}
                  >
                    <Typography>전 객실 패스워드 추출</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickDownloadRooms();
                    }}
                  >
                    <Typography>전 객실 데이터 추출</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickResetKeyCardAll();
                    }}
                  >
                    <Typography>전 객실 카드 데이터 재발급</Typography>
                  </OptionButton>

                  <OptionButton
                    variant="contained"
                    style={{ padding: '16px', margin: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedModal('DUPLICATE_ROOMS');
                      onClickSearchDuplicateData();
                    }}
                  >
                    <Typography>전 객실 중복 데이터 검증</Typography>
                  </OptionButton>
                </FlexRow>

                {/* All Rooms Card Section */}

                <FlexRow
                  style={{
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    padding: '0 0 120px 16px',
                  }}
                >
                  {sortType === 'floor' &&
                    Object?.keys(roomsWithFloor)?.map((key) => {
                      const sortedRoomsInFloor = roomsWithFloor[key]?.sort(
                        (a, b) =>
                          parseInt(a?.name?.replace(/[^0-9]/g, '')) -
                          parseInt(b?.name?.replace(/[^0-9]/g, '')),
                      );

                      return (
                        <Fragment key={key}>
                          {sortedRoomsInFloor?.map((room, idx) => {
                            return (
                              <RoomCard key={room?.id}>
                                <div
                                  style={{
                                    width: '100%',
                                    height: '8px',
                                    backgroundColor: isLegacyCardData(
                                      room.cardData,
                                    )
                                      ? '#dede00'
                                      : isSerializedData(room.cardData)
                                      ? '#7700cc'
                                      : '#dedede',
                                  }}
                                />
                                <RoomInnerCard
                                  data={room?.cardData}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    if (!selectedControl) {
                                      let swapAdditionalData = {};

                                      const cardData = room?.cardData;

                                      if (isLegacyCardData(cardData)) {
                                        console.log('Legacy Data Found.');
                                        console.log(cardData);

                                        swapAdditionalData = {
                                          ...swapAdditionalData,
                                          cardData: cardData,
                                          cardDataType: 'Legacy',
                                          originCardDataType: 'Legacy',
                                        };
                                      }

                                      if (isSerializedData(cardData)) {
                                        console.log('Serialized Data Found.');

                                        const deserialized512 =
                                          deserialize512(cardData);
                                        const deserialized1024 =
                                          deserialize1024(cardData);

                                        if (deserialized512?.length === 64) {
                                          console.log(
                                            'Serialized 512 Data Found.',
                                          );
                                          console.log(
                                            'Deserialize 512 : ',
                                            deserialized512,
                                          );
                                          console.log(
                                            'Manufacturer Block : ',
                                            deserialized512?.[0],
                                          );

                                          swapAdditionalData = {
                                            ...swapAdditionalData,
                                            cardData:
                                              JSON.stringify(deserialized512),
                                            cardDataType: '512',
                                            originCardDataType: '512',
                                          };
                                        }

                                        if (deserialized512?.length === 128) {
                                          console.log(
                                            'Serialized 1024 Data Found.',
                                          );
                                          console.log(
                                            'Deserialize 1024 : ',
                                            deserialized1024,
                                          );
                                          swapAdditionalData = {
                                            ...swapAdditionalData,
                                            cardData:
                                              JSON.stringify(deserialized1024),
                                            cardDataType: '1024',
                                            originCardDataType: '1024',
                                          };
                                        }
                                      }

                                      console.log(room);

                                      setRoomInfo({
                                        id: room?.id,
                                        name: room?.name,
                                        number: room?.number,
                                        order: room?.order,
                                        key: room?.key,
                                        encryptionKey: room?.encryptionKey,
                                        originName: room?.name,
                                        originNumber: room?.number,
                                        originOrder: room?.order,
                                        originKey: room?.key,
                                        originEncryptionKey:
                                          room?.encryptionKey,
                                        originCardData: room?.cardData,
                                        originCardDataType: null,
                                        roomDevices: room?.roomDevices,
                                        ...swapAdditionalData,
                                      });
                                      setOpenUpdateModal(!openUpdateModal);
                                    }
                                    if (selectedControl === 'first') {
                                      setRoomInfo((prev) => ({
                                        ...prev,
                                        startRoomId: room?.id,
                                        startRoomName: room?.name,
                                      }));
                                      setSelectedControl('last');
                                      console.log('FIRST', room?.id);
                                    }

                                    if (selectedControl === 'last') {
                                      setRoomInfo((prev) => ({
                                        ...prev,
                                        lastRoomId: room?.id,
                                        lastRoomName: room?.name,
                                      }));
                                      setSelectedControl('');
                                      setSelectedModal('RCU_SYNC_A_TO_B');
                                    }
                                  }}
                                >
                                  <FlexCol
                                    style={{
                                      width: 'inherit',
                                      height: 'inherit',
                                      textAlign: 'left',
                                    }}
                                  >
                                    <FlexSpaceBetweenRow
                                      style={{
                                        alignItems: 'center',
                                        width: 'inherit',
                                        height: '48px',
                                      }}
                                    >
                                      <Typography>{room?.name}</Typography>

                                      <IconButton
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          zIndex: 99,
                                        }}
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          e.preventDefault();

                                          if (!selectedControl) {
                                            setSelectedRoom(room);
                                            setOpenDeleteModal(true);
                                          }
                                        }}
                                      >
                                        <Delete
                                          style={{
                                            color: 'rgba(160, 0, 32, 0.8)',
                                          }}
                                        />
                                      </IconButton>
                                    </FlexSpaceBetweenRow>
                                    <Typography
                                      style={{
                                        height: '56px',
                                        wordBreak: 'keep-all',
                                        fontSize: '0.875rem',
                                        color: '#efefef',
                                      }}
                                    >
                                      {room?.roomType?.name}
                                    </Typography>

                                    <FlexSpaceBetweenRow>
                                      <Typography
                                        style={{
                                          height: '24px',
                                          wordBreak: 'keep-all',
                                          fontSize: '0.875rem',
                                        }}
                                      >
                                        {room?.number}
                                      </Typography>
                                      <Typography
                                        style={{
                                          height: '24px',
                                          wordBreak: 'keep-all',
                                          fontSize: '0.875rem',
                                        }}
                                      >
                                        {room?.order}
                                      </Typography>
                                    </FlexSpaceBetweenRow>
                                  </FlexCol>
                                </RoomInnerCard>
                              </RoomCard>
                            );
                          })}
                        </Fragment>
                      );
                    })}

                  {/* {sortType === 'roomType' && (
                    <Fragment>
                      {roomsWithRoomType?.map((roomType) => {
                        return (
                          <Fragment key={roomType?.id}>
                            {roomType?.rooms?.map((room) => {
                              return (
                                <RoomCard key={room?.id}>
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '8px',
                                      backgroundColor: '#dedede',
                                    }}
                                  />
                                  <RoomInnerCard
                                    data={room?.cardData}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      let cardData = room?.cardData;

                                      console.log(cardData);

                                      if (isLegacyCardData(cardData)) {
                                        console.log('Legacy Data Found');
                                        console.log(cardData);
                                      }

                                      if (isSerializedData(cardData)) {
                                        cardData = JSON.stringify(
                                          deserialize512(cardData),
                                        );
                                        console.log('Serialized Data Found.');
                                        console.log(cardData);

                                        const firstSector =
                                          JSON?.parse(cardData)?.[0];
                                        const firstSectorStr = firstSector.map(
                                          (i) =>
                                            i.toString(16)?.length === 1
                                              ? `0${i.toString(16)}`
                                              : i.toString(16),
                                        );
                                        console.log(firstSector);
                                        console.log(firstSectorStr);
                                        console.log(firstSectorStr.join(''));
                                      }

                                      setRoomInfo({
                                        id: room?.id,
                                        name: room?.name,
                                        number: room?.number,
                                        order: room?.order,
                                        key: room?.key,
                                        encryptionKey: room?.encryptionKey,
                                        cardData: cardData,
                                        originName: room?.name,
                                        originNumber: room?.number,
                                        originOrder: room?.order,
                                        originKey: room?.key,
                                        originEncryptionKey:
                                          room?.encryptionKey,
                                        originCardData: room?.cardData,
                                      });
                                      setOpenUpdateModal(!openUpdateModal);
                                    }}
                                  >
                                    <FlexCol
                                      style={{
                                        width: 'inherit',
                                        height: 'inherit',
                                        textAlign: 'left',
                                      }}
                                    >
                                      <FlexSpaceBetweenRow
                                        style={{
                                          alignItems: 'center',
                                          width: 'inherit',
                                          height: '48px',
                                        }}
                                      >
                                        <Typography>{room?.name}</Typography>
                                        <IconButton
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            zIndex: 99,
                                          }}
                                          onClick={async (e) => {
                                            e.stopPropagation();

                                            await emitDeleteRoom({
                                              variables: {
                                                id: room?.id,
                                              },
                                            });
                                          }}
                                        >
                                          <Delete
                                            style={{
                                              color: 'rgba(224, 0, 32, 0.76)',
                                            }}
                                          />
                                        </IconButton>
                                      </FlexSpaceBetweenRow>
                                      <Typography
                                        style={{
                                          height: '56px',
                                          wordBreak: 'keep-all',
                                          fontSize: '0.875rem',
                                        }}
                                      >
                                        {roomType?.name}
                                      </Typography>
                                      <FlexSpaceBetweenRow>
                                        <Typography
                                          style={{
                                            height: '24px',
                                            wordBreak: 'keep-all',
                                            fontSize: '0.875rem',
                                          }}
                                        >
                                          {room?.number}
                                        </Typography>
                                        <Typography
                                          style={{
                                            height: '24px',
                                            wordBreak: 'keep-all',
                                            fontSize: '0.875rem',
                                          }}
                                        >
                                          {room?.order}
                                        </Typography>
                                      </FlexSpaceBetweenRow>
                                    </FlexCol>
                                  </RoomInnerCard>
                                </RoomCard>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  )} */}
                </FlexRow>
              </FlexCol>
            ) : (
              <FlexCenteredCol style={{ height: '100vh' }}>
                <Typography align="center" variant="h4">
                  {`키오스크가 생성되어 있지 않습니다`}
                </Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ marginTop: '16px' }}
                >
                  {`하단의 키오스크 설정에서 키오스크를 생성해주세요`}
                </Typography>
              </FlexCenteredCol>
            ))}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnack}
            autoHideDuration={2000}
            onClose={() => setIsSnack(false)}
            key={'top-center'}
          >
            <Alert onClose={() => setIsSnack(false)} severity={snack}>
              설정이 완료되었습니다
            </Alert>
          </Snackbar>
        </ComponentContainer>
      )}

      {selectedControl && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '168px',
            background: '#f1f1f1',
            borderRadius: '32px 32px 0 0',
            bottom: '0',
            zIndex: 9999,
          }}
        >
          <FlexCol style={{ height: '256px' }}>
            {(selectedControl === 'first' || selectedControl === 'last') && (
              <Typography
                align="center"
                variant="h5"
                style={{ fontWeight: 'bold', margin: '24px 0 0 0' }}
              >
                {selectedControl === 'first' && '처음'}
                {selectedControl === 'last' && '마지막'} 객실을 선택하세요...
              </Typography>
            )}
            <FlexCenteredRow>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                  height: '56px',
                  margin: '24px',
                  borderRadius: '14px',
                }}
                onClick={() => {
                  setSelectedControl('');
                  setRoomInfo(roomInfoInit);
                }}
              >
                <Typography variant="h6">취소</Typography>
              </Button>
            </FlexCenteredRow>
          </FlexCol>
        </div>
      )}
    </WrapperContainer>
  );
};

export default Room;
