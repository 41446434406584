import { createTheme, Theme } from '@material-ui/core';

const venditFontFamily = `Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, sans-serif`;

const fontOption: any = {
  fontFamily: venditFontFamily,
  fontWeight: 'initial',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
};

const dragDisabledOption: any = {
  userSelect: 'none',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  WebkitUserDrag: 'none',
  MozWindowDragging: 'no-drag',
};

export const muiTheme: Theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(144, 144, 160)',
    },
  },
  typography: {
    fontFamily: venditFontFamily,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          ...fontOption,
          margin: 0,
          padding: 0,
          fontSize: '10px',
        },
        body: {
          ...fontOption,
          padding: 0,
          margin: '0 auto',
          ...dragDisabledOption,
        },
        img: {
          ...dragDisabledOption,
        },
      },
    },
    MuiFormControl: {
      root: {
        ...fontOption,
      },
    },
    MuiInputBase: {
      root: {
        ...fontOption,
      },
    },
    MuiFormLabel: {
      root: {
        ...fontOption,
      },
    },
    MuiTypography: {
      root: {
        ...fontOption,
      },
      button: {
        ...fontOption,
      },
      body1: {
        ...fontOption,
      },
      body2: {
        ...fontOption,
      },
      h1: {
        ...fontOption,
      },
      h2: {
        ...fontOption,
      },
      h3: {
        ...fontOption,
      },
      h4: {
        ...fontOption,
      },
      h5: {
        ...fontOption,
      },
      h6: {
        ...fontOption,
      },
    },
  },
});
