import { useMutation, useQuery } from '@apollo/client';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMemo, useState } from 'react';
import { ComponentContainer, FlexCol } from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import { AccoTable } from '../../components/Table';
import { GET_ALL_ACCOMMODATIONS } from '../../graphql/query';
import {
  ACTIVATE_LICENSE,
  CREATE_CONCIERGE_CONFIG,
  INACTIVATE_LICENSE,
  MIGRATE_SALE_AMOUNT_INCLUDING_COMMISSION_AMOUNT,
  ProductType,
  UPDATE_CONCIERGE_CONFIG,
} from '../../graphql/mutation';

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const AdminAccoControl = () => {
  const { loading, data, error, refetch } = useQuery(GET_ALL_ACCOMMODATIONS);
  const onLicenseToggle = async (data: any) => {
    await refetch();
  };
  const [emitActivateLicense, { loading: activatingLicense }] = useMutation(
    ACTIVATE_LICENSE,
    {
      onCompleted: onLicenseToggle,
    },
  );

  const [emitInactivateLicense, { loading: inactivatingLicense }] = useMutation(
    INACTIVATE_LICENSE,
    {
      onCompleted: onLicenseToggle,
    },
  );

  const [emitCreateConciergeConfig] = useMutation(CREATE_CONCIERGE_CONFIG, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const [emitUpdateConciergeConfig] = useMutation(UPDATE_CONCIERGE_CONFIG, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const [accommodations] = useMemo(() => {
    if (loading) return [[], []];

    const allAccommodations = data?.['getAccommodations']?.edges?.map(
      (edge) => edge?.node,
    );

    const sortedAccommodations = allAccommodations
      ?.sort((a: { address1: string }, b: { address1: string }) => {
        if (a?.address1 < b?.address1) return -1;
        if (a?.address1 > b?.address1) return 1;
        if (a?.address1 === b?.address1) return 0;
      })
      ?.map((acco, idx) => {
        return {
          no: idx + 1,
          roomSum: acco.rooms.length,
          ...acco,
        };
      });

    return [sortedAccommodations];
  }, [data]);

  const [isSnack, setIsSnack] = useState(false);

  const columns = useMemo(() => {
    return [
      { accessor: 'no', Header: '순서', Filter: DefaultColumnFilter },
      { accessor: 'name', Header: '지점명', Filter: DefaultColumnFilter },
      { accessor: 'address1', Header: '주소', Filter: DefaultColumnFilter },
      {
        accessor: 'address2',
        Header: '상세 주소',
        Filter: DefaultColumnFilter,
      },
      { accessor: 'contact', Header: '연락처', Filter: DefaultColumnFilter },
      {
        accessor: 'csContact',
        Header: '문의 전화번호',
        Filter: DefaultColumnFilter,
      },
      {
        accessor: 'registererName',
        Header: '사업자명',
        Filter: DefaultColumnFilter,
      },
      {
        accessor: 'registrationNumber',
        Header: '사업자번호',
        Filter: DefaultColumnFilter,
      },
      { accessor: 'roomSum', Header: '객실 수', Filter: DefaultColumnFilter },
      {
        accessor: 'isActive',
        Cell: (data) => {
          const accommodationId = data.row.original.id;
          return data?.value ? (
            <>
              <span>사용</span>&nbsp;&nbsp;
              <button
                onClick={() => {
                  emitInactivateLicense({
                    variables: {
                      accommodationId,
                      productType: ProductType.VPMS,
                    },
                  });
                }}
              >
                비활성화
              </button>
            </>
          ) : (
            <>
              <span>미사용</span>&nbsp;&nbsp;
              <button
                onClick={() => {
                  emitActivateLicense({
                    variables: {
                      accommodationId,
                      productType: ProductType.VPMS,
                    },
                  });
                }}
              >
                활성화
              </button>
            </>
          );
        },
        Header: '클라우드 활성화 여부',
        Filter: DefaultColumnFilter,
      },
      {
        accessor: 'isCmsActive',
        Cell: (data) => {
          const accommodationId = data.row.original.id;
          return data?.value ? (
            <>
              <span>사용</span>&nbsp;&nbsp;
              <button
                onClick={() => {
                  emitInactivateLicense({
                    variables: {
                      accommodationId,
                      productType: ProductType.VCMS,
                    },
                  });
                }}
              >
                비활성화
              </button>
            </>
          ) : (
            <>
              <span>미사용</span>&nbsp;&nbsp;
              <button
                onClick={() => {
                  emitActivateLicense({
                    variables: {
                      accommodationId,
                      productType: ProductType.VCMS,
                    },
                  });
                }}
              >
                활성화
              </button>
            </>
          );
        },
        Header: '채널매니저 활성화 여부',
        Filter: DefaultColumnFilter,
      },
      {
        accessor: 'isBookingEngineEnabled',
        Cell: (row) => {
          return row?.value ? '사용' : '미사용';
        },
        Header: '부킹엔진 사용여부',
        Filter: DefaultColumnFilter,
      },
      {
        accessor: 'conciergePaymentConfig',
        Cell: (data) => {
          const accommodationId = data.row.original.id;

          return data?.value ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const cpid = e.target[0].value;
                emitUpdateConciergeConfig({
                  variables: {
                    input: {
                      accommodationId,
                      cpid,
                    },
                  },
                });
              }}
              style={{ display: 'flex' }}
            >
              <input name="cpid" defaultValue={data?.value} />
              <button type="submit">변경</button>
            </form>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const cpid = e.target[0].value;
                emitCreateConciergeConfig({
                  variables: {
                    input: [
                      {
                        accommodationId,
                        cpid,
                      },
                    ],
                  },
                });
              }}
              style={{ display: 'flex' }}
            >
              <input name="cpid" />
              <button type="submit">등록</button>
            </form>
          );
        },
        Header: '컨시어지 사용여부',
        Filter: DefaultColumnFilter,
      },
    ];
  }, [accommodations, activatingLicense, inactivatingLicense]);

  return (
    <FlexCol
      style={{ height: 'inherit', backgroundColor: 'rgb(208, 208, 208)' }}
    >
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <ComponentContainer style={{ height: '100%', overflow: 'scroll' }}>
            <AccoTable columns={columns} data={accommodations} />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isSnack}
              autoHideDuration={2000}
              onClose={() => setIsSnack(false)}
              key={'top-center'}
            >
              <Alert onClose={() => setIsSnack(false)} severity="success">
                원격 요청이 전송되었습니다
              </Alert>
            </Snackbar>
          </ComponentContainer>
        </>
      )}
    </FlexCol>
  );
};

export default AdminAccoControl;

