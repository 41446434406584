import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import { CONSTANTS } from '../../commons/constants';
import {
  ComponentContainer,
  FlexCenteredCol,
  FlexCol,
  FlexRow,
  WrapperContainer,
} from '../../components/Layout';
import { GET_MY_ACCOMMODATIONS } from '../../graphql/query';
import { history } from '../../routes';
import SessionOut from '../../components/SessionOut';
import Loading from '../../components/Loading';

const Accommodations = () => {
  const { loading, data, error } = useQuery(GET_MY_ACCOMMODATIONS);

  return (
    <WrapperContainer>
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            height: 'inherit',
            overflow: 'scroll',
          }}
        >
          {data?.getMyAccommodations?.length > 0 ? (
            <ComponentContainer style={{ height: '100vh' }}>
              <FlexCol>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ marginTop: '64px' }}
                >
                  지점 선택
                </Typography>
                <FlexRow
                  style={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginTop: '88px',
                  }}
                >
                  {data?.getMyAccommodations?.map((accommodation: any) => {
                    return (
                      <Button
                        key={accommodation?.id}
                        variant="contained"
                        color="primary"
                        style={{
                          width: '50vw',
                          maxWidth: '320px',
                          height: '56px',
                        }}
                        onClick={() => {
                          history.push(
                            `${CONSTANTS.PATH.ACCOMMODATION}/${accommodation.id}`,
                          );
                        }}
                      >
                        <img
                          src={accommodation?.thumbnail}
                          style={{
                            width: '32px',
                            borderRadius: '50%',
                            marginRight: '24px',
                          }}
                        />
                        <Typography>{accommodation?.name} 지점</Typography>
                      </Button>
                    );
                  })}
                </FlexRow>
              </FlexCol>
            </ComponentContainer>
          ) : (
            <ComponentContainer style={{ justifyContent: 'center' }}>
              <Typography align="center" variant="h4">
                지점이 없습니다.
              </Typography>
            </ComponentContainer>
          )}
        </FlexCenteredCol>
      )}
    </WrapperContainer>
  );
};

export default Accommodations;
