// @ts-nocheck
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { CONSTANTS } from '../../commons/constants';
import {
  ComponentContainer,
  Flex,
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
  FlexSpaceBetweenRow,
  WrapperContainer,
} from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import {
  CREATE_KIOSK,
  CREATE_KIOSK_ARTICLE,
  CREATE_KIOSK_MAIN_ARTICLE,
  CREATE_TERMS_ARTICLE,
  DELETE_KIOSK,
  SEND_CONTROL_PACKET,
  SET_KIOSK_OPTIONS,
  UPDATE_KIOSK,
  UPDATE_KIOSK_ARTICLE,
  UPLOAD_IMAGE_FILE_TO_ARTICLE,
} from '../../graphql/mutation';
import { GET_SINGLE_ACCOMMODATION } from '../../graphql/query';
import kioskImage from '../../images/vendit-kiosk.png';
import { KioskConfig } from '../../typedefs/types';
import { convertKioskConfig, isEmpty } from '../../utils/utils';
import KioskHomeArticles from './KioskHomeArticles';
import KioskMainArticles from './KioskMainArticles';
import { KioskTermsArticles } from './KioskTermsArticles';

const RowLine = styled.div`
  border: 1px solid #eee;
  margin: 8px;
`;

const CustomFlex = styled(Flex)`
  margin: 8px 0;
  padding: 16px 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #d3d3d8;
`;

const CustomInnerFlex = styled(Flex)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomFormControl = styled(FormControl)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const HardwareInput = styled(TextField)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomSelect = styled(Select)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const commonBtnStyles = {};

const Kiosk = () => {
  const { id: accommodationId } = useParams<{ id: string }>();

  const {
    loading: myAccommodationsLoading,
    data: myAccommodationsData,
    error: myAccommodationsError,
    refetch,
  } = useQuery(GET_SINGLE_ACCOMMODATION, {
    variables: {
      accommodationId: accommodationId,
    },
  });

  const onCompleteSave = async (data: any) => {
    await refetch();
  };

  const [emitCreateKiosk] = useMutation(CREATE_KIOSK, {
    onCompleted: onCompleteSave,
  });
  const [emitUpdateKiosk] = useMutation(UPDATE_KIOSK, {
    onCompleted: onCompleteSave,
  });
  const [emitDeleteKiosk] = useMutation(DELETE_KIOSK, {
    onCompleted: onCompleteSave,
  });

  const [emitCreateKioskHomeArticle] = useMutation(CREATE_KIOSK_ARTICLE, {
    onCompleted: onCompleteSave,
  });
  const [emitCreateKioskMainArticle] = useMutation(CREATE_KIOSK_MAIN_ARTICLE, {
    onCompleted: onCompleteSave,
  });
  const [emitCreateTermsArticle] = useMutation(CREATE_TERMS_ARTICLE, {
    onCompleted: onCompleteSave,
  });
  const [emitUpdateKioskArticle] = useMutation(UPDATE_KIOSK_ARTICLE, {
    onCompleted: onCompleteSave,
  });
  const [emitUploadImageFile, { loading, error }] = useMutation(
    UPLOAD_IMAGE_FILE_TO_ARTICLE,
  );

  const [emitUpdateKioskOptions] = useMutation(SET_KIOSK_OPTIONS, {
    onCompleted: onCompleteSave,
  });

  const [emitSendKioskControlPacket] = useMutation(SEND_CONTROL_PACKET);

  const sendKioskControlPacket = async ({
    args,
    kioskId,
  }: {
    kioskId: string;
    args: {
      controlType: string;
      id?: string;
      isFullyPaid?: boolean;
      reservationType?: string;
      status?: string;
      type?: string;
      amount?: number;
      outstandingAmount?: number;
    };
  }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ ...args }),
        },
      });

      renderSnack();
    } catch (err) {
      console.error(err);
    }
  };

  const reqVersionUpdateV1ToKiosk = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'updateKiosk' },
    });
  };

  const reqVersionUpdateV2ToKiosk = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'updateKioskV2' },
    });
  };
  const reqUploadLogFromKiosk = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'uploadLog' },
    });
  };

  const reqVersionUpdateToCCUClient = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'updateCCU' },
    });
  };

  const reqRefreshToCCUClient = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'restartCCU' },
    });
  };

  const reqLogoutToCCUClient = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: { controlType: 'logoutCCU' },
    });
  };

  const reqPrintReceipt = async ({ kioskId }: { kioskId: any }) => {
    await sendKioskControlPacket({
      kioskId,
      args: {
        controlType: 'printReceipt',
        id: '518e0b70-6ad3-11ec-9537-3d3a0bd07d9f',
        isFullyPaid: true,
        reservationType: 'rent',
        status: 'paid',
        type: 'creditCard',
        amount: 20000,
        outstandingAmount: 0,
      },
    });
  };

  const [selectKiosk, setSelectKiosk] = useState<any>(null);
  const [isSnack, setIsSnack] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultInputValues, setDefaultInputValues] = useState<any>({});
  const [homeInputValues, setHomeInputValues] = useState<any>({});
  const [mainInputValues, setMainInputValues] = useState<any>({});
  const [termsInputValues, setTermsInputValues] = useState<any>({});
  const [imageFile, setImageFile] = useState();
  const [kioskDefaultConfig, setKioskDefaultConfig] = useState<any>({});
  const [kioskOptionConfig, setKioskOptionConfig] = useState<KioskConfig>({});

  const accommodation =
    !myAccommodationsLoading &&
    myAccommodationsData?.['getSingleAccommodation'];

  const kioskHomeArticle = accommodation?.kioskArticle;

  const kioskMainArticle = accommodation?.kioskMainArticle;

  const termsArticle = accommodation?.termsArticle;

  const kiosks = accommodation?.kiosks;

  const { id: homeId } = !!kioskHomeArticle && kioskHomeArticle;

  const { id: mainId, attachments: mainAttachments } =
    !!kioskMainArticle && kioskMainArticle;

  const { id: termsId } = !!termsArticle && termsArticle;

  const handleChange = (prop: any) => (event: any) => {
    const prevValues = { ...defaultInputValues };

    const currentProp = prevValues[prop];

    if (prop?.match(/use/gi)) {
      setDefaultInputValues({
        ...prevValues,
        [prop]: event.target.checked,
      });
    } else if (prop?.match(/PayList/gi)) {
      if (
        (currentProp.length === 3 &&
          currentProp.find((i: any) => i !== event)) ||
        event === 'deleteAll'
      )
        return setDefaultInputValues({ ...prevValues, [prop]: [] });

      setDefaultInputValues({
        ...prevValues,
        [prop]: [...currentProp, event],
      });
    } else if (prop?.match(/relayConnectList/gi)) {
      if (event === 'change') {
        if (currentProp?.[0] === 'cash') {
          return setDefaultInputValues({
            ...prevValues,
            [prop]: ['roomKey', 'cash'],
          });
        }
        return setDefaultInputValues({
          ...prevValues,
          [prop]: ['cash', 'roomKey'],
        });
      }

      setDefaultInputValues({
        ...prevValues,
        [prop]: [...currentProp, event],
      });
    } else if (prop?.match(/Layout/gi)) {
      if (
        (currentProp.length === 3 &&
          currentProp.find((i: any) => i !== event)) ||
        event === 'deleteAll'
      )
        return setDefaultInputValues({ ...prevValues, [prop]: [] });

      setDefaultInputValues({
        ...prevValues,
        [prop]: [...currentProp, event],
      });
    } else if (prop?.match(/billDispenserStrategy/gi)) {
      let calculatedBillDispenserStrategy = Array.from(currentProp);
      if (event === 'firstBillUnit')
        calculatedBillDispenserStrategy[0] =
          !calculatedBillDispenserStrategy[0];
      if (event === 'secondBillUnit')
        calculatedBillDispenserStrategy[1] =
          !calculatedBillDispenserStrategy[1];
      setDefaultInputValues({
        ...prevValues,
        [prop]: calculatedBillDispenserStrategy,
      });
    } else if (prop?.match(/Contact|SleepsStrategy/gi)) {
      let calculatedCollectContact = Array.from(currentProp);
      if (event === 'reservation')
        calculatedCollectContact[0] = !calculatedCollectContact[0];
      if (event === 'checkIn')
        calculatedCollectContact[1] = !calculatedCollectContact[1];
      setDefaultInputValues({
        ...prevValues,
        [prop]: calculatedCollectContact,
        mustCollectContact:
          event === 'mustCollectContact'
            ? !prevValues['mustCollectContact']
            : prevValues['mustCollectContact'],
      });
    } else if (prop?.match(/restrict/gi)) {
      if (event === 'selectAll') {
        return setDefaultInputValues((prev: any) => {
          let arr: number[] = [];
          for (let i = 0; i < 64; i++) {
            arr.push(i);
          }
          return { ...prev, [prop]: arr.join(',') };
        });
      }
      if (event === 'deleteAll') {
        return setDefaultInputValues((prev: any) => {
          return { ...prev, [prop]: '' };
        });
      }

      let blockAddresses: string[] = Array.from(currentProp?.split(','));

      blockAddresses = [...new Set(blockAddresses)];

      if (!event.includes('selectSector')) {
        if (blockAddresses.includes(event)) {
          blockAddresses = blockAddresses.filter((block) => block !== event);
        } else {
          blockAddresses.push(event);
        }
      } else {
        const eventAddresses = event.split('$')[1].split(',');

        eventAddresses.forEach((eventAddr: string) => {
          blockAddresses.push(eventAddr);
        });

        blockAddresses = [...new Set(blockAddresses)];
      }
      setDefaultInputValues((prev: any) => {
        return {
          ...prev,
          [prop]: blockAddresses.toString(),
        };
      });
    } else {
      setDefaultInputValues({
        ...prevValues,
        [prop]: event.target.value,
      });
    }
  };

  const handleHomeChange = (prop: any) => (event: any) => {
    setHomeInputValues({
      ...homeInputValues,
      [prop]: event.target.value,
    });
  };

  const handleMainChange =
    (prop: any, additionalProp?: any) => (event: any) => {
      if (prop?.match(/use/gi)) {
        setMainInputValues({
          ...mainInputValues,
          [prop]: event.target.checked,
        });
      } else if (prop?.match(/list/gi)) {
        if (prop === 'onDeleteReservationListAll')
          return setMainInputValues({
            ...mainInputValues,
            ['listInReservationSub']: [],
          });

        setMainInputValues({
          ...mainInputValues,
          [prop]: [...mainInputValues[prop], additionalProp],
        });
      } else {
        setMainInputValues({
          ...mainInputValues,
          [prop]: event.target.value,
        });
      }
    };

  const handleTermsChange =
    (prop: any, additionalProp?: any) => (event: any) => {
      setTermsInputValues({
        ...termsInputValues,
        [prop]: event.target.value,
      });
    };

  const handleImageFileChange = (event: any) => {
    const fileReader = new FileReader();
    const imageForUpload = event.target.files?.[0];

    if (!imageForUpload) return;
    setImageFile(imageForUpload);
    fileReader.readAsDataURL(imageForUpload);

    // fileReader.addEventListener("load", () => {
    //   setPreviewImage({
    //     image: fileReader.result,
    //     isChanged: true,
    //   });
    // });
  };

  const renderSnack = () => {
    setIsSnack(true);
    setTimeout(() => {
      setIsSnack(false);
    }, 1250);
  };

  const onClickUpdateDefaultKioskConfig = async () => {
    let changedKioskConfig = {
      id: kioskDefaultConfig?.id,
    };

    Object.keys(kioskDefaultConfig).forEach((element) => {
      if (kioskDefaultConfig[element] !== defaultInputValues[element]) {
        if (
          element === 'name' ||
          element === 'type' ||
          element === 'location'
        ) {
          // @ts-ignore
          changedKioskConfig[element] = defaultInputValues[element];
        }
      }
    });

    const result = await emitUpdateKiosk({
      variables: {
        input: changedKioskConfig,
      },
    });

    if (result) renderSnack();
  };

  const updateKioskHomeArticle = async () => {
    let isSaved = false;

    if (imageFile) {
      await emitUploadImageFile({
        variables: {
          articleId: homeId,
          files: [imageFile],
          persistFiles: [],
        },
      });
      isSaved = true;
    }

    const modifiedSubject = `${homeInputValues?.mainTitle}\\\\${homeInputValues?.subTitle}`;
    const modifiedContent = JSON.stringify({
      mainTitleSize: homeInputValues?.mainTitleSize,
      subTitleSize: homeInputValues?.subTitleSize,
    });

    const result = await emitUpdateKioskArticle({
      variables: {
        input: {
          id: homeId,
          subject: modifiedSubject,
          content: modifiedContent,
        },
      },
    });

    if (isSaved || result?.data) renderSnack();
  };

  const updateKioskMainArticle = async () => {
    let isSaved = false;

    if (imageFile) {
      await emitUploadImageFile({
        variables: {
          articleId: mainId,
          files: [imageFile],
          persistFiles: [],
        },
      });
      isSaved = true;
    }

    const modifiedSubject = `${mainInputValues?.mainTitle}\\\\${mainInputValues?.subTitle1}\\\\${mainInputValues?.subTitle2}`;

    const {
      mainTitleSize,
      subTitle1Size,
      subTitle2Size,

      listInReservationSub,
      useReservationSub,
      useOnSitePaymentSub,
      useCheckOutSub,
      useRentSub,
      useLodgeSub,

      useReservationBtnLogo,
      reservationBtnColor,
      reservationInnerFontSize,
      reservation,
      reservationDisableInnerFontSize,
      reservationDisableInnerText,

      useCheckInBtnLogo,
      checkInBtnColor,
      checkInInnerFontSize,
      checkIn,
      checkInDisableInnerFontSize,
      checkInDisableInnerText,

      useCheckOutBtnLogo,
      checkOutBtnColor,
      checkOutInnerFontSize,
      checkOut,
      checkOutDisableInnerFontSize,
      checkOutDisableInnerText,

      useRentBtnLogo,
      rentBtnColor,
      rentInnerFontSize,
      rent,
      rentDisableInnerFontSize,
      rentDisableInnerText,

      useLodgeBtnLogo,
      lodgeBtnColor,
      lodgeInnerFontSize,
      lodge,
      lodgeDisableInnerFontSize,
      lodgeDisableInnerText,

      reservationPopup,
      reservationPopupFontSize,
      checkInPopup,
      checkInPopupFontSize,
      rentPopup,
      rentPopupFontSize,
      lodgePopup,
      lodgePopupFontSize,
      checkOutPopup,
      checkOutPopupFontSize,
    } = mainInputValues;

    const modifiedContent = JSON.stringify({
      mainTitleSize,
      subTitle1Size,
      subTitle2Size,
      listInReservationSub,
      useReservationSub,
      useOnSitePaymentSub,
      useCheckOutSub,
      useRentSub,
      useLodgeSub,

      useReservationBtnLogo,
      reservationBtnColor,
      reservationInnerFontSize,
      reservation,
      reservationDisableInnerFontSize,
      reservationDisableInnerText,

      useCheckInBtnLogo,
      checkInBtnColor,
      checkInInnerFontSize,
      checkIn,
      checkInDisableInnerFontSize,
      checkInDisableInnerText,

      useCheckOutBtnLogo,
      checkOutBtnColor,
      checkOutInnerFontSize,
      checkOut,
      checkOutDisableInnerFontSize,
      checkOutDisableInnerText,

      useRentBtnLogo,
      rentBtnColor,
      rentInnerFontSize,
      rent,
      rentDisableInnerFontSize,
      rentDisableInnerText,

      useLodgeBtnLogo,
      lodgeBtnColor,
      lodgeInnerFontSize,
      lodge,
      lodgeDisableInnerFontSize,
      lodgeDisableInnerText,

      reservationPopup,
      reservationPopupFontSize,
      checkInPopup,
      checkInPopupFontSize,
      rentPopup,
      rentPopupFontSize,
      lodgePopup,
      lodgePopupFontSize,
      checkOutPopup,
      checkOutPopupFontSize,
    });

    const result = await emitUpdateKioskArticle({
      variables: {
        input: {
          id: mainId,
          subject: modifiedSubject,
          content: modifiedContent,
        },
      },
    });

    if (isSaved || result?.data) renderSnack();
  };

  const updateTermsArticle = async () => {
    let isSaved = false;

    const { title, terms } = termsInputValues;

    const content = JSON.stringify({ title, terms });

    const result = await emitUpdateKioskArticle({
      variables: {
        input: {
          id: termsId,
          content,
        },
      },
    });

    if (isSaved || result?.data) renderSnack();
  };

  const onUpdateKioskOptions = async () => {
    let changedKioskOptions: any[] = [];
    let filterOptions = '';

    Object.keys(kioskOptionConfig).forEach((element) => {
      if (kioskOptionConfig[element] != defaultInputValues[element]) {
        if (
          element.match(
            /Layout|ContactStrategy|SleepsStrategy|billDispenserStrategy|onSiteAdditionalPayList|onSiteInstantPayList|relayConnectList/g,
          )
        ) {
          filterOptions = JSON.stringify(defaultInputValues[element]);
        } else if (element.match(/use|must/g)) {
          filterOptions = defaultInputValues[element].toString();
        } else if (element.match(/restrict/g)) {
          filterOptions = defaultInputValues[element]
            .split(',')
            .filter((val) => val !== '')
            .sort((a, b) => parseInt(a) - parseInt(b))
            .toString();
        } else if (element.match(/customCCUOption|customKioskOption/g)) {
          filterOptions = JSON.stringify(defaultInputValues[element]);
        } else {
          filterOptions = defaultInputValues[element];
        }
        changedKioskOptions.push({ key: element, value: filterOptions });
      }
    });

    if (changedKioskOptions?.length > 0) {
      const result = await emitUpdateKioskOptions({
        variables: {
          inputs: [...changedKioskOptions],
          kioskId: kioskDefaultConfig?.id,
        },
      });

      if (result) renderSnack();
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
  };

  const getFormByConfig = (config: string, value: any, onChange: any) => {
    if (config.match(/use/gi)) {
      // @ts-ignore
      return (
        <FormControlLabel
          control={<Checkbox checked={!!value} onChange={onChange} />}
          label={convertKioskConfig(config)}
        />
      );
    } else if (config.match(/PayList/gi)) {
      return (
        <FormGroup>
          <FormGroup>
            <CustomFlex
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'creditCard')}
                    onChange={() => onChange('creditCard')}
                  />
                }
                label={'카드'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'cash')}
                  />
                }
                onChange={() => onChange('cash')}
                label={'현금'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'mileage')}
                    onChange={() => onChange('mileage')}
                  />
                }
                label={'마일리지'}
              />
              <Button
                variant="contained"
                onClick={() => onChange('deleteAll')}
                style={{ height: '40px', marginLeft: '16px' }}
              >
                순서 변경
              </Button>
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/relayConnectList/gi)) {
      return (
        <FormGroup>
          <FormGroup>
            <CustomFlex
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              {value?.map((i, idx) => (
                <FlexRow
                  key={i}
                  style={{
                    margin: '0 8px',
                    borderRadius: '8px',
                  }}
                >
                  <FlexCenteredRow
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: '#fff',
                      margin: '0 2px',
                    }}
                  >
                    {idx}
                  </FlexCenteredRow>
                  <FlexRow style={{ fontSize: '1.2rem' }}>
                    {i === 'roomKey' && '객실키'}
                    {i === 'cash' && '현금'}
                  </FlexRow>
                </FlexRow>
              ))}
              <Button
                variant="contained"
                onClick={() => onChange('change')}
                style={{ height: '40px', marginLeft: '16px' }}
              >
                순서 변경
              </Button>
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/Layout/gi)) {
      return (
        <FormGroup>
          <Typography>{`${convertKioskConfig(config)}`}</Typography>
          <FormGroup>
            <CustomFlex
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'reservation')}
                    onChange={() => onChange('reservation')}
                  />
                }
                label={'예약 체크인'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'checkIn')}
                  />
                }
                onChange={() => onChange('checkIn')}
                label={'현장판매'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'checkOut')}
                    onChange={() => onChange('checkOut')}
                  />
                }
                label={'체크아웃'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'rent')}
                    onChange={() => onChange('rent')}
                  />
                }
                label={'대실'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.find((val: any) => val === 'lodge')}
                    onChange={() => onChange('lodge')}
                  />
                }
                label={'숙박'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!value?.find((val: any) => val === 'reservationCheckout')
                    }
                    onChange={() => onChange('reservationCheckout')}
                  />
                }
                label={'예약 체크인 / 체크아웃'}
              />
              <Button
                variant="contained"
                style={{ height: '32px' }}
                onClick={() => onChange('deleteAll')}
              >
                지우기
              </Button>
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/Contact/gi)) {
      return (
        <FormGroup>
          <Typography>
            {`${convertKioskConfig(config)}`}
            <FormControlLabel
              style={{ height: '16px', marginLeft: '1rem' }}
              control={
                <Checkbox
                  checked={!!value?.[2]}
                  onChange={() => onChange('mustCollectContact')}
                />
              }
              label={'필수 여부'}
            />
          </Typography>
          <FormGroup>
            <CustomFlex
              style={{
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[0]}
                    onChange={() => onChange('reservation')}
                  />
                }
                label={'예약 체크인'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[1]}
                    onChange={() => onChange('checkIn')}
                  />
                }
                label={'현장결제'}
              />
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/billDispenserStrategy/gi)) {
      return (
        <FormGroup>
          <Typography>{`${convertKioskConfig(config)}`}</Typography>
          <FormGroup>
            <CustomFlex
              style={{
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[0]}
                    onChange={() => onChange('firstBillUnit')}
                  />
                }
                label={'1번 방출기'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[1]}
                    onChange={() => onChange('secondBillUnit')}
                  />
                }
                label={'2번 방출기'}
              />
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/SleepsStrategy/gi)) {
      return (
        <FormGroup>
          <Typography>{`${convertKioskConfig(config)}`}</Typography>
          <FormGroup>
            <CustomFlex
              style={{
                padding: '4px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[0]}
                    onChange={() => onChange('reservation')}
                  />
                }
                label={'예약 체크인'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!value?.[1]}
                    onChange={() => onChange('checkIn')}
                  />
                }
                label={'현장결제'}
              />
            </CustomFlex>
          </FormGroup>
        </FormGroup>
      );
    } else if (config.match(/icCardReaderType/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="tossPG">토스 페이먼츠</MenuItem>
            <MenuItem value="daouVP">다우VP</MenuItem>
            <MenuItem value="kovan">KOVAN</MenuItem>
            <MenuItem value="ksNet">케이에스넷</MenuItem>
            <MenuItem value="nice">나이스</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/idAuthenticatorType/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="vendit">기본</MenuItem>
            <MenuItem value="suprema">슈프리마</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/qrReaderType/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="vendit">기본</MenuItem>
            <MenuItem value="newland">뉴랜드</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/ccuType/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="none">사용 안함</MenuItem>
            <MenuItem value="Seereal">씨리얼</MenuItem>
            <MenuItem value="TheMR">더엠알</MenuItem>
            <MenuItem value="Icrew">아이크루</MenuItem>
            <MenuItem value="Wooyeon">우연TNE</MenuItem>
            <MenuItem value="Hermes">헤르메스</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/cardType/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="issuance">등록형</MenuItem>
            <MenuItem value="hiOneTicketing">하이원 발급형</MenuItem>
            <MenuItem value="buildOneTicketing">빌드원 발급형</MenuItem>
            <MenuItem value="sindoEDS">신도EDS 발급형</MenuItem>
            <MenuItem value="woongJinINS">웅진INS 발급형</MenuItem>
            <MenuItem value="messerschmitt">메서슈미트 발급형</MenuItem>
            <MenuItem value="vingCard">빙카드 발급형</MenuItem>
            <MenuItem value="jnTech">제이엔테크 발급형</MenuItem>
            <MenuItem value="garam">가람 발급형</MenuItem>
            <MenuItem value="ento">엔토시스템 발급형</MenuItem>
            <MenuItem value="hiOneTheMR">하이원 더엠알 발급형</MenuItem>
            <MenuItem value="daemyungHMS">대명HMS 발급형</MenuItem>
            <MenuItem value="theMRIoT">더엠알 IoT 발급형</MenuItem>
            <MenuItem value="junghwaTech">정화테크 발급형</MenuItem>
            <MenuItem value="themrEurope">더엠알 유럽형</MenuItem>
            <MenuItem value="imGate">아임게이트 발급형</MenuItem>
            <MenuItem value="daesung">대성 발급형</MenuItem>
            <MenuItem value="hiLever">하이레버 발급형</MenuItem>
            <MenuItem value="ygs">더엠알 YGS 발급형</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/theme/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="light">밝은 테마</MenuItem>
            <MenuItem value="dark">어두운 테마</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/receipt/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="always">항상 출력</MenuItem>
            <MenuItem value="onRequested">선택 출력</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/DispensorAmount/gi)) {
      return (
        <CustomFormControl variant="outlined">
          <Select value={value} onChange={onChange}>
            <MenuItem value="1000">1000원</MenuItem>
            <MenuItem value="5000">5000원</MenuItem>
            <MenuItem value="10000">10000원</MenuItem>
          </Select>
        </CustomFormControl>
      );
    } else if (config.match(/port/gi) && !config.match(/ccu/gi)) {
      return (
        <HardwareInput
          value={value}
          onChange={onChange}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginRight: '2px', padding: 0 }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0px',
                  }}
                >
                  COM
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      );
    } else if (config.match(/restrict/gi)) {
      const ALL_SECTORS = CONSTANTS.SECTOR_ADDRESSES.ALL_BLOCKS;
      const FIRST_BLOCKS = CONSTANTS.BLOCK_ADDRESSES.FIRST_BLOCKS;

      const valueArr = value.split(',');

      return (
        <Fragment>
          <FlexRow style={{ alignItems: 'center' }}>
            <Typography>{`${convertKioskConfig(config)}`}</Typography>
            <Button
              variant="contained"
              onClick={() => onChange('selectAll')}
              style={{ height: '40px', marginLeft: '24px' }}
            >
              전체 선택
            </Button>
            <Button
              variant="contained"
              onClick={() => onChange('deleteAll')}
              style={{ height: '40px', marginLeft: '16px' }}
            >
              전체 삭제
            </Button>
          </FlexRow>

          <FormGroup>
            <CustomFlex
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '8px 16px',
                backgroundColor: '#eee',
                borderRadius: '4px',
              }}
            >
              {ALL_SECTORS.map((sector) => {
                const CORRECT_BLOCKS = [
                  sector * 4 + 0,
                  sector * 4 + 1,
                  sector * 4 + 2,
                  sector * 4 + 3,
                ].toString();

                return (
                  <FormGroup
                    key={`sector-group-${sector}`}
                    style={{
                      flexGrow: 1,
                      width: '45%',
                      margin: '8px 16px 8px 16px',
                    }}
                  >
                    <FlexCol key={`sector-${sector}`}>
                      <FlexRow
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '0 12px 0 8px',
                        }}
                      >
                        <Typography>{`섹터 ${sector}`}</Typography>
                        <Button
                          variant="contained"
                          onClick={() =>
                            onChange(`selectSector$${CORRECT_BLOCKS}`)
                          }
                          style={{ height: '32px', marginLeft: '16px' }}
                        >
                          섹터 선택
                        </Button>
                      </FlexRow>
                      <div
                        style={{
                          width: '100%',
                          borderTop: '1px solid #a0a0a0',
                          margin: '8px 0 8px 0',
                        }}
                      />
                      {FIRST_BLOCKS.map((block) => {
                        const calNumber = sector * 4 + block;
                        const calValue = calNumber.toString();

                        return (
                          <FormControlLabel
                            key={`restrict-${calValue}`}
                            control={
                              <Checkbox
                                checked={valueArr.includes(calValue)}
                                onChange={() => onChange(calValue)}
                              />
                            }
                            label={`블록 ${block}`}
                            style={{ minWidth: '100px' }}
                          />
                        );
                      })}
                    </FlexCol>
                  </FormGroup>
                );
              })}
            </CustomFlex>
          </FormGroup>
        </Fragment>
      );
    } else if (config.match(/customTermConfirm/gi)) {
      return (
        <TextField
          multiline
          maxRows={3}
          value={value}
          onChange={onChange}
          variant="outlined"
        />
      );
    } else if (config.match(/customCCUOption|customKioskOption/gi)) {
      return (
        <TextField
          multiline
          minRows={5}
          value={value}
          onChange={onChange}
          variant="outlined"
        />
      );
    } else {
      return (
        <HardwareInput value={value} onChange={onChange} variant="outlined" />
      );
    }
  };

  const onClickKiosk = (index: number) => {
    const accommodation = myAccommodationsData?.['getSingleAccommodation'];
    setDefaultInputValues(() => ({
      ...accommodation?.kioskArticle,
      ...accommodation?.kiosks?.[index],
      ...accommodation?.kiosks?.[index]?.options,
    }));
    setSelectKiosk({
      ...selectKiosk,
      id: accommodation?.kiosks?.[index]?.id,
    });
    setKioskDefaultConfig(accommodation?.kiosks?.[index]);
    setKioskOptionConfig(accommodation?.kiosks?.[index]?.options);
  };

  const createKiosk = async () => {
    await emitCreateKiosk({
      variables: {
        input: {
          accommodationId: accommodation?.id,
          name: '키오스크',
          type: 'hotel',
          location: '1층',
        },
      },
    });
  };

  const deleteKiosk = async (id: string) => {
    await emitDeleteKiosk({
      variables: {
        id: id,
      },
    });
  };

  const parseArticleContent = (article: any) => {
    return !isEmpty(article?.content) ? JSON.parse(article?.content) : {};
  };

  const parseHomeArticle = (article: any) => {
    const homeContent = parseArticleContent(article);

    setHomeInputValues({
      ...article,
      mainTitle: article?.subject?.split('\\\\')?.[0],
      subTitle: article?.subject?.split('\\\\')?.[1],
      subject: homeContent,
      mainTitleSize: homeContent?.['mainTitleSize'] || '',
      subTitleSize: homeContent?.['subTitleSize'] || '',
    });
  };

  const parseMainArticle = (article: any) => {
    const mainContent = parseArticleContent(article);

    setMainInputValues({
      ...article,
      mainTitle: article?.subject?.split('\\\\')?.[0],
      subTitle1: article?.subject?.split('\\\\')?.[1],
      subTitle2: article?.subject?.split('\\\\')?.[2],
      subject: mainContent,
      mainTitleSize: mainContent?.['mainTitleSize'] || '',
      subTitle1Size: mainContent?.['subTitle1Size'] || '',
      subTitle2Size: mainContent?.['subTitle2Size'] || '',
      listInReservationSub: mainContent?.['listInReservationSub'] || [],

      useReservationSub: mainContent?.['useReservationSub'] === true,
      useOnSitePaymentSub: mainContent?.['useOnSitePaymentSub'] === true,
      useCheckOutSub: mainContent?.['useCheckOutSub'] === true,
      useRentSub: mainContent?.['useRentSub'] === true,
      useLodgeSub: mainContent?.['useLodgeSub'] === true,

      useReservationBtnLogo: mainContent?.['useReservationBtnLogo'] === true,
      reservationBtnColor: mainContent?.['reservationBtnColor'] || '',
      reservationInnerFontSize: mainContent?.['reservationInnerFontSize'],
      reservation: mainContent?.['reservation'],
      reservationDisableInnerFontSize:
        mainContent?.['reservationDisableInnerFontSize'],
      reservationDisableInnerText: mainContent?.['reservationDisableInnerText'],

      useCheckInBtnLogo: mainContent?.['useCheckInBtnLogo'] === true,
      checkInBtnColor: mainContent?.['checkInBtnColor'] || '',
      checkInInnerFontSize: mainContent?.['checkInInnerFontSize'],
      checkIn: mainContent?.['checkIn'] || '체크인',
      checkInDisableInnerFontSize: mainContent?.['checkInDisableInnerFontSize'],
      checkInDisableInnerText: mainContent?.['checkInDisableInnerText'],

      useCheckOutBtnLogo: mainContent?.['useCheckOutBtnLogo'] === true,
      checkOutBtnColor: mainContent?.['checkOutBtnColor'] || '',
      checkOutInnerFontSize: mainContent?.['checkOutInnerFontSize'],
      checkOut: mainContent?.['checkOut'] || '체크아웃',
      checkOutDisableInnerFontSize:
        mainContent?.['checkOutDisableInnerFontSize'],
      checkOutDisableInnerText: mainContent?.['checkOutDisableInnerText'],

      useRentBtnLogo: mainContent?.['useRentBtnLogo'] === true,
      rentBtnColor: mainContent?.['rentBtnColor'] || '',
      rentInnerFontSize: mainContent?.['rentInnerFontSize'],
      rent: mainContent?.['rent'] || '대실',
      rentDisableInnerFontSize: mainContent?.['rentDisableInnerFontSize'],
      rentDisableInnerText: mainContent?.['rentDisableInnerText'],

      useLodgeBtnLogo: mainContent?.['useLodgeBtnLogo'] === true,
      lodgeBtnColor: mainContent?.['lodgeBtnColor'] || '',
      lodgeInnerFontSize: mainContent?.['lodgeInnerFontSize'],
      lodge: mainContent?.['lodge'] || '숙박',
      lodgeDisableInnerFontSize: mainContent?.['lodgeDisableInnerFontSize'],
      lodgeDisableInnerText: mainContent?.['lodgeDisableInnerText'],

      reservationPopupFontSize: mainContent?.['reservationPopupFontSize'],
      reservationPopup: mainContent?.['reservationPopup'],

      checkInPopupFontSize: mainContent?.['checkInPopupFontSize'],
      checkInPopup: mainContent?.['checkInPopup'],

      rentPopupFontSize: mainContent?.['rentPopupFontSize'],
      rentPopup: mainContent?.['rentPopup'],

      lodgePopupFontSize: mainContent?.['lodgePopupFontSize'],
      lodgePopup: mainContent?.['lodgePopup'],

      checkOutPopupFontSize: mainContent?.['checkOutPopupFontSize'],
      checkOutPopup: mainContent?.['checkOutPopup'],
    });

    console.log(mainInputValues);
  };

  const parseTermsArticle = (article: any) => {
    const termContent = parseArticleContent(article);

    setTermsInputValues({
      ...article,
      title: termContent?.['title'] || '',
      terms: termContent?.['terms'] || '',
    });
  };

  useEffect(() => {
    const accommodation = myAccommodationsData?.['getSingleAccommodation'];
    const kiosk =
      accommodation?.kiosks?.find(
        (kiosk: any) => kiosk?.id === selectKiosk?.id,
      ) || accommodation?.kiosks?.[0];

    const defaultInputValues = { ...kiosk, ...kiosk?.options };

    setDefaultInputValues(defaultInputValues);
    setKioskDefaultConfig(kiosk);
    setKioskOptionConfig(kiosk?.options);
    parseHomeArticle(accommodation?.kioskArticle);
    parseMainArticle(accommodation?.kioskMainArticle);
    parseTermsArticle(accommodation?.termsArticle);
    setIsLoading(false);
  }, [myAccommodationsData]);

  return (
    <WrapperContainer>
      {myAccommodationsError ? (
        <SessionOut />
      ) : myAccommodationsLoading ? (
        <Loading />
      ) : (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            height: 'inherit',
            overflow: 'scroll',
          }}
        >
          <ComponentContainer style={{ height: '100%' }}>
            {!isLoading && (
              <Fragment>
                {kiosks?.length > 0 ? (
                  <FlexCenteredCol>
                    {/* 지점 내 존재하는 키오스크에 대한 버튼 */}

                    <FlexRow style={{ flexWrap: 'wrap' }}>
                      <>
                        {!loading &&
                          kiosks?.map((kiosk: any, index: number) => {
                            return (
                              <Button
                                key={kiosk?.id}
                                variant="contained"
                                color="primary"
                                onClick={() => onClickKiosk(index)}
                                style={{
                                  margin: '8px',
                                  wordWrap: 'break-word',
                                }}
                              >
                                <img
                                  src={kioskImage}
                                  width="16px"
                                  alt="vendit-kiosk"
                                  style={{ marginRight: '16px' }}
                                />
                                {kiosk?.name}
                                <div
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await deleteKiosk(kiosk?.id);
                                  }}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '32px',
                                    height: '32px',
                                    marginLeft: '16px',
                                    borderRadius: '4px',
                                    backgroundColor: '#eee',
                                    cursor: 'pointer',
                                    zIndex: 99,
                                  }}
                                >
                                  <Delete
                                    style={{
                                      color: 'rgba(224, 0, 32, 0.76)',
                                    }}
                                  />
                                </div>
                              </Button>
                            );
                          })}
                        {!loading && (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ width: '144px', margin: '8px' }}
                            onClick={createKiosk}
                          >
                            키오스크 추가 +
                          </Button>
                        )}
                      </>
                    </FlexRow>

                    <RowLine />

                    {/* 키오스크 기본 설정 */}

                    <Fragment>
                      <FlexSpaceBetweenRow
                        style={{
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          maxHeight: '184px',
                          marginTop: '32px',
                        }}
                      >
                        <FlexRow style={{ alignItems: 'center' }}>
                          <Typography
                            variant="h5"
                            style={{ marginLeft: '32px' }}
                          >
                            {`${kioskDefaultConfig?.name} 기본 설정\n${kioskDefaultConfig?.id}`}
                          </Typography>
                        </FlexRow>

                        <FlexRow
                          style={{ flexWrap: 'wrap', margin: '8px 24px' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqVersionUpdateV1ToKiosk({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            키오스크 업데이트 (구 UI)
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqVersionUpdateV2ToKiosk({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            키오스크 업데이트 (신 UI)
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqUploadLogFromKiosk({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            로그 업로드
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqVersionUpdateToCCUClient({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            CCU 업데이트
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqRefreshToCCUClient({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            CCU 재시작
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqLogoutToCCUClient({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            CCU 로그아웃
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '144px',
                              margin: '8px',
                            }}
                            onClick={() => {
                              reqPrintReceipt({
                                kioskId: kioskDefaultConfig?.id,
                              });
                            }}
                          >
                            영수증 테스트
                          </Button>
                        </FlexRow>
                      </FlexSpaceBetweenRow>

                      <CustomFlex>
                        <FormControl style={{ width: '33%' }}>
                          <Typography>이름</Typography>
                          <HardwareInput
                            value={defaultInputValues.name || ''}
                            onChange={handleChange('name')}
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          style={{ width: '33%', marginLeft: '32px' }}
                        >
                          <Typography>타입</Typography>
                          <CustomSelect
                            value={defaultInputValues.type || ''}
                            onChange={handleChange('type')}
                          >
                            <MenuItem value="motel">
                              1세대 스탠드형(모텔)
                            </MenuItem>
                            <MenuItem value="hotel">
                              1세대 스탠드형(호텔)
                            </MenuItem>
                            <MenuItem value="secondGenTable">
                              2세대 테이블형
                            </MenuItem>
                            <MenuItem value="secondGenStand">
                              2세대 스탠드형
                            </MenuItem>
                            <MenuItem value="thirdGenStand">
                              3세대 스탠드형
                            </MenuItem>
                            <MenuItem value="mini">2세대 미니</MenuItem>
                            <MenuItem value="sanha">
                              1세대 스탠드형(산하윙스)
                            </MenuItem>
                            <MenuItem value="sanha">
                              2세대 테이블형(산하윙스)
                            </MenuItem>
                            <MenuItem value="sanhaMini">
                              2세대 미니(산하윙스)
                            </MenuItem>
                            <MenuItem value="smartKeyCardDispenser">
                              스마트 카드 발급기
                            </MenuItem>
                            
                          </CustomSelect>
                        </FormControl>
                        <FormControl
                          style={{ width: '33%', marginLeft: '32px' }}
                        >
                          <Typography>위치</Typography>
                          <HardwareInput
                            value={defaultInputValues.location || ''}
                            onChange={handleChange('location')}
                            variant="outlined"
                          />
                        </FormControl>
                      </CustomFlex>

                      <FlexRow
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '96px',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: '184px', height: '56px' }}
                          onClick={onClickUpdateDefaultKioskConfig}
                        >
                          <Typography>기본 설정 변경</Typography>
                        </Button>
                      </FlexRow>
                    </Fragment>

                    <RowLine />

                    {/* 홈 화면 설정 */}

                    {!myAccommodationsLoading && (
                      <KioskHomeArticles
                        accommodation={accommodation}
                        homeInputValues={homeInputValues}
                        kioskArticle={kioskHomeArticle}
                        handleHomeChange={handleHomeChange}
                        handleImageChange={handleImageFileChange}
                        createKioskHomeArticle={emitCreateKioskHomeArticle}
                        updateKioskHomeArticle={updateKioskHomeArticle}
                      />
                    )}

                    <RowLine />

                    {/* 메인 화면 설정 */}

                    {!myAccommodationsLoading && (
                      <KioskMainArticles
                        accommodation={accommodation}
                        mainInputValues={mainInputValues}
                        kioskArticle={kioskMainArticle}
                        handleMainChange={handleMainChange}
                        handleImageChange={handleImageFileChange}
                        createKioskMainArticle={emitCreateKioskMainArticle}
                        updateKioskMainArticle={updateKioskMainArticle}
                      />
                    )}

                    {/* 약관 설정 */}
                    {!myAccommodationsLoading && (
                      <KioskTermsArticles
                        accommodation={accommodation}
                        article={termsArticle}
                        articleState={termsInputValues}
                        handleArticle={handleTermsChange}
                        createArticle={emitCreateTermsArticle}
                        updateArticle={updateTermsArticle}
                      />
                    )}

                    <RowLine />

                    {/* 키오스크 옵션 설정 */}

                    <Fragment>
                      <FlexRow style={{ width: '100%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            height: '48px',
                            marginTop: '40px',
                            marginLeft: '32px',
                          }}
                        >
                          CCU 커스텀 인자 설정
                        </Typography>
                      </FlexRow>

                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/customCCUOption/gi))
                            .map((config) => {
                              return (
                                <FlexRow key={config} style={{ width: '100%' }}>
                                  <FormControl style={{ width: '100%' }}>
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            height: '48px',
                            marginTop: '40px',
                            marginLeft: '32px',
                          }}
                        >
                          키오스크 커스텀 인자 설정
                        </Typography>
                      </FlexRow>

                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/customKioskOption/gi))
                            .map((config) => {
                              return (
                                <FlexRow key={config} style={{ width: '100%' }}>
                                  <FormControl style={{ width: '100%' }}>
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <FlexRow style={{ width: '100%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            height: '48px',
                            marginTop: '40px',
                            marginLeft: '32px',
                          }}
                        >
                          성인 인증 문구 설정 (최대 3줄)
                        </Typography>
                      </FlexRow>

                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/customTermConfirm/gi))
                            .map((config) => {
                              return (
                                <FlexRow key={config} style={{ width: '100%' }}>
                                  <FormControl style={{ width: '100%' }}>
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            height: '48px',
                            marginTop: '40px',
                            marginLeft: '32px',
                          }}
                        >
                          세부 설정
                        </Typography>
                      </FlexRow>

                      <CustomFlex>
                        <CustomInnerFlex
                          style={{
                            width: '100%',
                            padding: '16px',
                            flexWrap: 'wrap',
                          }}
                        >
                          {!!kioskOptionConfig &&
                            Object.keys(kioskOptionConfig)
                              .filter((val) => val.match(/use/gi))
                              .map((config) => {
                                const convertConfig =
                                  convertKioskConfig(config);

                                return (
                                  <FlexRow
                                    key={config}
                                    style={{
                                      width: '33%',
                                      height: '64px',
                                    }}
                                  >
                                    <FormControl
                                      style={{ width: '100%', height: '64px' }}
                                    >
                                      {!config.match(
                                        /use|Layout|Contact/gi,
                                      ) && (
                                        <Typography
                                          style={{
                                            fontSize: '1rem',
                                            marginBottom: '4px',
                                            wordBreak: 'keep-all',
                                          }}
                                        >
                                          {!convertConfig
                                            ? config
                                            : convertConfig}
                                        </Typography>
                                      )}
                                      {getFormByConfig(
                                        config,
                                        defaultInputValues[config],
                                        handleChange(config),
                                      )}
                                    </FormControl>
                                  </FlexRow>
                                );
                              })}
                        </CustomInnerFlex>
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            height: '48px',
                            marginTop: '40px',
                            marginLeft: '32px',
                          }}
                        >
                          CCU 기본 설정
                        </Typography>
                      </FlexRow>

                      {/* 카드 발급 타입 ~ 테마 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/^ccu/gi))
                            .map((config, idx) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: '25%',
                                    height: '88px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight: idx !== 3 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          영수증 관련 설정
                        </Typography>
                      </FlexRow>

                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) =>
                              val.match(/thermalPrinter|receipt/gi),
                            )
                            .map((config, idx) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: '25%',
                                    height: '88px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight: idx !== 3 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          카드 키 관련 설정
                        </Typography>
                      </FlexRow>

                      {/* 키 발급 관련 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) =>
                              val.match(/cardType|keyCard|rfid/gi),
                            )
                            .map((config, idx) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: '25%',
                                    height: '88px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight: idx !== 3 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          QR 관련 설정
                        </Typography>
                      </FlexRow>

                      {/* QR 설정 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/qr/gi))
                            .map((config, idx, arr) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    flex: 1,
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight:
                                        idx !== arr.length - 1 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          릴레이 관련 설정
                        </Typography>
                      </FlexRow>

                      {/* 릴레이 설정 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/relay/gi))
                            .map((config, idx, arr) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    flex: 1,
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight:
                                        idx !== arr.length - 1 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          신분증 설정
                        </Typography>
                      </FlexRow>

                      {/* 나이 설정 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/blockMinorAgeBase/gi))
                            .map((config, idx, arr) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    flex: 1,
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight:
                                        idx !== arr.length - 1 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/blockMinorAgeBase/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          신분증 설정
                        </Typography>
                      </FlexRow>

                      {/* 신분증 인식 설정 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/idAuth/gi))
                            .map((config, idx, arr) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    flex: 1,
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      width: '100%',
                                      height: '88px',
                                      marginRight:
                                        idx !== arr.length - 1 ? '16px' : '0',
                                    }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          현금 결제 관련 설정
                        </Typography>
                      </FlexRow>

                      {/* 현금 방출기 설정 */}
                      <CustomFlex
                        style={{ flexDirection: 'column', flexWrap: 'wrap' }}
                      >
                        <FlexRow>
                          {!!kioskOptionConfig &&
                            Object.keys(kioskOptionConfig)
                              .filter((val) =>
                                val.match(/billDispenserStrategy/gi),
                              )
                              .map((config, idx) => {
                                const convertConfig =
                                  convertKioskConfig(config);
                                return (
                                  <FlexRow
                                    key={config}
                                    style={{
                                      flex: 1,
                                      height: '96px',
                                    }}
                                  >
                                    <FormControl
                                      style={{
                                        width: '100%',
                                        height: 'inherit',
                                      }}
                                    >
                                      {getFormByConfig(
                                        config,
                                        defaultInputValues[config],
                                        handleChange(config),
                                      )}
                                    </FormControl>
                                  </FlexRow>
                                );
                              })}
                        </FlexRow>

                        <FlexRow style={{ flex: 1 }}>
                          {!!kioskOptionConfig &&
                            Object.keys(kioskOptionConfig)
                              .filter((val) =>
                                val.match(/ioBoard|DispensorAmount/gi),
                              )
                              .map((config, idx) => {
                                const convertConfig =
                                  convertKioskConfig(config);
                                return (
                                  <FlexRow
                                    key={config}
                                    style={{ flex: 1, height: '96px' }}
                                  >
                                    <FormControl
                                      style={{
                                        width: '100%',
                                        height: '88px',
                                        marginRight: idx === 3 ? '0' : '16px',
                                      }}
                                    >
                                      {!config.match(
                                        /use|Layout|Contact/gi,
                                      ) && (
                                        <Typography
                                          style={{
                                            fontSize: '1rem',
                                            marginBottom: '4px',
                                          }}
                                        >
                                          {!convertConfig
                                            ? config
                                            : convertConfig}
                                        </Typography>
                                      )}
                                      {getFormByConfig(
                                        config,
                                        defaultInputValues[config],
                                        handleChange(config),
                                      )}
                                    </FormControl>
                                  </FlexRow>
                                );
                              })}
                        </FlexRow>
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          카드 결제 관련 설정
                        </Typography>
                      </FlexRow>

                      {/* 현금 방출기 설정 */}
                      <CustomFlex
                        style={{ flexDirection: 'column', flexWrap: 'wrap' }}
                      >
                        <FlexRow style={{ flex: 1 }}>
                          {!!kioskOptionConfig &&
                            Object.keys(kioskOptionConfig)
                              .filter((val) => val.match(/icCard/gi))
                              .map((config, idx) => {
                                const convertConfig =
                                  convertKioskConfig(config);
                                return (
                                  <FlexRow
                                    key={config}
                                    style={{
                                      flex: 1,
                                      height: '96px',
                                    }}
                                  >
                                    <FormControl
                                      style={{
                                        width: '100%',
                                        height: '88px',
                                        marginRight: idx === 0 ? '16px' : '0',
                                      }}
                                    >
                                      {!config.match(
                                        /use|Layout|Contact/gi,
                                      ) && (
                                        <Typography
                                          style={{
                                            fontSize: '1rem',
                                            marginBottom: '4px',
                                          }}
                                        >
                                          {!convertConfig
                                            ? config
                                            : convertConfig}
                                        </Typography>
                                      )}
                                      {getFormByConfig(
                                        config,
                                        defaultInputValues[config],
                                        handleChange(config),
                                      )}
                                    </FormControl>
                                  </FlexRow>
                                );
                              })}
                        </FlexRow>
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          카메라 ID 설정
                        </Typography>
                      </FlexRow>

                      {/* 카메라 ID 설정 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/UpperCamera/gi))
                            .map((config) => {
                              const convertConfig = convertKioskConfig(config);

                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: '100%',
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{ width: '100%', height: '88px' }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          외부 API 키 설정
                        </Typography>
                      </FlexRow>

                      {/* 카카오 API 키 등등 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/kakao/gi))
                            .map((config, idx) => {
                              const convertConfig = convertKioskConfig(config);
                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: !config?.match(/use/gi)
                                      ? '100%'
                                      : '33%',
                                    height: '96px',
                                  }}
                                >
                                  <FormControl
                                    style={{ width: '100%', height: '88px' }}
                                  >
                                    {!config.match(/use|Layout|Contact/gi) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          결제 정책 설정
                        </Typography>
                      </FlexRow>

                      {/* 연락처 수집 정책 및 메인 화면 버튼 구성 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/PayList/gi))
                            .reverse()
                            .map((config) => {
                              const convertConfig = convertKioskConfig(config);
                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: !config?.match(/use/gi)
                                      ? '100%'
                                      : '33%',
                                    height: '96px',
                                  }}
                                >
                                  <FormControl style={{ width: '100%' }}>
                                    {!config.match(
                                      /use|Layout|Contact|SleepsStrategy/gi,
                                    ) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      [...defaultInputValues[config]],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          기타 정책 설정
                        </Typography>
                      </FlexRow>

                      {/* 연락처 수집 정책 및 메인 화면 버튼 구성 */}
                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) =>
                              val.match(
                                /ContactStrategy|SleepsStrategy|menuLayout/gi,
                              ),
                            )
                            .map((config) => {
                              const convertConfig = convertKioskConfig(config);
                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: !config?.match(/use/gi)
                                      ? '100%'
                                      : '33%',
                                    height: '96px',
                                  }}
                                >
                                  <FormControl style={{ width: '100%' }}>
                                    {!config.match(
                                      /use|Layout|Contact|SleepsStrategy/gi,
                                    ) && (
                                      <Typography
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        {!convertConfig
                                          ? config
                                          : convertConfig}
                                      </Typography>
                                    )}
                                    {getFormByConfig(
                                      config,
                                      [
                                        ...defaultInputValues[config],
                                        defaultInputValues[
                                          'mustCollectContact'
                                        ],
                                      ],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <RowLine style={{ marginTop: '24px' }} />

                      <FlexRow style={{ width: '100%', padding: '0 0 0 32px' }}>
                        <Typography
                          variant="h5"
                          style={{ height: '48px', margin: '40px 0 0 0' }}
                        >
                          카드 키 발급 섹터 제한 설정
                        </Typography>
                      </FlexRow>

                      {/* 키오스크 발급 블록 제한 */}

                      <CustomFlex style={{ flexWrap: 'wrap' }}>
                        {!!kioskOptionConfig &&
                          Object.keys(kioskOptionConfig)
                            .filter((val) => val.match(/restrict/gi))
                            .map((config) => {
                              return (
                                <FlexRow
                                  key={config}
                                  style={{
                                    width: !config?.match(/use/gi)
                                      ? '100%'
                                      : '33%',
                                    height: '100%',
                                  }}
                                >
                                  <FormControl style={{ width: '100%' }}>
                                    {getFormByConfig(
                                      config,
                                      defaultInputValues[config],
                                      handleChange(config),
                                    )}
                                  </FormControl>
                                </FlexRow>
                              );
                            })}
                      </CustomFlex>

                      <FlexCenteredRow
                        style={{ alignItems: 'flex-start', height: '200px' }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            width: '184px',
                            height: '56px',
                            marginTop: '16px',
                          }}
                          onClick={onUpdateKioskOptions}
                        >
                          <Typography>옵션 설정 변경</Typography>
                        </Button>
                      </FlexCenteredRow>
                    </Fragment>
                  </FlexCenteredCol>
                ) : (
                  <Fragment>
                    <FlexCenteredCol style={{ height: 'calc(100vh)' }}>
                      <Typography variant="h4" style={{ textAlign: 'center' }}>
                        {`발견된 키오스크가 없습니다.`}
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{ textAlign: 'center', marginTop: '32px' }}
                      >
                        {`키오스크를 추가해주세요.`}
                      </Typography>
                      <FlexCenteredRow>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: '128px', marginTop: '64px' }}
                          onClick={createKiosk}
                        >
                          <Typography variant="h5">+ 추가</Typography>
                        </Button>
                      </FlexCenteredRow>
                    </FlexCenteredCol>
                  </Fragment>
                )}
              </Fragment>
            )}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isSnack}
              autoHideDuration={2000}
              onClose={() => setIsSnack(false)}
              key={'top-center'}
            >
              <Alert onClose={() => setIsSnack(false)} severity="success">
                설정이 완료되었습니다
              </Alert>
            </Snackbar>
          </ComponentContainer>
        </FlexCenteredCol>
      )}
    </WrapperContainer>
  );
};

export default Kiosk;
