import './App.css';
import Routes from './routes';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { muiTheme } from './styles/MuiTheme';

const App = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Routes />
    </MuiThemeProvider>
  );
};

export default App;
