import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { FlexCenteredCol, FlexCol, FlexRow } from '../../../components/Layout';

export const RoomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    flex-direction: column;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
  }
`;

export const RoomInnerModal = styled(FlexCenteredCol)`
  background-color: #ffffff;
  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    flex-direction: column;
    height: 100%;
    border-radius: 12px;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    border-radius: 12px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    width: 60vw;
    height: 48vh;
    border-radius: 12px;
  }
`;

export const RoomInnerModalHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px 12px 0 0;
  border-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    border-radius: 0;
  }
`;

export const OptionButton = styled(Button)`
  /* flex-grow: 1; */
  /* min-width: 144px; */
  /* max-width: 144px; */
  height: 48px;
  margin: 4px;
  background: #eee !important;
`;

export const RoomCard = styled(FlexCol)`
  flex-grow: 1;
  min-width: 132px;
  max-width: 148px;
  margin: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 0 1px 1px;
  overflow: hidden;
  cursor: pointer;
`;

export const RoomInnerCard = styled.div`
  min-width: 132px;
  max-width: 148px;
  height: 96px;
  background-color: ${(props: any) =>
    props.data ? 'rgb(0, 152, 0)' : 'rgb(128, 128, 128)'};
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 0 0 1px 1px;
  padding: 0 8px;
  color: #ffffff;
  :hover {
    background-color: ${(props: any) =>
      props.data ? 'rgb(0, 128, 0)' : 'rgb(112, 112, 112)'};
  }
`;
