//@ts-nocheck
import { Typography } from '@material-ui/core';
import {
  FlexRow,
  FlexSpaceBetweenRow,
  HeaderContainer,
  HeaderHomeButton,
  HeaderSignInButton,
} from '../../components/Layout';
import VenditLogo from '../../images/vendit_logo_white.svg';
import { history } from '../../routes';
import jwt from 'jsonwebtoken';
import { CONSTANTS, isDev, setIsDev } from '../../commons/constants';
import { useLocation } from 'react-router-dom';
import authHandler from '../../helpers/authHandler';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { AccessTime, SupervisedUserCircle } from '@material-ui/icons';

const Timeout = () => {
  const _lastTime = localStorage.getItem('lastTime');
  const totalExp = localStorage.getItem('expireAt');

  const nowTime = moment();
  const expireTime = moment(_lastTime).add(30, 'm');
  const subtractTime = Math.floor((expireTime - nowTime) / 1000);

  // console.log(nowTime, expireTime, subtractTime);

  useEffect(() => {
    if (authHandler?.user && !nowTime.isAfter(expireTime)) {
      localStorage.setItem('expireAt', totalExp);
    }
  }, [moment]);

  const [expireAt, setExpireAt] = useState(totalExp || 0);

  useEffect(() => {
    if (!nowTime.isAfter(expireTime)) {
      let myInterval = setInterval(() => {
        if (expireAt > 0 && totalExp > 0) {
          setExpireAt(subtractTime);
        }
      }, 1000);

      return () => clearInterval(myInterval);
    } else {
      setExpireAt(0);
    }
  }, [subtractTime]);

  const [expireHour, expireMinute] = useMemo(() => {
    return [Math.floor(subtractTime / 60), Math.floor(subtractTime % 60)];
  }, [expireAt]);

  // console.log(expireAt, expireHour, expireMinute);

  return (
    <>
      {expireAt && (
        <Typography
          align="center"
          style={{ width: '52px', margin: '0 0 0 4px' }}
        >
          {expireHour >= 10 ? `${expireHour}` : `0${expireHour}`}:
          {expireMinute >= 10 ? `${expireMinute}` : `0${expireMinute}`}
        </Typography>
      )}
    </>
  );
};

const Header = () => {
  const { pathname } = useLocation();
  const isSignIn = authHandler.accessToken;
  const userData = !!isSignIn && jwt.decode(isSignIn);

  // @ts-ignore
  const { name, thumbnail } = !!isSignIn && userData;

  const isRoot =
    pathname === CONSTANTS.PATH.ROOT || pathname === CONSTANTS.PATH.SIGN_IN;

  const isProcessDev = process.env.NODE_ENV === 'development';

  const isAdmin = authHandler?.user?.accessLevel === 'ADMIN';

  const toggleServer = () => {
    if (isProcessDev && isRoot) {
      if (isDev === 'true') {
        setIsDev('false');
        authHandler.signOut();
      } else {
        setIsDev('true');
        authHandler.signOut();
      }
    }
  };

  const signOut = () => {
    if (isSignIn !== null) {
      authHandler.signOut();
    }
    window.expireAt = null;
    history.push(CONSTANTS.PATH.ROOT);
    history.go(0);
  };

  return (
    <HeaderContainer>
      <FlexSpaceBetweenRow style={{ width: '100%', padding: '0 12px 0 24px' }}>
        <img src={VenditLogo} width="147px" />

        <FlexRow style={{ alignItems: 'center' }}>
          {!isRoot && (
            <FlexRow style={{ margin: '0 8px 0 0', color: '#e1e1e1' }}>
              <AccessTime style={{ width: 'auto' }} />
              <Timeout />
            </FlexRow>
          )}
          {isProcessDev && (
            <HeaderSignInButton
              disabled={!isRoot}
              style={{
                marginRight: '8px',
                color: '#ffffff',
              }}
              onClick={toggleServer}
            >
              <Typography style={{ fontSize: '1.2rem' }}>
                {isDev === 'true' ? '개발서버' : '상용서버'}
              </Typography>
            </HeaderSignInButton>
          )}

          {isSignIn !== null && (
            <>
              <HeaderSignInButton
                style={{
                  color: '#ffffff',
                }}
                onClick={signOut}
              >
                {thumbnail ? (
                  <>
                    {isAdmin ? (
                      <SupervisedUserCircle
                        style={{
                          margin: '0 12px 0 0',
                          fontSize: '32px',
                          color: '#ff1e1e',
                        }}
                      />
                    ) : (
                      <img src={thumbnail} width="32px" alt="thumbnail" />
                    )}
                  </>
                ) : (
                  <>
                    {isAdmin ? (
                      <SupervisedUserCircle
                        style={{
                          margin: '0 12px 0 0',
                          fontSize: '32px',
                          color: '#cc3333',
                        }}
                      />
                    ) : (
                      <SupervisedUserCircle
                        style={{
                          margin: '0 12px 0 0',
                          fontSize: '32px',
                          color: '#e1e1e1',
                        }}
                      />
                    )}
                  </>
                )}

                <Typography>{name}</Typography>
              </HeaderSignInButton>
            </>
          )}
        </FlexRow>
      </FlexSpaceBetweenRow>
    </HeaderContainer>
  );
};

export default Header;
