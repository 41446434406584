// @ts-nocheck
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { Fragment, useState } from 'react';
import { version } from '../../../package.json';
import { CONSTANTS } from '../../commons/constants';
import { FlexCenteredCol, FlexCenteredRow } from '../../components/Layout';
import { DISABLE_USER, SIGN_IN_BY_ADMIN } from '../../graphql/mutation';
import { GET_USERS } from '../../graphql/query';
import authHandler from '../../helpers/authHandler';
import { history } from '../../routes';
import { Debounce, destructEdges } from '../../utils/utils';
import { useAuth } from '../../hooks/useAuth';

type userType = {
  identity: string;
  nickname: string;
};

const searchDebouncer = new Debounce();

const AdminSignIn = () => {
  const { accessLevel, accessToken, isAccess } = useAuth();

  (() => {
    if (accessLevel !== 'ADMIN' && accessLevel !== 'SUPERADMIN') {
      history.push(CONSTANTS.PATH.ACCOMMODATIONS);
    }
  })();

  const defaultUserType: userType = {
    identity: '',
    nickname: '',
  };

  const [selectedDisableUser, setSelectedDisableUser] =
    useState<userType>(defaultUserType);
  const [selectedUser, setSelectedUser] = useState<userType>(defaultUserType);
  const [search, setSearch] = useState('');

  const {
    data: usersData,
    loading,
    refetch: refetchUsers,
  } = useQuery(GET_USERS, {
    variables: {
      filter: {
        keyword: search,
      },
    },
  });

  const [emitSignIn] = useMutation(SIGN_IN_BY_ADMIN);

  const [emitDisableUser] = useMutation(DISABLE_USER);

  const searchIds = async (keyword) => {
    if (await searchDebouncer.asyncDebounce(500)) {
      setSearch(keyword);
    }
  };

  const adminSignIn = async () => {
    try {
      const signInResult = await emitSignIn({
        variables: {
          id: selectedUser.identity,
        },
      });

      const {
        data: {
          signInByAdmin: {
            token: { accessToken, refreshToken },
          },
        },
      } = signInResult;

      if (signInResult) {
        await authHandler.signIn(accessToken, refreshToken, true);
        history.push(CONSTANTS.PATH.ACCOMMODATIONS);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const users: userType[] = destructEdges(usersData).filter(
    (user) => !/^nasom/.test(user.identity),
  );

  return (
    <Fragment>
      {accessLevel === 'ADMIN' || accessLevel === 'SUPERADMIN' ? (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            width: '100vw',
            height: 'calc(100vh - 160px)',
          }}
        >
          <Typography variant="h5" style={{ marginBottom: '32px' }}>
            지점 로그인
          </Typography>

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await adminSignIn();
            }}
          >
            <Autocomplete
              options={users}
              value={selectedUser || ''}
              onChange={(event, changedOption) =>
                setSelectedUser(changedOption)
              }
              disableClearable
              noOptionsText={'검색 결과 없음'}
              style={{ width: '280px', margin: 8 }}
              loading={loading}
              filterOptions={(x) => x}
              loadingText="검색 중입니다.."
              ListboxProps={{ style: { width: '100%' } }}
              getOptionLabel={(option) => option.identity}
              renderOption={(option) =>
                `${option.identity} (${option.nickname})`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginBottom: 16 }}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  label="ID 입력"
                  onChange={(event) => searchIds(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    style: { paddingRight: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <FlexCenteredCol>
              <FlexCenteredRow>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: '120px', height: '48px', marginTop: '32px' }}
                >
                  <Typography variant="h6">로그인</Typography>
                </Button>
              </FlexCenteredRow>
            </FlexCenteredCol>
          </form>

          <Typography style={{ marginTop: '36px', color: '#dd0000' }}>
            경고 - 인가되지 않은 사용자 이외 접근을 금함
          </Typography>

          <Typography style={{ marginTop: '16px' }}>버전 {version}</Typography>
          {accessLevel === 'SUPERADMIN' && (
            <FlexCenteredRow>
              <Autocomplete
                options={users}
                value={selectedUser || ''}
                onChange={(event, changedOption) =>
                  setSelectedDisableUser(changedOption)
                }
                disableClearable
                noOptionsText={'검색 결과 없음'}
                style={{ width: '280px', margin: 8 }}
                loading={loading}
                filterOptions={(x) => x}
                loadingText="검색 중입니다.."
                ListboxProps={{ style: { width: '100%' } }}
                getOptionLabel={(option) => option.identity}
                renderOption={(option) =>
                  `${option.identity} (${option.nickname})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ marginBottom: 16 }}
                    variant="outlined"
                    fullWidth
                    autoFocus
                    label="ID 입력"
                    onChange={(event) => searchIds(event.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      style: { paddingRight: 16 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                variant="contained"
                disabled={!selectedDisableUser?.identity}
                onClick={async () => {
                  try {
                    const disableResult = await emitDisableUser({
                      variables: {
                        identity: selectedDisableUser.identity,
                        disable: true,
                      },
                      onCompleted: () => {
                        refetchUsers();
                      },
                    });

                    if (disableResult) {
                      alert('비활성화 완료');
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <Typography variant="h6">비활성화</Typography>
              </Button>
            </FlexCenteredRow>
          )}
        </FlexCenteredCol>
      ) : (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            width: '100vw',
            height: 'calc(100vh - 160px)',
            color: '#dd0000',
          }}
        >
          <Typography variant="h3">401 - Access denied</Typography>
          <Typography variant="h4" style={{ marginTop: '64px' }}>
            접근이 차단되었습니다.
          </Typography>
        </FlexCenteredCol>
      )}
    </Fragment>
  );
};

export default AdminSignIn;
