import { gql } from '@apollo/client';

export enum ProductType {
  VPMS = 'VPMS',
  VCMS = 'VCMS',
}

export const SIGN_IN = gql`
  mutation signIn($id: String!, $pw: String!) {
    signIn(input: { identity: $id, password: $pw }) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const SIGN_IN_BY_ADMIN = gql`
  mutation signInByAdmin($id: String!) {
    signInByAdmin(identity: $id) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const ACTIVATE_SINGLE_ACCOMMODATION = gql`
  mutation activateAccommodation($accommodationId: ID!) {
    activateSingleAccommodation(accommodationId: $accommodationId) {
      id
      name
      isActive
    }
  }
`;

export const UPDATE_ACCOMMODATION = gql`
  mutation updateAccommodation($input: UpdateAccommodationInput!) {
    updateAccommodation(input: $input) {
      id
      name
      cardData
      options {
        key
        value
      }
    }
  }
`;

export const CREATE_KIOSK_ARTICLE = gql`
  mutation createAccommodationKioskArticle($accommodationId: ID!) {
    createAccommodationArticle(
      input: {
        subject: ""
        content: ""
        attachments: ""
        type: "KIOSK_INTRO"
        accommodationId: $accommodationId
      }
    ) {
      id
      subject
      content
      attachments
      type
      tag
      createdAt
    }
  }
`;

export const CREATE_KIOSK_MAIN_ARTICLE = gql`
  mutation createAccommodationKioskArticle($accommodationId: ID!) {
    createAccommodationArticle(
      input: {
        subject: ""
        content: ""
        attachments: ""
        type: "KIOSK_MAIN_INTRO"
        accommodationId: $accommodationId
      }
    ) {
      id
      subject
      content
      attachments
      type
      tag
      createdAt
    }
  }
`;

export const CREATE_TERMS_ARTICLE = gql`
  mutation createAccommodationKioskArticle($accommodationId: ID!) {
    createAccommodationArticle(
      input: {
        subject: ""
        content: ""
        attachments: ""
        type: "TERMS_INTRO"
        accommodationId: $accommodationId
      }
    ) {
      id
      subject
      content
      attachments
      type
      tag
      createdAt
    }
  }
`;

export const UPDATE_KIOSK_ARTICLE = gql`
  mutation updateAccommodationKioskArticle(
    $input: updateAccommodationArticleInput
  ) {
    updateAccommodationArticle(input: $input) {
      id
      subject
      content
      attachments
      type
      tag
      createdAt
    }
  }
`;

export const UPLOAD_IMAGE_FILE_TO_ARTICLE = gql`
  mutation uploadAccommodationKioskArticleImages(
    $articleId: ID!
    $files: [Upload!]!
    $persistFiles: [String!]
  ) {
    uploadAccommodationArticleImages(
      articleId: $articleId
      files: $files
      persistFiles: $persistFiles
    ) {
      id
      subject
      content
      attachments
      type
      tag
      createdAt
    }
  }
`;

export const CREATE_KIOSK = gql`
  mutation createKiosk($input: CreateKioskInput!) {
    createAccommodationKiosk(input: $input) {
      id
      name
      type
      location
    }
  }
`;

export const UPDATE_KIOSK = gql`
  mutation updateKiosk($input: UpdateKioskInput!) {
    updateAccommodationKiosk(input: $input) {
      id
      name
      type
      location
    }
  }
`;

export const DELETE_KIOSK = gql`
  mutation deleteKiosk($id: ID!) {
    deleteAccommodationKiosk(id: $id) {
      result
    }
  }
`;

export const SET_KIOSK_OPTIONS = gql`
  mutation setKioskOptions($inputs: [KioskOptionInput], $kioskId: ID!) {
    setKioskOptions(inputs: $inputs, kioskId: $kioskId) {
      key
      value
    }
  }
`;

export const CREATE_ROOM = gql`
  mutation createRoomInKiosk($input: CreateRoomInput!) {
    createRoom(input: $input) {
      id
      name
      number
      key
      cardData
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoomInKiosk($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      id
      name
      number
      key
      cardData
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation deleteRoomInKiosk($id: ID!) {
    deleteRoom(id: $id) {
      result
    }
  }
`;

export const DELETE_ROOM_DEVICE = gql`
  mutation deleteRoomInKiosk($id: ID!) {
    deleteRoomDevice(id: $id) {
      result
    }
  }
`;
export const SEND_CONTROL_PACKET = gql`
  mutation sendKioskControlPacket($kioskId: ID!, $data: String!) {
    sendKioskControlPacket(kioskId: $kioskId, data: $data)
  }
`;

export const CREATE_OR_UPDATE_PG_CONFIG = gql`
  mutation createOrUpdatePgConfig($input: CreateOrUpdatePgConfigInput!) {
    createOrUpdatePgConfig(input: $input) {
      accommodationId
      data
    }
  }
`;

export const DISABLE_USER = gql`
  mutation disableUser($identity: String!, $disable: Boolean) {
    disableUser(identity: $identity, disable: $disable)
  }
`;

export const ACTIVATE_LICENSE = gql`
  mutation activateLicense($accommodationId: ID!, $productType: ProductType!) {
    activateLicense(
      accommodationId: $accommodationId
      productType: $productType
    )
  }
`;

export const INACTIVATE_LICENSE = gql`
  mutation inactivateLicense(
    $accommodationId: ID!
    $productType: ProductType!
  ) {
    inactivateLicense(
      accommodationId: $accommodationId
      productType: $productType
    )
  }
`;

export const CREATE_CONCIERGE_CONFIG = gql`
  mutation createCoamPaymentConfigs($input: [CreateCoamPaymentConfig]!) {
    createCoamPaymentConfigs(input: $input) {
      cpid
      accommodationId
    }
  }
`;

export const UPDATE_CONCIERGE_CONFIG = gql`
  mutation updateCoamPaymentConfig($input: UpdateCoamPaymentConfig!) {
    updateCoamPaymentConfig(input: $input) {
      cpid
      accommodationId
    }
  }
`;

export const MIGRATE_SALE_AMOUNT_INCLUDING_COMMISSION_AMOUNT = gql`
  mutation MigrateSaleAmountIncludingCommissionAmount($accommodationId: ID!) {
    migrateSaleAmountIncludingCommissionAmount(
      accommodationId: $accommodationId
    )
  }
`;

export const USER_UPDATE_INFO = gql`
  mutation UpdateInfo($input: UserInput!) {
    updateInfo(input: $input) {
      id
      name
      nickname
    }
  }
`;

