// @ts-nocheck
import { useMutation } from '@apollo/client';
import { Button, FormControl, TextField, Typography } from '@material-ui/core';
import { BaseSyntheticEvent, useState } from 'react';
import { SIGN_IN } from '../../graphql/mutation';
import { FlexCenteredCol, FlexCenteredRow } from '../../components/Layout';
import { history } from '../../routes';
import { CONSTANTS, isDev, setIsDev } from '../../commons/constants';
import { version } from '../../../package.json';
import authHandler from '../../helpers/authHandler';
import jwt, { JwtPayload } from 'jsonwebtoken';
import styled from 'styled-components';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const SignInInput = styled(TextField)`
  > div {
    > input {
      padding: 18px;
    }
  }
`;

const SignIn = () => {
  const accessToken = authHandler?.accessToken;

  (() => {
    if (accessToken) {
      history.push(CONSTANTS.PATH.ROOM);
    }
  })();

  const { pathname } = useLocation();

  const [emitSignIn, { loading: signInLoading }] = useMutation(SIGN_IN);

  const [quitCounts, setQuitCounts] = useState(0);

  const [quitTimeout, setQuitTimeout] = useState<NodeJS.Timeout | null>(null);

  const [signInError, setSignInError] = useState(false);

  const [signInInput, setSignInInput] = useState<any>({
    identity: '',
    password: '',
  });

  const { identity, password } = signInInput;

  const isRoot =
    pathname === CONSTANTS.PATH.ROOT || pathname === CONSTANTS.PATH.SIGN_IN;

  const isProcessDev = process.env.NODE_ENV === 'development';

  const onChangeSignInInput = (inputKey, value) => {
    setSignInInput({
      ...signInInput,
      [inputKey]: value,
    });
  };

  const onClickChangeMode = () => {
    if (quitTimeout) {
      clearTimeout(quitTimeout);
    }

    setQuitTimeout(
      setTimeout(() => {
        setQuitCounts(0);
      }, 1000),
    );

    if (quitCounts >= 3) {
      clearTimeout(quitCounts);

      if (isRoot) {
        if (isDev === 'true') {
          setIsDev('false');
          authHandler.signOut();
        } else {
          setIsDev('true');
          authHandler.signOut();
        }
      }
    }

    setQuitCounts((prev) => prev + 1);
  };

  const onEnter = (callback) => (event) => {
    if (event.keyCode === 13) {
      callback();
    }
  };

  const requestSignIn = async () => {
    try {
      const signInResult = await emitSignIn({
        variables: {
          id: identity,
          pw: password,
        },
      });
      const {
        data: {
          signIn: {
            token: { accessToken, refreshToken },
          },
        },
      } = signInResult;

      await authHandler.signIn(accessToken, refreshToken, true);
      const result: JwtPayload = jwt.decode(accessToken);

      if (result?.accessLevel === 'ADMIN') {
        history.push(CONSTANTS.PATH.ADMIN_MODE);
      } else {
        history.push(CONSTANTS.PATH.ACCOMMODATIONS);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FlexCenteredCol
      style={{
        alignItems: 'center',
        width: '100vw',
        height: 'calc(100vh - 60px)',
      }}
    >
      <FlexCenteredCol
        onClick={() => {
          onClickChangeMode();
        }}
      >
        <Typography
          variant="h5"
          style={{ marginBottom: '40px', fontSize: '2rem', fontWeight: 600 }}
        >
          VENDIT 세팅 페이지
        </Typography>
      </FlexCenteredCol>

      {isDev === 'true' && (
        <Typography
          variant="h6"
          style={{ marginBottom: '40px', fontSize: '1.2rem', fontWeight: 600 }}
        >
          [Development Server]
        </Typography>
      )}

      <FlexCenteredCol>
        <SignInInput
          value={identity}
          variant="outlined"
          onChange={(event) =>
            onChangeSignInInput('identity', event.target.value)
          }
          label="ID"
          onKeyDown={onEnter(requestSignIn)}
        />

        <SignInInput
          value={password}
          variant="outlined"
          type="password"
          onChange={(event) =>
            onChangeSignInInput('password', event.target.value)
          }
          label="비밀번호"
          onKeyDown={onEnter(requestSignIn)}
          style={{ margin: '12px 0 0 0' }}
        />

        <FlexCenteredRow>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: '128px', height: '48px', marginTop: '32px' }}
            onClick={requestSignIn}
          >
            <Typography variant="h6">로그인</Typography>
          </Button>
        </FlexCenteredRow>
      </FlexCenteredCol>

      <Typography
        style={{ marginTop: '36px', fontWeight: 600, color: '#dd0000' }}
      >
        경고 - 인가되지 않은 사용자 이외 접근을 금함
      </Typography>

      <Typography style={{ marginTop: '16px' }}>버전 {version}</Typography>
    </FlexCenteredCol>
  );
};

export default SignIn;
