import { Route } from 'react-router-dom';
import { CONSTANTS } from '../commons/constants';
import Footer from '../containers/Footer/Footer';
import Header from '../containers/Header/Header';
import authHandler from '../helpers/authHandler';

type AppRouterProps = {
  path: string;
  exact?: boolean;
  Component: any;
};

const AppRoute = (props: AppRouterProps) => {
  const { Component } = props;

  const signIn = authHandler.accessToken;
  const isSelect = props.path === CONSTANTS.PATH.ACCOMMODATIONS;

  return (
    <Route exact={props.exact} path={props.path}>
      <Header />
      <Component />
      {signIn && !isSelect && <Footer />}
    </Route>
  );
};

export default AppRoute;
