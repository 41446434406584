// @ts-nocheck

import { ListObjectsCommand } from '@aws-sdk/client-s3';
import { Checkbox, MenuItem, Select } from '@material-ui/core';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Refresh } from '@material-ui/icons';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useCallback, useEffect, useState } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import client from '../helpers/S3Client';
import { Kiosk } from '../typedefs/gql';
import { FlexCenteredCol, FlexCenteredRow, FlexRow } from './Layout';
import Search from './Search';

const thStyles: React.CSSProperties = {
  padding: '8px',
  border: '1px solid black',
  fontSize: '1rem',
  wordBreak: 'keep-all',
  textAlign: 'center',
};

const tdStyles: React.CSSProperties = {
  padding: '8px',
  border: '1px solid black',
  fontSize: '1rem',
  wordBreak: 'keep-all',
  textAlign: 'center',
};

const AccoTable = ({ columns, data, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useFilters, useSortBy);

  return (
    <FlexCenteredCol style={{ margin: '128px 0 0 0' }}>
      <FlexCenteredRow>
        <Search onSubmit={setGlobalFilter} />
      </FlexCenteredRow>
      <FlexRow>
        <MuiTable
          {...getTableProps()}
          style={{
            margin: '64px',
            fontSize: '1.2rem',
            backgroundColor: '#fff',
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell {...column.getHeaderProps()} style={thStyles}>
                        {column.render('Header')}
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} style={tdStyles}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </FlexRow>
    </FlexCenteredCol>
  );
};

const KioskTable = ({ columns, data, onRefresh, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const [checkedList, setCheckedList] = useState<Map<string, any>>(new Map());

  const [versions, setVersions] = useState<(string | undefined)[]>([]);

  const [version, setVersion] = useState<string>('latest');

  const [versionMap, setVersionMap] = useState<Map<string, string>>(new Map());

  const getVersionsCommand = new ListObjectsCommand({
    Bucket: 'vendit-kiosk-application-new',
  });

  const onMount = useCallback(async () => {
    const response = await client.send(getVersionsCommand);
    if (response) {
      const versions = response?.Contents?.map((content) => {
        return content?.Key;
      }).filter((version) => version?.includes('.yml'));

      setVersions(versions?.map((version) => version?.split('.yml')[0]) || []);
    }
  }, []);

  const onUpdate = async (row) => {
    const kiosk: Maybe<Kiosk> = row?.original;

    const kioskId = kiosk?.id || '';

    const kioskVersionType = kiosk?.version?.split('.')?.[0] || '';

    const { onUpdateV1, onUpdateV2 } = props;

    switch (kioskVersionType) {
      case '0': {
        return await onUpdateV1({ kioskId });
      }
      default: {
        return await onUpdateV2({
          kioskId,
          channel: versionMap.get(kioskId) || 'latest',
        });
      }
    }
  };

  const onUpdateAll = async (kiosk, channel = 'latest') => {
    const kioskId = kiosk?.id || '';

    const kioskVersionType = kiosk?.version?.split('.')?.[0] || '';

    const { onUpdateV1, onUpdateV2 } = props;

    switch (kioskVersionType) {
      case '0': {
        return await onUpdateV1({ kioskId });
      }
      case '2': {
        return await onUpdateV2({
          kioskId,
          channel,
        });
      }
    }
  };

  const onRestart = async (row) => {
    const kiosk: Maybe<Kiosk> = row?.original;

    const kioskId = kiosk?.id || '';

    const { onRestart } = props;

    await onRestart({ kioskId });
  };

  const onUpdateCCU = async (row) => {
    const kiosk: Maybe<Kiosk> = row?.original;

    const kioskId = kiosk?.id || '';

    const { onUpdateCCU } = props;

    return await onUpdateCCU({ kioskId });
  };

  const onRestartCCU = async (row) => {
    const kiosk: Maybe<Kiosk> = row?.original;

    const kioskId = kiosk?.id || '';

    const { onRestartCCU } = props;

    await onRestartCCU({ kioskId });
  };

  const onUploadLog = async (row) => {
    const kiosk: Maybe<Kiosk> = row?.original;

    const kioskId = kiosk?.id || '';

    const { onUploadLog } = props;

    await onUploadLog({ kioskId });
  };

  const handleChangeVersion = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    kioskId: string,
  ) => {
    setVersionMap((prev) => {
      const newMap = new Map(prev);
      newMap.set(kioskId, event.target.value);
      return newMap;
    });
  };

  const reqVersionUpdateAllKiosk = async () => {
    try {
      const kiosks: any[] = Array.from(checkedList.values()).filter(Boolean);

      for (let i = 0; i < kiosks.length; i++) {
        try {
          await onUpdateAll(kiosks[i], version);
          console.log(`${kiosks[i]?.name} update complete.`);
        } catch (e) {
          console.error('kiosk', i, 'update error.');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <FlexCenteredCol style={{ margin: '128px 0 0 0' }}>
      <FlexCenteredRow>
        <Search onSubmit={setGlobalFilter} />{' '}
        <button
          onClick={onRefresh}
          style={{ width: '80px', margin: '0 0 0 32px', padding: '0 0 0 0' }}
        >
          <Refresh />
        </button>
      </FlexCenteredRow>
      <FlexRow>
        <MuiTable
          {...getTableProps()}
          style={{
            margin: '64px',
            fontSize: '1.2rem',
            backgroundColor: '#fff',
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  <TableCell style={thStyles}>
                    <Checkbox
                      value={
                        Array.from(checkedList.values()).filter(Boolean)
                          .length === data.length || false
                      }
                      checked={
                        Array.from(checkedList.values()).filter(Boolean)
                          .length === data.length || false
                      }
                      indeterminate={
                        Array.from(checkedList.values()).filter(Boolean)
                          .length > 0 &&
                        Array.from(checkedList.values()).filter(Boolean)
                          .length < data.length
                      }
                      onChange={(event) => {
                        setCheckedList((prev) => {
                          const newMap = new Map(prev);
                          rows.forEach((row) => {
                            newMap.set(
                              row.original['id'] || '',
                              event.target.checked
                                ? data.find((d) => d?.id === row.original['id'])
                                : null,
                            );
                          });
                          return newMap;
                        });
                      }}
                    />
                  </TableCell>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell {...column.getHeaderProps()} style={thStyles}>
                        {column.render('Header')}
                      </TableCell>
                    );
                  })}
                  <TableCell style={thStyles}>
                    버전선택
                    <Select
                      value={version}
                      onChange={(event) => setVersion(event.target.value)}
                    >
                      {versions.map((version) => (
                        <MenuItem value={version}>{version}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell style={thStyles}>
                    키오스크 업데이트
                    <button
                      onClick={async () => {
                        await reqVersionUpdateAllKiosk();
                      }}
                      style={{ width: '100%', height: '64px' }}
                    >
                      선택 업데이트
                    </button>
                  </TableCell>
                  <TableCell style={thStyles}>키오스크 재시작</TableCell>
                  <TableCell style={thStyles}>CCU 업데이트</TableCell>
                  <TableCell style={thStyles}>CCU 재시작</TableCell>
                  <TableCell style={thStyles}>로그 업로드</TableCell>
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    <Checkbox
                      value={checkedList.get(row.original['id']) || false}
                      checked={checkedList.get(row.original['id']) || false}
                      onChange={(event) => {
                        setCheckedList((prev) => {
                          const newMap = new Map(prev);
                          newMap.set(
                            row.original['id'],
                            event.target.checked ? row.original : null,
                          );
                          return newMap;
                        });
                      }}
                    />
                  </TableCell>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} style={tdStyles}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    <Select
                      value={versionMap.get(row.original['id']) || 'latest'}
                      onChange={(event) =>
                        handleChangeVersion(event, row.original['id'])
                      }
                    >
                      {versions.map((version) => (
                        <MenuItem value={version}>{version}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    <button
                      onClick={async () => {
                        await onUpdate(row);
                      }}
                      style={{ width: '100%', height: '64px' }}
                    >
                      업데이트
                    </button>
                  </TableCell>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    <button
                      onClick={async () => {
                        await onRestart(row);
                      }}
                      style={{ width: '100%', height: '64px' }}
                    >
                      재시작
                    </button>
                  </TableCell>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    {row?.original?.ccuType !== '미사용' && (
                      <button
                        onClick={async () => {
                          await onUpdateCCU(row);
                        }}
                        style={{ width: '100%', height: '64px' }}
                      >
                        업데이트
                      </button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    {row?.original?.ccuType !== '미사용' && (
                      <button
                        onClick={async () => {
                          await onRestartCCU(row);
                        }}
                        style={{ width: '100%', height: '64px' }}
                      >
                        재시작
                      </button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ ...tdStyles, ...{ padding: 0, margin: 0 } }}
                  >
                    <button
                      onClick={async () => {
                        await onUploadLog(row);
                      }}
                      style={{ width: '100%', height: '64px' }}
                    >
                      업로드
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </FlexRow>
    </FlexCenteredCol>
  );
};

export { AccoTable, KioskTable };

