import { CircularProgress } from '@material-ui/core';
import { FlexCol } from './Layout';

const Loading = () => {
  return (
    <FlexCol style={{ width: '100%', height: '100%' }}>
      <CircularProgress
        style={{
          width: '64px',
          height: '64px',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50vh',
          marginLeft: 'calc(50vw - 32px)',
        }}
      />
    </FlexCol>
  );
};

export default Loading;
