import { useQuery } from '@apollo/client';
import {
  CommonPrefix,
  ListObjectsCommand,
  ListObjectsOutput,
} from '@aws-sdk/client-s3';
import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FlexCol } from '../../../components/Layout';
import { GET_SINGLE_ACCOMMODATION } from '../../../graphql/query';
import client from '../../../helpers/S3Client';
import { Accommodation, Kiosk, Maybe } from '../../../typedefs/gql';

type Props = {
  selectedId: Maybe<string>;
  selectedKioskId?: Maybe<string>;
  kiosks?: Maybe<Kiosk>[];
  onKioskClick: (kiosk?: Maybe<Kiosk>) => void;
};

const KioskLists = ({
  selectedId,
  selectedKioskId,
  kiosks,
  onKioskClick,
}: Props) => {
  const [logs, setLogs] = useState<ListObjectsOutput>();

  const listObjectsCommand = new ListObjectsCommand({
    Bucket: 'vendit-kiosk-logs',
    Delimiter: `/`,
    Prefix: `${selectedId}/`,
  });

  const onMount = async () => {
    const response = await client.send(listObjectsCommand);
    console.log(response);
    if (response) {
      setLogs(response);
    }
  };

  useEffect(() => {
    if (selectedId) {
      onMount();
    }
  }, [selectedId]);

  return selectedId && logs ? (
    <>
      {logs?.CommonPrefixes?.map((log) => {
        const kiosk = kiosks?.find(
          (kiosk) => kiosk?.id === log?.Prefix?.split('/')[1],
        );
        console.log(kiosk);
        const selected = selectedKioskId === log?.Prefix?.split('/')[1];
        return kiosk ? (
          <Box
            padding={3}
            margin={1}
            width={300}
            key={log?.Prefix}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            style={{
              background: selected ? 'black' : 'white',
              color: selected ? 'white' : 'black',
            }}
            onClick={() => onKioskClick?.(kiosk)}
          >
            <Typography variant="h5">
              {`${kiosk?.name} - ${kiosk?.connectionState} 
              버전명: ${kiosk?.options?.version}`}
            </Typography>
            <Button
              variant="outlined"
              color={selected ? 'secondary' : 'primary'}
            >
              선택
            </Button>
          </Box>
        ) : null;
      })}
    </>
  ) : null;
};

export default KioskLists;
