import {
  CommonPrefix,
  GetObjectCommand,
  ListObjectsCommand,
  ListObjectsOutput,
} from '@aws-sdk/client-s3';
import { Box, Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import client from '../../../helpers/S3Client';

type Props = {
  bucketPrefix?: string;
};
function download(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

const LogLists = ({ bucketPrefix }: Props) => {
  const [logs, setLogs] = useState<ListObjectsOutput>();

  const listObjectsCommand = new ListObjectsCommand({
    Bucket: 'vendit-kiosk-logs',
    Prefix: `${bucketPrefix}`,
  });

  const handleDownload = async (key: string, fileName: string) => {
    const getObjectCommand = new GetObjectCommand({
      Bucket: 'vendit-kiosk-logs',
      Key: key,
    });
    try {
      const response = await client.send(getObjectCommand);
      console.log(response);

      const { Body } = response;
      if (Body instanceof ReadableStream) {
        const reader = Body.getReader();
        const newReader = await new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
        const newResponse = new Response(newReader);
        const blob = await newResponse.blob();
        download(blob, fileName);
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onMount = async () => {
    const response = await client.send(listObjectsCommand);
    console.log(response);
    if (response) {
      setLogs(response);
    }
  };

  useEffect(() => {
    if (bucketPrefix) {
      onMount();
    }
  }, [bucketPrefix]);

  return logs ? (
    <>
      {logs?.Contents?.sort((a, b) => {
        if ((a?.Key || '') < (b?.Key || '')) {
          return 1;
        }
        return -1;
      }).map((log) => {
        return (
          <Box
            padding={3}
            margin={1}
            width={300}
            key={log?.Key}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            style={{
              background: 'white',
            }}
          >
            <Typography variant="h5">
              {log.Key?.split('/')[log.Key?.split('/').length - 1]}
            </Typography>
            <Button
              variant="outlined"
              color={'primary'}
              onClick={() =>
                handleDownload(
                  log.Key || '',
                  log.Key?.split('/')[log.Key?.split('/').length - 1] || '',
                )
              }
            >
              다운로드
            </Button>
          </Box>
        );
      })}
    </>
  ) : null;
};

export default LogLists;
