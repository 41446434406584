import { gql } from '@apollo/client';

export const ACCOMMODATION_FIELDS = gql`
  fragment AccommodationFields on Accommodation {
    id
    name
    thumbnail
    address1
    address2
    contact
    csContact
    registererName
    registrationNumber
    defaultCheckIn
    defaultCheckOut
    encryptionKey
    cardData
    cleanCardData
  }
`;

export const KIOSK_OPTIONS_FIELDS = gql`
  fragment KioskOptionsFields on KioskOptions {
    theme
    idleMusic
    autoRebootInterval
    autoRebootTime
    printReceiptStrategy
    collectContactStrategy
    firstUpperCameraDeviceId
    secondUpperCameraDeviceId
    qrCameraDeviceId
    ccuComPort
    version
    ioBoardComPort
    rfidWriterComPort
    thermalPrinterComPort
    firstCashDispensorAmount
    secondCashDispensorAmount
    icCardReaderNumber
    kakaoAPIKey
    useCheckVehicle
    useCheckPassport
    useBlockMinor
    useLateCheckout
    useDetectGhostClean
    usePreventAutoClean
    useRoomNumberConfirm
    useExtendReservationExpireAt
    ccuType
    cardType
    mainMenuLayout
    mainMenuHeading
    cardReaderComPort
    cashInjectorPort
    firstCashDispensorPort
    secondCashDispensorPort
    maxKeyCardAmount

    useAuthCancel
    collectSleepsStrategy
    restrictBlockAddresses
    logSuspicious
    logDeviceNotFoundIgnore
    keyCardServerAddress
    keyCardEncoder
    customCCUOption
    customTermConfirm

    idAuthenticatorType
    billDispenserStrategy
    qrReaderType
    qrReaderComPort
    icCardReaderType
    reservationRenderList
    onSiteRenderList
    customKioskOption

    mustCollectContact

    onSiteAdditionalPayList
    onSiteInstantPayList

    relayComPort
    relayConnectList

    useUnderAgeLimit

    blockMinorAgeBase
  }
`;

export const ROOM_FIELDS = gql`
  fragment RoomFields on Room {
    id
    name
    number
    order
    key
    encryptionKey
    cardData
    roomDevices {
      id
      name
    }
  }
`;
