import { useMutation, useQuery } from '@apollo/client';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useMemo, useState } from 'react';
import { ComponentContainer, FlexCol } from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import { KioskTable } from '../../components/Table';
import { SEND_CONTROL_PACKET } from '../../graphql/mutation';
import { GET_ALL_ACCOMMODATION_KIOSKS } from '../../graphql/query';
import { Accommodation } from '../../typedefs/gql';

const AdminKioskControl = () => {
  const { loading, data, error, refetch } = useQuery(
    GET_ALL_ACCOMMODATION_KIOSKS,
  );

  const [emitSendKioskControlPacket] = useMutation(SEND_CONTROL_PACKET);

  const [accommodations, kiosks] = useMemo(() => {
    if (loading) return [[], []];

    const allAccommodations: Accommodation[] = data?.[
      'getAccommodations'
    ]?.edges?.map((edge) => edge?.node);

    const sortedAccommodations = allAccommodations?.sort((a, b) => {
      if ((a?.address1 || '') < (b?.address1 || '')) return -1;
      if ((a?.address1 || '') > (b?.address1 || '')) return 1;
      if (a?.address1 === b?.address1) return 0;
      return 0;
    });

    const flatKiosks = allAccommodations
      ?.map((accommodation) => {
        const modifiedKiosks = accommodation?.kiosks?.map((kiosk) => {
          return {
            accommodationName: accommodation.name,
            address: accommodation.address1?.split(' ')?.[0],
            ...kiosk,
          };
        });

        return modifiedKiosks;
      })
      ?.flat()
      ?.map((k, idx) => {
        const modifiedKiosk = { no: idx + 1, ...k };

        const kioskOptions = k.options;

        delete modifiedKiosk.options;

        const adminStrategy = kioskOptions?.activateAdminStrategy || [];

        return {
          ...modifiedKiosk,
          ...kioskOptions,
          connectionState:
            (modifiedKiosk?.connectionState === 'connected' && '연결됨') ||
            '연결 끊김',
          type:
            (modifiedKiosk?.type === 'motel' && '1세대 스탠드형 (모텔)') ||
            (modifiedKiosk?.type === 'hotel' && '1세대 스탠드형 (호텔)') ||
            (modifiedKiosk?.type === 'secondGenTable' && '2세대 테이블형') ||
            (modifiedKiosk?.type === 'secondGenStand' && '2세대 스탠드형') ||
            (modifiedKiosk?.type === 'thirdGenStand' && '3세대 스탠드형') ||
            (modifiedKiosk?.type === 'mini' && '2세대 미니') ||
            (modifiedKiosk?.type === 'sanha' && '1세대 스탠드형 산하') ||
            (modifiedKiosk?.type === 'sanhaMini' && '2세대 미니 (산하)') ||
            (modifiedKiosk?.type === 'smartKeyCardDispenser' &&
              '스마트 카드 발급기') ||
            modifiedKiosk?.type,
          version:
            (kioskOptions?.version === '0.0.1' && '알 수 없음') ||
            (kioskOptions?.version?.split('.')?.length &&
              kioskOptions?.version) ||
            '알 수 없음',
          cardType: kioskOptions?.cardType,
          ccuType:
            (kioskOptions?.ccuType === 'none' && '미사용') ||
            (kioskOptions?.ccuType === 'Seereal' && '씨리얼') ||
            (kioskOptions?.ccuType === 'TheMR' && '더엠알') ||
            (kioskOptions?.ccuType === 'Garam' && '가람') ||
            (kioskOptions?.ccuType === 'Jada369' && '자다369') ||
            (kioskOptions?.ccuType === 'Romasys' && '로마시스') ||
            (kioskOptions?.ccuType === 'Icrew' && '아이크루') ||
            (kioskOptions?.ccuType === 'Hermes' && '헤르메스') ||
            (kioskOptions?.ccuType === 'Wooyeon' && '우연') ||
            (kioskOptions?.ccuType === 'Future' && '미래') ||
            (kioskOptions?.ccuType === 'SindoEDS' && '신도EDS') ||
            (kioskOptions?.ccuType === 'Micronic' && '마이크로닉') ||
            (kioskOptions?.ccuType === 'NewGaram' && '가람(신)') ||
            (kioskOptions?.ccuType === 'Hms' && 'HMS') ||
            (kioskOptions?.ccuType === 'Jnd' && 'JND') ||
            kioskOptions?.ccuType,
          ccuVersion:
            (kioskOptions?.ccuVersion === '0.0.1' && '알 수 없음') ||
            kioskOptions?.ccuVersion ||
            '알 수 없음',
          useStandaloneCCU:
            (!!kioskOptions?.useStandaloneCCU && '예') || '아니오',
          useAdmin: adminStrategy?.[0] && 'ON',
          useAdvancedAdmin: adminStrategy?.[1] && 'ON',
          useKeyIssueAdmin: adminStrategy?.[2] && 'ON',
          useKeySyncAdmin: adminStrategy?.[3] && 'ON',
          useAgreementTerms: (kioskOptions?.useAgreementTerms && 'ON') || 'OFF',
          useBlockMinorInReservation:
            (kioskOptions?.useAgreementTerms && 'ON') || 'OFF',
          useBlockMinor: (kioskOptions?.useAgreementTerms && 'ON') || 'OFF',
          blockMinorAgeBase: kioskOptions?.blockMinorAgeBase || '알 수 없음',
        };
      });

    return [sortedAccommodations, flatKiosks];
  }, [data]);

  const [isSnack, setIsSnack] = useState(false);

  const [filteredAccommodation, setFilteredAccommodation] = useState<
    Accommodation[]
  >([]);

  const [selectProvince, setSelectProvince] = useState('');

  const onSuccess = () => {
    setIsSnack(true);

    setTimeout(() => {
      setIsSnack(false);
    }, 1250);
  };

  const onError = (msg: any) => {
    console.error('ERROR FOUND', msg);
    setIsSnack(true);

    setTimeout(() => {
      setIsSnack(false);
    });
  };

  const onRefresh = async () => {
    console.log('REFRECH!');
    try {
      await refetch();
    } catch (e) {
      console.error('Refetch Error.', e);
    }
  };

  const reqVersionUpdateAllKiosk = async () => {
    try {
      const allKiosksByAccommodation: any[] = accommodations.map((acco) => {
        const { id, name, kiosks: _kiosks } = acco;

        const kiosks = _kiosks.flat();

        return kiosks;
      });

      const allKiosks = allKiosksByAccommodation.flat();

      const kiosksId = allKiosks.map((kiosk) => kiosk.id);

      for (let i = 0; i < kiosksId.length; i++) {
        try {
          await emitSendKioskControlPacket({
            variables: {
              kioskId: kiosksId[i],
              data: JSON.stringify({ controlType: 'updateKiosk' }),
            },
          });
          console.log('kiosk', i, 'update complete.');
        } catch (e) {
          console.error('kiosk', i, 'update error.');
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  const reqVersionUpdateV1ToKiosk = async ({ kioskId }: { kioskId: any }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ controlType: 'updateKiosk' }),
        },
      });

      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  const reqVersionUpdateV2ToKiosk = async ({
    kioskId,
    channel,
  }: {
    kioskId: any;
    channel?: string;
  }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({
            controlType: 'updateKioskV2',
            channel,
          }),
        },
      });

      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  const reqRestartKiosk = async ({ kioskId }: { kioskId: any }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ controlType: 'restartKiosk' }),
        },
      });

      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  const reqVersionUpdateToCCUClient = async ({ kioskId }: { kioskId: any }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ controlType: 'updateCCU' }),
        },
      });

      setIsSnack(true);

      setTimeout(() => {
        setIsSnack(false);
      }, 1250);
    } catch (err) {
      onError(err);
    }
  };

  const reqRestartCCUClient = async ({ kioskId }: { kioskId: any }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ controlType: 'restartCCU' }),
        },
      });

      setIsSnack(true);

      setTimeout(() => {
        setIsSnack(false);
      }, 1250);
    } catch (err) {
      onError(err);
    }
  };

  const reqUploadLogFromKiosk = async ({ kioskId }: { kioskId: any }) => {
    try {
      await emitSendKioskControlPacket({
        variables: {
          kioskId,
          data: JSON.stringify({ controlType: 'uploadLog' }),
        },
      });

      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    setFilteredAccommodation(accommodations);
  }, [accommodations]);

  useEffect(() => {
    if (selectProvince === '전체') {
      setFilteredAccommodation(accommodations);
    } else {
      if (accommodations?.length > 0) {
        const refilteredAccommodation = accommodations?.filter(
          (accommodation) =>
            accommodation?.address1?.split(' ')?.[0]?.match(selectProvince),
        );

        setFilteredAccommodation(refilteredAccommodation);
      }
    }
  }, [selectProvince]);

  const columns = useMemo(() => {
    console.log('KIOSKS', kiosks);
    return [
      { accessor: 'no', Header: '순서' },
      { accessor: 'accommodationName', Header: '지점명' },
      { accessor: 'address', Header: '주소' },
      { accessor: 'name', Header: '이름' },
      { accessor: 'connectionState', Header: '연결 상태' },
      {
        accessor: 'type',
        Header: '타입',
      },
      {
        accessor: 'version',
        Header: '버전',
      },
      { accessor: 'cardType', Header: '카드 타입' },
      { accessor: 'ccuType', Header: 'CCU 타입' },
      {
        accessor: 'ccuVersion',
        Header: 'CCU 버전',
      },
      {
        accessor: 'useAdmin',
        Header: '관리자',
      },
      {
        accessor: 'useAdvancedAdmin',
        Header: '고급 관리자',
      },
      {
        accessor: 'useKeyIssueAdmin',
        Header: '키 발급 모드',
      },
      {
        accessor: 'useAgreementTerms',
        Header: '인증 약관 동의',
      },
      {
        accessor: 'useBlockMinorInReservation',
        Header: '예약 체크인 인증 여부',
      },
      {
        accessor: 'useBlockMinor',
        Header: '현장 결제 인증 여부',
      },
      {
        accessor: 'blockMinorAgeBase',
        Header: '인증 나이 정책',
      },
    ];
  }, [kiosks]);

  return (
    <FlexCol
      style={{ height: 'inherit', backgroundColor: 'rgb(208, 208, 208)' }}
    >
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <ComponentContainer style={{ height: '100%', overflow: 'scroll' }}>
            <KioskTable
              columns={columns}
              data={kiosks}
              onUpdateV1={reqVersionUpdateV1ToKiosk}
              onUpdateV2={reqVersionUpdateV2ToKiosk}
              onRestart={reqRestartKiosk}
              onUploadLog={reqUploadLogFromKiosk}
              onUpdateCCU={reqVersionUpdateToCCUClient}
              onRestartCCU={reqRestartCCUClient}
              onRefresh={onRefresh}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isSnack}
              autoHideDuration={2000}
              onClose={() => setIsSnack(false)}
              key={'top-center'}
            >
              <Alert onClose={() => setIsSnack(false)} severity="success">
                원격 요청이 전송되었습니다
              </Alert>
            </Snackbar>
          </ComponentContainer>
        </>
      )}
    </FlexCol>
  );
};

export default AdminKioskControl;
