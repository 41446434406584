import { useMutation, useQuery } from '@apollo/client';
import { Button, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Fragment, useMemo, useState } from 'react';
import {
  FlexCenteredCol,
  FlexCol,
  FlexRow,
  FlexSpaceBetweenRow,
} from '../../components/Layout';
import { ACTIVATE_SINGLE_ACCOMMODATION } from '../../graphql/mutation';
import { GET_IS_ACTIVE_ACCOMMODATIONS } from '../../graphql/query';
import { useAuth } from '../../hooks/useAuth';

const Activation = () => {
  const { accessLevel, accessToken, isAccess } = useAuth();

  const { data } = useQuery(GET_IS_ACTIVE_ACCOMMODATIONS);

  const [emitActivateAccommodation] = useMutation(
    ACTIVATE_SINGLE_ACCOMMODATION,
  );

  const [accommodations] = useMemo(() => {
    if (!data) return [];

    return [data?.getIsActiveAccommodations];
  }, [data]);

  const [isSnack, setIsSnack] = useState(false);

  const activateAccommodation = async (accommodationId: string) => {
    try {
      const result = await emitActivateAccommodation({
        variables: {
          accommodationId: accommodationId,
        },
      });

      if (result) {
        setIsSnack(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Fragment>
      {accessLevel === 'ADMIN' || accessLevel === 'SUPERADMIN' ? (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            paddingTop: '10rem',
          }}
        >
          <Typography variant="h5" style={{ marginBottom: '32px' }}>
            활성화가 필요한 지점 목록
          </Typography>
          <FlexCol style={{ height: '60vh', overflow: 'auto' }}>
            <FlexCenteredCol
              style={{
                height: '100%',
                overflow: 'auto',
                width: 'fit-content',
              }}
            >
              {accommodations?.length > 0 ? (
                accommodations?.map((accommodation: any, index: number) => {
                  return (
                    <FlexCol key={accommodation?.name}>
                      <FlexSpaceBetweenRow
                        style={{
                          alignItems: 'center',
                          width: '320px',
                          margin: '8px 0',
                        }}
                      >
                        <FlexRow>
                          <Typography variant="h6">
                            {accommodation?.name}
                          </Typography>
                        </FlexRow>
                        <FlexRow>
                          <Button
                            variant="contained"
                            onClick={async () => {
                              await activateAccommodation(accommodation?.id);
                            }}
                          >
                            <Typography variant="h6">활성화</Typography>
                          </Button>
                        </FlexRow>
                      </FlexSpaceBetweenRow>
                    </FlexCol>
                  );
                })
              ) : (
                <Typography>활성화가 필요한 지점이 없습니다.</Typography>
              )}
            </FlexCenteredCol>
          </FlexCol>
        </FlexCenteredCol>
      ) : (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            width: '100vw',
            height: 'calc(100vh - 160px)',
            color: '#dd0000',
          }}
        >
          <Typography variant="h3">401 - Access denied</Typography>
          <Typography variant="h4" style={{ marginTop: '64px' }}>
            접근이 차단되었습니다.
          </Typography>
        </FlexCenteredCol>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnack}
        autoHideDuration={2000}
        onClose={() => setIsSnack(false)}
        key={'top-center'}
      >
        <Alert onClose={() => setIsSnack(false)} severity="success">
          설정이 완료되었습니다
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Activation;

