import { Typography, IconButton, TextField, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FlexCol, FlexCenteredRow } from '../../components/Layout';
import { WrapperModal, InnerModal } from '../../components/Modal';
import { RoomInnerModalHeader } from './UI';

export const RoomModalHeader = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => {
  return (
    <RoomInnerModalHeader style={{ width: '100%' }}>
      <div style={{ width: '64px' }}></div>
      <Typography variant="h6">{title}</Typography>
      <IconButton
        style={{
          width: '64px',
          height: '64px',
          backgroundColor: 'transparent',
        }}
        onClick={onClose}
      >
        <Close style={{ color: '#ffffff' }} />
      </IconButton>
    </RoomInnerModalHeader>
  );
};

// export const RoomControlModal = ({
//   children,
//   modalName,
//   onClose,
//   onConfig,
// }: {
//   children: JSX.Element;
//   modalName: string;
//   onClose: () => void;
//   onConfig: () => Promise<void>;
// }) => {
//   return (
//     <WrapperModal open={!!modalName}>
//       <>
//         {modalName === 'CREATE_ROOM' && (
//           <InnerModal style={{ maxWidth: '440px', maxHeight: '400px' }}>
//             <RoomModalHeader title="객실 생성" onClose={onClose} />

//             <FlexCol style={{ height: '120px', padding: '32px' }}>
//               {children}

//               {/* <TextField
//                 label="객실명 (전부 기입하세요)"
//                 value={getRoomValue('name')}
//                 onFocus={handleRoomFocus('name')}
//                 onChange={handleRoomChange}
//                 variant="outlined"
//               /> */}

//               <FlexCenteredRow style={{ height: '96px', margin: '32px 0 0 0' }}>
//                 <Button
//                   variant="contained"
//                   style={{ width: '120px', height: '48px' }}
//                   onClick={async () => {
//                     // setSelectedModal('');
//                     // await createRoom(roomInfo?.name);
//                     onConfig();
//                   }}
//                 >
//                   <Typography variant="h6">설정</Typography>
//                 </Button>
//               </FlexCenteredRow>
//             </FlexCol>
//           </InnerModal>
//         )}
//         {modalName === 'CREATE_ROOM_A_TO_B' && (
//           <InnerModal style={{ maxWidth: '440px', maxHeight: '400px' }}>
//             <RoomModalHeader
//               title={`A -> B 객실 생성`}
//               onClose={() => {
//                 // setSelectedModal('');
//                 // setRoomInfo(roomInfoInit);
//                 onClose();
//               }}
//             />

//             <FlexCenteredRow style={{ height: '64px', padding: '32px' }}>
//               <TextField
//                 label="객실 처음 값"
//                 value={getRoomValue('startNumber')}
//                 onFocus={handleRoomFocus('startNumber')}
//                 onChange={handleRoomChange}
//                 variant="outlined"
//                 style={{ width: '120px' }}
//               />
//               <FlexCenteredRow
//                 style={{ padding: '24px' }}
//               >{`->`}</FlexCenteredRow>
//               <TextField
//                 label="객실 끝 값"
//                 value={getRoomValue('endNumber')}
//                 onFocus={handleRoomFocus('endNumber')}
//                 onChange={handleRoomChange}
//                 variant="outlined"
//                 style={{ width: '120px' }}
//               />
//             </FlexCenteredRow>

//             <FlexCenteredRow style={{ height: '64px' }}>
//               <Button
//                 variant="contained"
//                 style={{ width: '120px', height: '48px' }}
//                 onClick={async () => {
//                   // let roomArr = [];
//                   // for (
//                   //   let i = roomInfo?.startNumber;
//                   //   i <= roomInfo?.endNumber;
//                   //   i++
//                   // ) {
//                   //   roomArr = [...roomArr, { name: `${i}호` }];
//                   // }

//                   // roomArr = roomArr.map((room, idx) => ({
//                   //   ...room,
//                   //   number: idx + 1,
//                   // }));

//                   // await roomArr.reduce(async (roomPromise, room) => {
//                   //   await roomPromise;

//                   //   await emitCreateRoom({
//                   //     variables: {
//                   //       input: {
//                   //         accommodationId: accommodation?.id,
//                   //         roomTypeId: roomType?.id,
//                   //         kioskId: kiosk?.id,
//                   //         name: room?.name,
//                   //         number: room?.number,
//                   //         encryptionKey: 'null',
//                   //         defaultSleeps: 0,
//                   //         maxSleeps: 1,
//                   //         amenities: [],
//                   //       },
//                   //     },
//                   //   });
//                   // }, Promise.resolve());
//                   onConfig();
//                 }}
//               >
//                 <Typography variant="h6">생성</Typography>
//               </Button>
//             </FlexCenteredRow>
//           </InnerModal>
//         )}
//         {modalName === 'RCU_SYNC' && (
//           <InnerModal style={{ maxWidth: '440px', maxHeight: '400px' }}>
//             <RoomModalHeader title={`RCU ID 동기화`} onClose={onClose} />

//             <FlexCol style={{ height: '120px', padding: '32px' }}>
//               <TextField
//                 label="RCU ID 시작 값"
//                 value={getRoomValue('startRCUId')}
//                 onFocus={handleRoomFocus('startRCUId')}
//                 onChange={handleRoomChange}
//                 variant="outlined"
//               />

//               <FlexCenteredRow style={{ height: '96px', margin: '32px 0 0 0' }}>
//                 <Button
//                   variant="contained"
//                   style={{ width: '120px', height: '48px' }}
//                   onClick={async () => {
//                     setSelectedModal('');
//                     await sortRcuId(roomInfo?.startRCUId);
//                   }}
//                 >
//                   <Typography variant="h6">설정</Typography>
//                 </Button>
//               </FlexCenteredRow>
//             </FlexCol>
//           </InnerModal>
//         )}
//         {modalName === 'RCU_SYNC_A_TO_B' && (
//           <InnerModal>
//             <RoomModalHeader title={`RCU ID A ~ B 동기화`} onClose={onClose} />

//             <FlexCol style={{ height: '120px', padding: '32px' }}>
//               <FlexCenteredRow>
//                 <TextField
//                   label={`${roomInfo?.startRoomName} RCU ID`}
//                   value={getRoomValue('startRCUId')}
//                   onFocus={handleRoomFocus('startRCUId')}
//                   onChange={handleRoomChange}
//                   variant="outlined"
//                 />
//                 <FlexCenteredRow
//                   style={{ padding: '24px' }}
//                 >{`~`}</FlexCenteredRow>
//                 <TextField
//                   label={`${roomInfo?.lastRoomName} RCU ID`}
//                   value={getRoomValue('lastRCUId')}
//                   onFocus={handleRoomFocus('lastRCUId')}
//                   onChange={handleRoomChange}
//                   variant="outlined"
//                 />
//               </FlexCenteredRow>

//               <FlexCenteredRow style={{ height: '96px', margin: '32px 0 0 0' }}>
//                 <Button
//                   variant="contained"
//                   style={{ width: '120px', height: '48px' }}
//                   onClick={async () => {
//                     setSelectedModal('');
//                     await sortRcuIdBetween();
//                   }}
//                 >
//                   <Typography variant="h6">설정</Typography>
//                 </Button>
//               </FlexCenteredRow>
//             </FlexCol>
//           </InnerModal>
//         )}
//       </>
//     </WrapperModal>
//   );
// };
