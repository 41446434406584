export const extractFloor = (roomName: string) => {
  const roomNumber = roomName.split(/[^0-9]/).filter((val) => val)[0];
  return roomNumber?.slice(0, roomNumber.length - 2) || 0;
};

export const numArrToHexStrArr = (arr: number[]) => {
  return arr.map((i) =>
    i.toString(16).length === 1 ? `0${i.toString(16)}` : i.toString(16),
  );
};

export const hexStrArrToUUID = (arr: string[]) => {
  const joinArrToStr = arr.join('');

  return `${joinArrToStr?.slice(0, 8)}-${joinArrToStr?.slice(
    8,
    12,
  )}-${joinArrToStr?.slice(12, 16)}-${joinArrToStr?.slice(
    16,
    20,
  )}-${joinArrToStr?.slice(20, 32)}$full`;
};

export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
