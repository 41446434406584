import authHandler from "../helpers/authHandler";
import jwt, { JwtPayload } from 'jsonwebtoken';

const useAuth = () => {
  const accessToken = authHandler.accessToken;

  const isAccess: any = accessToken && jwt.decode(accessToken);

  const accessLevel = isAccess?.accessLevel;

  return {accessToken, isAccess, accessLevel}
}

export { useAuth }