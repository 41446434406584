import {
  Apartment,
  AppsOutlined,
  AssignmentTurnedInOutlined,
  CloudDownload,
  DesktopWindows,
  MeetingRoom,
} from '@material-ui/icons';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { Fragment } from 'react';
import { useParams } from 'react-router';
import { CONSTANTS } from '../../commons/constants';
import {
  FlexCenteredCol,
  FooterContainer,
  FooterNavButton,
  FooterNavButtonText,
} from '../../components/Layout';
import authHandler from '../../helpers/authHandler';
import { history } from '../../routes';
import { useAuth } from '../../hooks/useAuth';

const Footer = () => {
  const { id: accommodationId }: any = useParams();

  const { accessLevel, accessToken, isAccess } = useAuth();

  const partnerAdminNavList = [
    '지점 설정',
    '객실 설정',
    '키오스크 / CCU 설정',
    '로그 다운로드',
  ];

  const adminNavList = [
    '지점 로그인',
    '지점 활성화',
    '전체 지점',
    '전체 키오스크',
    '로그 다운로드',
  ];

  const iconStyles = {
    marginBottom: '8px',
    fontSize: '1.7rem',
    filter: `invert(100%)`,
  };

  const onClickMenu = (url: string) => {
    if (
      isAccess?.accessLevel !== 'ADMIN' &&
      isAccess?.accessLevel !== 'SUPERADMIN'
    ) {
      history.push(`${url}/${accommodationId}`);
    }
    if (
      isAccess?.accessLevel === 'ADMIN' ||
      isAccess?.accessLevel === 'SUPERADMIN'
    ) {
      history.push(url);
    }
    history.go(0);
  };

  return (
    <FooterContainer>
      <Fragment>
        {isAccess?.accessLevel !== 'ADMIN' &&
        isAccess?.accessLevel !== 'SUPERADMIN' ? (
          <Fragment>
            {partnerAdminNavList.map((item, idx) => {
              return (
                <FooterNavButton
                  key={item}
                  style={{ flex: 1 }}
                  onClick={() => {
                    if (idx === 0) onClickMenu(CONSTANTS.PATH.ACCOMMODATION);
                    if (idx === 1) onClickMenu(CONSTANTS.PATH.ROOM);
                    if (idx === 2) onClickMenu(CONSTANTS.PATH.KIOSK);
                    if (idx === 3)
                      onClickMenu(CONSTANTS.PATH.ACCOMMODATION_LOG_DOWNLOAD);
                  }}
                >
                  <FlexCenteredCol style={{ alignItems: 'center' }}>
                    {idx === 0 && <Apartment style={iconStyles} />}
                    {idx === 1 && <MeetingRoom style={iconStyles} />}
                    {idx === 2 && <DesktopWindows style={iconStyles} />}
                    {idx === 3 && <CloudDownload style={iconStyles} />}
                    <FooterNavButtonText>{item}</FooterNavButtonText>
                  </FlexCenteredCol>
                </FooterNavButton>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            {adminNavList?.map((item, idx) => {
              return (
                <FooterNavButton
                  key={item}
                  style={{ flex: 1 }}
                  onClick={() => {
                    if (idx === 0) onClickMenu(CONSTANTS.PATH.ADMIN_MODE);
                    if (idx === 1) onClickMenu(CONSTANTS.PATH.ADMIN_ACTIVATION);
                    if (idx === 2) onClickMenu(CONSTANTS.PATH.ADMIN_ACCOS);
                    if (idx === 3) onClickMenu(CONSTANTS.PATH.ADMIN_KIOSKS);
                    if (idx === 4) onClickMenu(CONSTANTS.PATH.LOG_DOWNLOAD);
                  }}
                >
                  <FlexCenteredCol style={{ alignItems: 'center' }}>
                    {idx === 0 && <Apartment style={iconStyles} />}
                    {idx === 1 && (
                      <AssignmentTurnedInOutlined style={iconStyles} />
                    )}
                    {idx === 2 && <AppsOutlined style={iconStyles} />}
                    {idx === 3 && <AppsOutlined style={iconStyles} />}
                    {idx === 4 && <CloudDownload style={iconStyles} />}
                    <FooterNavButtonText>{item}</FooterNavButtonText>
                  </FlexCenteredCol>
                </FooterNavButton>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    </FooterContainer>
  );
};

export default Footer;
