export const connectionTemplate = (nodeFragment) => `
  edges {
    cursor
    node {
      ${nodeFragment}
    }
  }
  pageInfo {
    endCursor
    hasNextPage
  }
`;
