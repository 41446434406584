import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  Dispatch,
  FocusEventHandler,
  ReactChild,
  ReactFragment,
  ReactPortal,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
  FlexSpaceBetweenRow,
} from '../../components/Layout';
import { RoomInnerModalHeader } from './UI';
import {
  deserialize1024,
  deserialize512,
  isLegacyCardData,
  isSerializedData,
} from '../../utils/utils';
import {
  CustomRoomDetailModal,
  CustomRoomInnerModal,
  CustomRoomModalWrapper,
} from '../../components/Modal';
import { useMutation } from '@apollo/client';
import { DELETE_ROOM_DEVICE } from '../../graphql/mutation';
import { RoomInfo } from '../../hooks/useRoomControl';

const RoomModifyModal = ({
  roomInfo,
  setRoomInfo,
  onClose,
  getValue,
  onFocus,
  onChange,
  onSave,
}: {
  roomInfo: RoomInfo;
  setRoomInfo: Dispatch<SetStateAction<any>>;
  onClose: () => void;
  getValue: (str: string) => string;
  onFocus: (str: string) => FocusEventHandler;
  onChange: () => void;
  onSave: () => Promise<void>;
}) => {
  const [emitDeleteRoomDevice] = useMutation(DELETE_ROOM_DEVICE);

  const [origin, setOrigin] = useState('');

  const roomItems = [
    { key: '객실명', value: 'name' },
    { key: 'RCU ID', value: 'number' },
    { key: '도어락 ID', value: 'order' },
    { key: '키 값', value: 'key' },
    { key: '암호화 키 값', value: 'encryptionKey' },
  ];
  const cardDataItems = [
    { name: '원본', cardDataType: roomInfo?.originCardDataType || '' },
    { name: '레거시', cardDataType: 'Legacy' },
    { name: 'MCT', cardDataType: 'MCT' },
    { name: 'Hex', cardDataType: 'HEX' },
    { name: '[ [] ] → 1024', cardDataType: 'DoubleArrayTo1K' },
    { name: '[ [] ] → 512', cardDataType: 'DoubleArrayTo512' },
  ];

  // useEffect(() => {
  //   if (roomInfo) {
  //     const { id, name, key, cardData } = roomInfo;

  //     const bcc = key
  //       ?.slice(0, 8)
  //       ?.match(/.{1,2}/g)
  //       ?.map((i: string) => parseInt(i, 16))
  //       ?.reduce((a: number, c: number) => a ^ c);
  //     const keyToBcc = parseInt(key?.slice(9, 11), 16);
  //     const cardDataToHex = arrToHexStrArr(JSON.parse(cardData)?.[0]);
  //     const HexToKey = hexArrToUUID(cardDataToHex);

  //     console.log('객실명 : ', name);
  //     console.log('객실 카드키 : ', key);
  //     console.log('객실 데이터 : ', HexToKey);
  //     console.log('키 BCC 검증 : ', bcc === keyToBcc);
  //     console.log('카드키 검증 : ', bcc === keyToBcc);
  //   }
  // }, []);

  useEffect(() => {
    const cardData = roomInfo?.originCardData;

    if (isLegacyCardData(cardData)) {
      console.log('Legacy Data Found.');
      console.log(cardData);
      const filterCardData = JSON?.parse(cardData)?.map((i) => i?.data);
      setOrigin(JSON.stringify(filterCardData));
    }

    if (isSerializedData(cardData)) {
      console.log('Serialized Data Found.');
      const deserialized512 = deserialize512(cardData);
      const deserialized1024 = deserialize1024(cardData);

      if (deserialized512?.length === 64) {
        console.log('Serialized 512 Data Found.');
        console.log('Deserialize 512 : ', deserialized512);
        console.log('Manufacturer Block : ', deserialized512?.[0]);
        setOrigin(JSON.stringify(deserialized512));
      }

      if (deserialized512?.length === 128) {
        console.log('Serialized 1024 Data Found.');
        console.log('Deserialize 1024 : ', deserialized1024);
        setOrigin(JSON.stringify(deserialized1024));
      }
    }
  }, []);

  return (
    <CustomRoomModalWrapper>
      <CustomRoomInnerModal>
        <RoomInnerModalHeader>
          <FlexCol style={{ justifyContent: 'center', width: '100%' }}>
            <FlexRow
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 'inherit',
              }}
            >
              <FlexRow style={{ alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    padding: '0 0 0 24px',
                  }}
                >
                  {`${roomInfo?.originName}`}
                </Typography>

                <Typography
                  style={{
                    fontSize: '0.8rem',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    padding: '2px 0 0 12px',
                  }}
                >
                  {`${roomInfo?.id}`}
                </Typography>
              </FlexRow>

              <IconButton
                style={{
                  width: '64px',
                  height: '64px',
                  backgroundColor: 'transparent',
                }}
                onClick={onClose}
              >
                <Close style={{ color: '#ffffff' }} />
              </IconButton>
            </FlexRow>
          </FlexCol>
        </RoomInnerModalHeader>

        <FlexCol
          style={{
            height: '100%',
            maxHeight: '540px',
            padding: '24px 24px 0 24px ',
          }}
        >
          <FlexRow style={{ justifyContent: 'space-around' }}>
            {roomItems?.slice(0, 3)?.map((item, idx, arr) => (
              <FormControl
                key={item?.key}
                style={{
                  flex: idx === 0 ? 2 : 1,
                  marginTop: '8px',
                  marginBottom: '8px',
                  marginRight: idx !== arr.length - 1 ? '16px' : '',
                }}
              >
                <TextField
                  label={item?.key}
                  value={getValue(item?.value)}
                  onFocus={onFocus(item?.value)}
                  onChange={onChange}
                  variant="outlined"
                />
              </FormControl>
            ))}
          </FlexRow>

          {roomItems?.slice(3)?.map((item) => (
            <FormControl
              key={item?.key}
              style={{
                width: '100%',
                marginTop: '8px',
                marginBottom: '8px',
              }}
            >
              <TextField
                label={item?.key}
                value={getValue(item?.value)}
                onFocus={onFocus(item?.value)}
                onChange={onChange}
                variant="outlined"
              />
            </FormControl>
          ))}

          <Button
            onClick={async () => {
              console.log(roomInfo);

              const roomDevices = roomInfo?.roomDevices;

              await roomDevices?.reduce(async (promise, roomDevice) => {
                await promise;

                console.log('RoomDevice :: ', roomDevice);

                try {
                  await emitDeleteRoomDevice({
                    variables: {
                      id: roomDevice?.id,
                    },
                  });

                  console.log('Delete RoomDevice Complete', roomDevice);
                } catch (err) {
                  console.error('Delete RoomDevice Error', err);
                }
              }, Promise.resolve());
            }}
          >
            객실 내 기기 정보 초기화 (주의)
          </Button>

          {/* 객실 카드 데이터 */}
          <FlexSpaceBetweenRow
            style={{ alignItems: 'center', margin: '16px 0 0 0' }}
          >
            {roomInfo?.cardDataType ? (
              <InputLabel>{`객실 카드 데이터 |  ${roomInfo?.cardDataType} 방식`}</InputLabel>
            ) : roomInfo?.cardData ? (
              <InputLabel>{`객실 카드 데이터 |  알 수 없음`}</InputLabel>
            ) : (
              <InputLabel>{`객실 카드 데이터 |  비어 있음`}</InputLabel>
            )}
          </FlexSpaceBetweenRow>

          {/* 카드 데이터 선택 */}
          <FlexRow style={{ width: '100%', marginTop: '16px' }}>
            <ButtonGroup
              size="small"
              aria-label="small outlined button group"
              style={{ width: '100%' }}
            >
              {cardDataItems?.map((card) => {
                return (
                  <Button
                    onClick={() => {
                      setRoomInfo({
                        ...roomInfo,
                        cardData: roomInfo?.originCardData,
                        cardDataType: card?.cardDataType,
                      });
                    }}
                    style={{ flex: 1 }}
                  >
                    {card?.name}
                  </Button>
                );
              })}
            </ButtonGroup>
          </FlexRow>

          <FlexCenteredRow style={{ margin: '16px 0 0 0' }}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <TextareaAutosize
                minRows={14}
                maxRows={14}
                value={getValue('cardData')}
                onFocus={onFocus('cardData')}
                onChange={onChange}
                style={{ lineHeight: '1.5', padding: '4px 52px' }}
              />
            </FormControl>
          </FlexCenteredRow>

          <Typography
            align="right"
            style={{ margin: '4px 0 0 0', color: '#cc0000' }}
          >
            주의 : 변경 전에 데이터를 백업할 것
          </Typography>

          <FlexCenteredRow
            style={{ alignItems: 'flex-end', margin: '20px 0 0 0' }}
          >
            <Button
              variant="contained"
              style={{
                width: '100%',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#4f4f4f',
                margin: '0 0 8px 0',
                color: '#fff',
              }}
              onClick={async () => {
                await onSave();
              }}
            >
              <Typography variant="h6">수정</Typography>
            </Button>
          </FlexCenteredRow>
        </FlexCol>
      </CustomRoomInnerModal>

      {origin?.length > 0 && (
        <CustomRoomDetailModal style={{ alignItems: 'center' }}>
          <FlexCenteredRow
            style={{
              alignItems: 'center',
              minHeight: '64px',
              margin: '0 0 8px 0',
            }}
          >
            <Typography variant="h6">카드 데이터 분석</Typography>
          </FlexCenteredRow>

          <FlexCenteredCol>
            <Typography>
              {JSON?.parse(origin)?.map(
                (
                  block: {
                    toString: (arg0: number) => {
                      (): any;
                      new (): any;
                      length: number;
                      toUpperCase: {
                        ():
                          | boolean
                          | ReactChild
                          | ReactFragment
                          | ReactPortal
                          | null
                          | undefined;
                        new (): any;
                      };
                    };
                  }[],
                  blkIdx: number,
                ) => (
                  <>
                    {blkIdx % 4 === 0 && (
                      <Typography
                        style={{
                          fontFamily: 'Consolas, sans-serif',
                          margin: '8px 0',
                        }}
                      >
                        +Sector: {blkIdx / 4}
                      </Typography>
                    )}

                    {blkIdx === 0 && (
                      <Typography
                        style={{
                          fontFamily: 'Consolas, sans-serif',
                          letterSpacing: '.1em',
                        }}
                      >
                        {block.map(
                          (
                            i: {
                              toString: (arg0: number) => {
                                (): any;
                                new (): any;
                                length: number;
                                toUpperCase: {
                                  ():
                                    | boolean
                                    | ReactChild
                                    | ReactFragment
                                    | ReactPortal
                                    | null
                                    | undefined;
                                  new (): any;
                                };
                              };
                            },
                            idx: number,
                          ) =>
                            i.toString(16).length === 1 ? (
                              <span
                                style={{
                                  color:
                                    idx < 5
                                      ? '#880088'
                                      : idx >= 5 && idx <= 7
                                      ? '#cc7700'
                                      : '#aa00aa',
                                }}
                              >{`0${i?.toString(16)?.toUpperCase()}`}</span>
                            ) : (
                              <span
                                style={{
                                  color:
                                    idx < 5
                                      ? '#880088'
                                      : idx >= 5 && idx <= 7
                                      ? '#cc7700'
                                      : '#aa00aa',
                                }}
                              >
                                {i?.toString(16)?.toUpperCase()}
                              </span>
                            ),
                        )}
                      </Typography>
                    )}

                    {blkIdx > 0 && (
                      <Typography
                        style={{
                          fontFamily: 'Consolas, sans-serif',
                          letterSpacing: '.1em',
                        }}
                      >
                        {JSON.stringify(block) ===
                        '[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]'
                          ? block.map(
                              (
                                i: {
                                  toString: (arg0: number) => {
                                    (): any;
                                    new (): any;
                                    length: number;
                                    toUpperCase: {
                                      ():
                                        | boolean
                                        | ReactChild
                                        | ReactFragment
                                        | ReactPortal
                                        | null
                                        | undefined;
                                      new (): any;
                                    };
                                  };
                                },
                                idx: number,
                              ) =>
                                i.toString(16).length === 1 ? (
                                  <span
                                    style={{
                                      color: '#bcbcbc',
                                    }}
                                  >{`0${i?.toString(16)?.toUpperCase()}`}</span>
                                ) : (
                                  <span
                                    style={{
                                      color: '#bcbcbc',
                                    }}
                                  >
                                    {i?.toString(16)?.toUpperCase()}
                                  </span>
                                ),
                            )
                          : block.map(
                              (
                                i: {
                                  toString: (arg0: number) => {
                                    (): any;
                                    new (): any;
                                    length: number;
                                    toUpperCase: {
                                      ():
                                        | boolean
                                        | ReactChild
                                        | ReactFragment
                                        | ReactPortal
                                        | null
                                        | undefined;
                                      new (): any;
                                    };
                                  };
                                },
                                idx: number,
                              ) =>
                                i.toString(16).length === 1 ? (
                                  <span
                                    style={{
                                      color:
                                        blkIdx % 4 === 3
                                          ? idx < 6
                                            ? '#008800'
                                            : idx >= 6 && idx <= 8
                                            ? '#cc7700'
                                            : idx >= 10
                                            ? '#00bb00'
                                            : 'inherit'
                                          : 'inherit',
                                    }}
                                  >{`0${i?.toString(16)?.toUpperCase()}`}</span>
                                ) : (
                                  <span
                                    style={{
                                      color:
                                        blkIdx % 4 === 3
                                          ? idx < 6
                                            ? '#008800'
                                            : idx >= 6 && idx <= 8
                                            ? '#cc7700'
                                            : idx >= 10
                                            ? '#00bb00'
                                            : 'inherit'
                                          : 'inherit',
                                    }}
                                  >
                                    {i?.toString(16)?.toUpperCase()}
                                  </span>
                                ),
                            )}
                      </Typography>
                    )}
                  </>
                ),
              )}
            </Typography>
          </FlexCenteredCol>
        </CustomRoomDetailModal>
      )}
    </CustomRoomModalWrapper>
  );
};

export default RoomModifyModal;
