import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Kiosk from '../containers/Kiosks/Kiosk';
import SignIn from '../containers/SignIn/SignIn';
import Room from '../containers/Room/Room';
import { CONSTANTS } from '../commons/constants';
import Accommodation from '../containers/Accommodation/Accommodation';
import AppRoute from './AppRoute';
import AdminSignIn from '../containers/Admin/AdminSignIn';
import Home from '../containers/Home/Home';
import AdminActivation from '../containers/Activation/Activation';
import Accommodations from '../containers/Accommodation/Accommodations';
import AdminKioskControl from '../containers/Admin/AdminKioskControl';
import NotFound from '../containers/NotFound';
import AccommodationsLog from '../containers/LogDownload';
import AccommodationLog from '../containers/Log/AccommodationLog';
import AdminAccoControl from '../containers/Admin/AdminAccoControl';

export const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <AppRoute exact path={CONSTANTS.PATH.ROOT} Component={Home} />
        <AppRoute path={CONSTANTS.PATH.SIGN_IN} Component={SignIn} />
        <AppRoute path={CONSTANTS.PATH.ADMIN_MODE} Component={AdminSignIn} />
        <AppRoute
          path={CONSTANTS.PATH.ADMIN_ACTIVATION}
          Component={AdminActivation}
        />
        <AppRoute
          path={CONSTANTS.PATH.ADMIN_ACCOS}
          Component={AdminAccoControl}
        />
        <AppRoute
          path={CONSTANTS.PATH.ADMIN_KIOSKS}
          Component={AdminKioskControl}
        />
        <AppRoute
          path={CONSTANTS.PATH.ACCOMMODATIONS}
          Component={Accommodations}
        />
        <AppRoute
          path={`${CONSTANTS.PATH.LOG_DOWNLOAD}`}
          Component={AccommodationsLog}
        />
        <AppRoute
          path={`${CONSTANTS.PATH.ACCOMMODATION}/:id`}
          Component={Accommodation}
        />
        <AppRoute
          path={`${CONSTANTS.PATH.ACCOMMODATION_LOG_DOWNLOAD}/:id`}
          Component={AccommodationLog}
        />
        <AppRoute path={`${CONSTANTS.PATH.KIOSK}/:id`} Component={Kiosk} />
        <AppRoute path={`${CONSTANTS.PATH.ROOM}/:id`} Component={Room} />
        <AppRoute path="*" Component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
