import { Fragment } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { Flex, FlexCenteredCol } from '../../components/Layout';
import styled from 'styled-components';
import {
  MutationFunctionOptions,
  OperationVariables,
  DefaultContext,
  ApolloCache,
  FetchResult,
} from '@apollo/client';

const CustomFlex = styled(Flex)`
  margin: 8px 0;
  padding: 16px 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #d3d3d8;
`;

const KioskTermsArticles = ({
  accommodation,
  article,
  articleState,
  handleArticle,
  createArticle,
  updateArticle,
}: {
  accommodation: any;
  article: any;
  articleState: any;
  handleArticle: (prop: any, additionalProp?: any) => (event: any) => void;
  createArticle: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  updateArticle: () => Promise<void>;
}) => {
  return (
    <Fragment>
      <Typography
        variant="h5"
        style={{ height: '48px', margin: '24px 0 0 32px' }}
      >
        약관 설정
      </Typography>

      <>
        {!article ? (
          <FlexCenteredCol
            style={{
              alignItems: 'center',
              height: '200px',
            }}
          >
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              약관 설정이 없습니다.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: '96px',
                height: '40px',
                marginTop: '64px',
              }}
              onClick={async () => {
                console.log('CLICK!');
                await createArticle({
                  variables: {
                    accommodationId: accommodation?.id,
                  },
                });
              }}
            >
              <Typography>+ 생성</Typography>
            </Button>
          </FlexCenteredCol>
        ) : (
          <>
            <CustomFlex style={{ flexDirection: 'column' }}>
              <FlexCenteredCol>
                <Typography>{`약관 상단 문구 (${
                  articleState?.title?.length || 0
                } / 40)`}</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  multiline
                  minRows={2}
                  InputProps={{ disableUnderline: true }}
                  margin="dense"
                  placeholder="최대 40자 까지 작성 가능합니다"
                  value={articleState?.title || ''}
                  onChange={handleArticle('title')}
                />
              </FlexCenteredCol>

              <FlexCenteredCol style={{ margin: '32px 0 0 0' }}>
                <Typography>{`약관 문구 (${
                  articleState?.terms?.length || 0
                } / 10000)`}</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  multiline
                  minRows={2}
                  InputProps={{ disableUnderline: true }}
                  margin="dense"
                  placeholder="최대 10000자 까지 작성 가능합니다"
                  value={articleState?.terms || ''}
                  onChange={handleArticle('terms')}
                />
              </FlexCenteredCol>
            </CustomFlex>
            <FlexCenteredCol style={{ alignItems: 'center', height: '96px' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '184px', height: '56px' }}
                onClick={updateArticle}
              >
                <Typography>약관 변경</Typography>
              </Button>
            </FlexCenteredCol>
          </>
        )}
      </>
    </Fragment>
  );
};

export { KioskTermsArticles };
