// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  ListObjectsCommand,
  ListObjectsCommandOutput,
} from '@aws-sdk/client-s3';
import { useParams } from 'react-router-dom';
import { FlexCol, FlexRow, WrapperContainer } from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import { GET_SINGLE_ACCOMMODATION } from '../../graphql/query';
import client from '../../helpers/S3Client';
import { Accommodation, Kiosk, Maybe } from '../../typedefs/gql';
import KioskLists from '../LogDownload/components/KioskLists';
import LogLists from '../LogDownload/components/LogLists';

type Props = {};

const AccommodationLog = (props: Props) => {
  const { id: accommodationId } = useParams();

  const {
    error,
    loading,
    data: myAccommodationsData,
  } = useQuery<{
    getSingleAccommodation: Accommodation;
  }>(GET_SINGLE_ACCOMMODATION, {
    variables: {
      accommodationId: accommodationId,
    },
  });

  const [state, setState] = useState<ListObjectsCommandOutput>();

  const [selected, setSelected] = useState<Maybe<string>>(accommodationId);

  const [selectedKiosk, setSelectedKiosk] = useState<Maybe<Kiosk>>(null);

  const listObjectsCommand = new ListObjectsCommand({
    Bucket: 'vendit-kiosk-logs',
    Delimiter: '/',
  });

  const onMount = async () => {
    const response = await client.send(listObjectsCommand);
    if (response) {
      setState(response);
    }
  };

  const onKioskClick = (kiosk?: Maybe<Kiosk>) => {
    setSelectedKiosk(kiosk || null);
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    setSelectedKiosk(null);
  }, [selected]);
  return (
    <WrapperContainer>
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <FlexRow style={{ height: 'inherit' }}>
          <FlexCol style={{ overflow: 'scroll' }}>
            <KioskLists
              selectedId={selected}
              selectedKioskId={selectedKiosk?.id}
              kiosks={myAccommodationsData?.getSingleAccommodation?.kiosks}
              onKioskClick={onKioskClick}
            />
          </FlexCol>
          <FlexCol style={{ overflow: 'scroll' }}>
            <LogLists
              bucketPrefix={
                selectedKiosk?.id
                  ? `${selected}/${selectedKiosk?.id}`
                  : undefined
              }
            />
          </FlexCol>
        </FlexRow>
      )}
    </WrapperContainer>
  );
};

export default AccommodationLog;
