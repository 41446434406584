export const CONSTANTS = {
  ACCESS_TOKEN: '__VENDIT__ACCESS_TOKEN',
  REFRESH_TOKEN: '__VENDIT__REFRESH_TOKEN',
  PARAMETER: {
    DEV: '__vendit__dev__',
  },
  PATH: {
    ROOT: '/',
    SIGN_IN: '/signIn',
    ADMIN_MODE: '/adminMode',
    ADMIN_ACCOS: '/adminAccos',
    ADMIN_KIOSKS: '/adminKiosks',
    ACCOMMODATIONS: '/accommodations',
    ACCOMMODATION: '/accommodation',
    KIOSK: '/kiosk',
    ROOM: '/room',
    ADMIN_ACTIVATION: '/adminActivation',
    LOG_DOWNLOAD: '/logDownload',
    ACCOMMODATION_LOG_DOWNLOAD: '/accommodationLogDownload',
  },
  STYLE_MACRO: {
    BACKGROUND_COLOR_RGB: '192, 192, 192',
    BACKGROUND_COLOR_HEX: '#C0C0C0',
  },
  SECTOR_ADDRESSES: {
    // prettier-ignore
    ALL_BLOCKS: [
      0, 1, 2, 3,
      4, 5, 6, 7,
      8, 9, 10, 11,
      12, 13, 14, 15,
    ],
  },
  BLOCK_ADDRESSES: {
    FIRST_BLOCKS: [0, 1, 2, 3],
    // prettier-ignore
    ALL_BLOCKS: [
      0, 1, 2, 3,
      4, 5, 6, 7,
      8, 9, 10, 11,
      12, 13, 14, 15,
      16, 17, 18, 19,
      20, 21, 22, 23,
      24, 25, 26, 27,
      28, 29, 30, 31,
      32, 33, 34, 35,
      36, 37, 38, 39,
      40, 41, 42, 43,
      44, 45, 46, 47,
      48, 49, 50, 51,
      52, 53, 54, 55,
      56, 57, 58, 59,
      60, 61, 62, 63,
    ],
  },
};

export const isDev = localStorage.getItem(CONSTANTS.PARAMETER.DEV);

export const setIsDev = (str: string) => {
  localStorage.setItem(CONSTANTS.PARAMETER.DEV, str);
};

export const API_HOST =
  isDev === 'true'
    ? 'https://dev.vendit.co.kr'
    : process.env.REACT_APP_API_HOST || 'https://api.vendit.co.kr';

const IMAGE_CLOUDFRONT_HOST = 'https://d2xlbl9i4adme7.cloudfront.net';

const articlesCDNRoot =
  isDev === 'true' ? 'accommodationArticlesDev' : 'accommodationArticles';

export const buildAttachmentCdnSrc = (articleId: string, fileName: string) =>
  `${IMAGE_CLOUDFRONT_HOST}/${articlesCDNRoot}/${articleId}/${fileName}`;
