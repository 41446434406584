import { useEffect } from 'react';
import { CONSTANTS } from '../../commons/constants';
import authHandler from '../../helpers/authHandler';
import { history } from '../../routes';
import { isEmpty } from '../../utils/utils';
import { decode } from 'jsonwebtoken';
import { useAuth } from '../../hooks/useAuth';

const Home = () => {
  const { accessLevel, accessToken, isAccess } = useAuth();

  (() => {
    if (!accessToken) history.push(CONSTANTS.PATH.SIGN_IN);

    if (accessToken && !isEmpty(accessToken)) {
      if (
        new Date().getTime() - new Date(isAccess.updatedAt).getTime() <
        54000000
      ) {
        history.push(CONSTANTS.PATH.ACCOMMODATIONS);
      } else {
        if (authHandler.user) {
          authHandler.signOut();
        }
        history.push(CONSTANTS.PATH.SIGN_IN);
      }
    }
  })();
  return <></>;
};

export default Home;
