import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_ACCOMMODATION } from '../graphql/mutation';
import { GET_ROOMS_IN_SINGLE_ACCOMMODATION } from '../graphql/query';

export const accommodationInfoInit = {
  id: '',
  name: '',
  cardData: '',
  options: [{ key: '', value: '' }],
  hotelCode: '',
};

const useAccommodationControl = ({
  accommodationId,
  onSuccess,
  onError,
}: {
  accommodationId: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  // NOTE: Queries
  const { loading, data, error, refetch } = useQuery(
    GET_ROOMS_IN_SINGLE_ACCOMMODATION,
    {
      variables: {
        accommodationId: accommodationId,
      },
    },
  );

  // NOTE: Mutations
  const [emitUpdateAccommodation] = useMutation(UPDATE_ACCOMMODATION, {});

  const [accommodationInfo, setAccommodationInfo] = useState(
    accommodationInfoInit,
  );

  const updateAccommodation = async (input: {
    id: string;
    cardData?: string;
    clearCardData?: string;
    options?: any[];
  }) => {
    return await emitUpdateAccommodation({
      variables: {
        input,
      },
    });
  };

  const createHotelCode = async () => {
    try {
      const result = await updateAccommodation({
        id: accommodationId,
        // TODO: Hexadecimal support needed.
        options: [{ key: 'hotelCode', value: '02-0001' }],
      });

      if (result) onSuccess();
    } catch (err) {
      onError();
    }
  };

  const updateHotelCode = async ({ hotelCode }: { hotelCode: string }) => {
    try {
      const result = await updateAccommodation({
        id: accommodationId,
        options: [{ key: 'hotelCode', value: hotelCode }],
      });

      if (result) onSuccess();
    } catch (err) {
      onError();
    }
  };

  return {
    loading,
    data,
    error,
    accommodationInfo,
    setAccommodationInfo,
    refetch,
    updateAccommodation,
    createHotelCode,
    updateHotelCode,
  };
};

export default useAccommodationControl;
