import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const FlexCenteredRow = styled(FlexRow)`
  justify-content: center;
`;

export const FlexSpaceBetweenRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const FlexCol = styled(Flex)`
  flex-direction: column;
`;

export const FlexCenteredCol = styled(FlexCol)`
  justify-content: center;
`;

export const FlexSpaceBetweenCol = styled(FlexCol)`
  justify-content: space-between;
`;

export const FlexCenteredAll = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled(FlexSpaceBetweenRow)`
  position: fixed;
  align-items: center;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(32, 32, 32);
  z-index: 999;
`;

export const ComponentContainer = styled(FlexCol)`
  background-color: rgb(224, 224, 224);

  @media screen and (max-width: 768px) {
    /* Mobile Style Sheet */
    align-items: center;
    height: 100%;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    /* Tablet Style Sheet */
    align-items: center;
    height: 100%;
    padding: 24px;
  }

  @media screen and (min-width: 1081px) {
    /* Desktop Style Sheet */
    align-items: center;
    height: 100%;
    padding: 24px;
  }
`;

export const FooterContainer = styled(FlexSpaceBetweenRow)`
  position: fixed;
  align-items: center;
  width: 100%;
  height: 72px;
  bottom: 0;
  background-color: rgb(32, 32, 32);
  z-index: 997;
`;

export const HeaderHomeButton = styled(Button)`
  width: 147px;
  height: inherit;
  background-image: ${(props: any) => `url(${props.logo})`};
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 12px;
  color: #fff;
`;

export const HeaderSignInButton = styled(Button)`
  min-width: 192px;
  margin-right: 16px;
  color: #fff;
`;

export const FooterNavButton = styled(Button)`
  flex-grow: 1;
  height: 100%;
  color: #ffffff;
`;

export const FooterNavButtonText = styled(Typography)`
  height: 100%;
  font-size: 0.8rem;
  color: #ffffff;
`;

export const WrapperContainer = styled(FlexCol)`
  height: inherit;
  background-color: rgb(224, 224, 224);
  padding: 72px 0 72px 0;
  margin: 16px 0 0 0;
`;
