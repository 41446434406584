import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import {
  CREATE_ROOM,
  UPDATE_ROOM,
  DELETE_ROOM,
  UPDATE_ACCOMMODATION,
} from '../graphql/mutation';
import {
  numArrToHexStrArr,
  extractFloor,
  hexStrArrToUUID,
  wait,
} from '../helpers';
import { Room, RoomType } from '../typedefs/gql';
import {
  isSerializedData,
  deserialize1024,
  isEmpty,
  serialize1024,
  getUUIDFormat,
  serialize512,
  isLegacyCardData,
} from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';

type OriginRoomInfo = {
  originName?: string;
  originNumber?: number;
  originKey?: string;
  originCardDataType?: string;
  originCardData?: string;
  originOrder?: string;
  originEncryptionKey?: string;
};

export type RoomInfo =
  | {
      id: string;
      name?: string;
      number?: number;
      key?: string;
      order?: string;
      encryptionKey?: string;
      cardData?: string;
      cardDataType?: string;
      specialType?: string;
      startNumber?: number;
      endNumber?: number;
      startRcuId?: number;
      endRcuId?: number;
      startRoomId?: string;
      endRoomId?: string;
      lastRoomId?: string;
      startRCUId?: string;
      lastRCUId?: string;
      roomDevices?: any[];
    } & OriginRoomInfo;

export const roomInfoInit: RoomInfo = {
  id: '',
  name: '',
  number: 0,
  key: '',
  order: '',
  encryptionKey: '',
  cardData: '',
  cardDataType: '',
  originName: '',
  originNumber: 0,
  originKey: '',
  originCardData: '',
  startNumber: 0,
  endNumber: 0,
  startRcuId: 0,
  endRcuId: 0,
  startRoomId: '',
  endRoomId: '',
  lastRoomId: '',
  startRCUId: '',
  lastRCUId: '',
};

const useRoomControl = ({
  roomsData,
  accommodation,
  accommodationId,
  roomTypeId,
  kioskId,
  onSuccess,
  onError,
}: {
  roomsData: any;
  accommodation: any;
  accommodationId: string;
  roomTypeId: string;
  kioskId: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const [emitUpdateAccommodation] = useMutation(UPDATE_ACCOMMODATION);

  const updateAccommodation = async (input: {
    id: string;
    cardData?: string;
    clearCardData?: string;
    options?: any[];
  }) => {
    return await emitUpdateAccommodation({
      variables: {
        input,
      },
    });
  };

  const [emitCreateRoom] = useMutation(CREATE_ROOM);
  const [emitUpdateRoom] = useMutation(UPDATE_ROOM);
  const [emitDeleteRoom] = useMutation(DELETE_ROOM);

  const [roomInfo, setRoomInfo] = useState(roomInfoInit);

  const [duplicatedData, setDuplicatedData] = useState<string[][] | null>(null);

  const [roomsWithRoomType, roomsWithFloor] = useMemo(() => {
    if (!roomsData) return [[], {}];

    const roomTypes = roomsData?.getSingleAccommodation?.roomTypes;
    const rooms = roomsData?.getSingleAccommodation?.rooms;

    const filteredRoomsWithRoomType: RoomType[] =
      roomTypes
        ?.map((roomType) => roomType?.rooms?.length > 0 && roomType)
        .filter((val) => val) || [];

    const filteredRoomsWithFloor = {};

    // console.log(
    //   !!roomTypes &&
    //     roomTypes.map((roomType) => {
    //       const { prices } = roomType;

    //       const lodgePrices = prices.filter((pri) => pri.priceType === 'LODGE');

    //       return { ...roomType, prices: lodgePrices };
    //     }),
    // );

    rooms?.forEach((room) => {
      const floor = extractFloor(room?.name);

      if (!filteredRoomsWithFloor[floor]) {
        filteredRoomsWithFloor[floor] = [];
      }
      if (filteredRoomsWithFloor[floor]) {
        filteredRoomsWithFloor[floor].push(room);
      }
    });

    return [filteredRoomsWithRoomType, filteredRoomsWithFloor];
  }, [roomsData]);

  const [customInfoOne] = useMemo(() => {
    // console.log(
    //   roomsWithRoomType
    //     ?.map((roomType) => roomType?.rooms)
    //     .flat()
    //     .map((room) => ({ name: room?.name, key: room?.key?.slice(0, 16) })),
    // );

    return [null];
  }, [roomsWithRoomType]);

  const [customInfoTwo] = useMemo(() => {
    //   let rooms;

    //   if (roomsWithRoomType?.length > 1) {
    //     rooms = roomsWithRoomType
    //       ?.map((r) => r?.rooms)
    //       .flat()
    //       ?.map((r) => ({
    //         name: r?.name,
    //         key: r?.key,
    //         cardData: !isEmpty(r?.cardData)
    //           ? deserialize1024(r?.cardData)?.[0]
    //               ?.map((i) =>
    //                 i.toString(16)?.length === 1
    //                   ? `0${i.toString(16)}`
    //                   : i.toString(16),
    //               )
    //               ?.join('')
    //           : null,
    //       }));

    //     rooms = rooms?.map((r) => ({
    //       ...r,
    //       cardData: !isEmpty(r?.cardData) && getUUIDFormat(r?.cardData),
    //     }));
    //   }

    //   console.log(rooms);
    return [null];
  }, [roomsWithRoomType]);

  const [customInfoThree] = useMemo(() => {
    //   roomsWithRoomType
    //     ?.map((roomType) => roomType?.rooms)
    //     .flat()
    //     .map((room) => {
    //       const key = JSON.stringify(
    //         room?.key
    //           ?.split('-')
    //           .join('')
    //           ?.match(/.{1,2}/g)
    //           ?.map((i) => parseInt(i, 16)),
    //       );
    //       const cardData = !isEmpty(room?.cardData)
    //         ? JSON.stringify(deserialize512(room?.cardData)?.[0])
    //         : '';
    //       return {
    //         name: room?.name,
    //         key: key,
    //         cardData: cardData,
    //       };
    //     }),
    return [null];
  }, []);

  const [generatedUid] = useMemo(() => {
    //   for (let i = 0; i < 16; i++) {
    //     const uuid = uuidv4();

    //     let uid = uuid
    //       .slice(0, 8)
    //       ?.match(/.{1,2}/g)
    //       ?.map((uid) => parseInt(uid, 16));
    //     const uidXor = uid[0] ^ uid[1] ^ uid[2] ^ uid[3];

    //     uid?.push(uidXor);

    //     const uidStr = uuid.slice(0, 8);
    //     const uidXorStr =
    //       uidXor?.toString(16).length === 1
    //         ? `0${uidXor?.toString(16)}`
    //         : uidXor?.toString(16);

    //     console.log(uuid.slice(0, 13), `${uidStr}-${uidXorStr}`, uid);
    //   }
    return [null];
  }, []);

  const createRoom = async ({
    name,
    number = 1,
    encryptionKey = 'null',
    defaultSleeps = 2,
    maxSleeps = 2,
    amenities = [],
  }: {
    name: string;
    number?: string | number;
    encryptionKey?: string;
    defaultSleeps?: number;
    maxSleeps?: number;
    amenities?: any[];
  }) => {
    return await emitCreateRoom({
      variables: {
        input: {
          accommodationId,
          roomTypeId,
          kioskId,
          name,
          number,
          encryptionKey,
          defaultSleeps,
          maxSleeps,
          amenities,
        },
      },
    });
  };

  const updateRoom = async ({
    id,
    name,
    key,
    encryptionKey,
    number,
    order,
    cardData,
  }: {
    id: string;
    name?: string;
    key?: string;
    encryptionKey?: string;
    number?: number | string;
    order?: string;
    cardData?: string;
  }) => {
    return await emitUpdateRoom({
      variables: {
        input: {
          id,
          ...(name && { name }),
          ...(key && { key }),
          ...(encryptionKey && { encryptionKey }),
          ...(number && typeof number === 'string'
            ? { number: parseInt(number, 10) }
            : { number }),
          ...(order && { order }),
          ...(cardData && { cardData }),
        },
      },
    });
  };

  const deleteRoom = async ({ id }: { id: string }) => {
    return await emitDeleteRoom({
      variables: {
        id,
      },
    });
  };

  const onClickCreateRoomAToB = async () => {
    let roomArr: any[] = [];

    for (let i = roomInfo?.startNumber; i <= roomInfo?.endNumber; i++) {
      roomArr = [...roomArr, { name: `${i}호` }];
    }

    roomArr = roomArr.map((room, idx) => ({
      ...room,
      number: idx + 1,
    }));

    await roomArr.reduce(async (roomPromise, room) => {
      await roomPromise;

      await createRoom({
        name: room.name,
        number: room.number,
      });
    }, Promise.resolve());
  };

  const onClickSortRcuId = async (type: string) => {
    const rooms = Object?.keys(roomsWithFloor)?.map((key) => {
      const sortedRoomsInFloor = roomsWithFloor[key]?.sort(
        (a, b) =>
          parseInt(a?.name?.replace(/[^0-9]/g, '')) -
          parseInt(b?.name?.replace(/[^0-9]/g, '')),
      );

      return sortedRoomsInFloor;
    });

    const sortedRooms = rooms.flat().map((room, roomIndex) => ({
      id: room?.id,
      name: room?.name,
      number: Number(type) + roomIndex,
    }));

    let result = false;

    try {
      await sortedRooms.reduce(async (promise, room, idx, arr) => {
        await promise;

        try {
          await updateRoom({
            id: room?.id,
            number: room?.number,
          });

          console.log('room changing complete!');

          if (idx === arr.length - 1) {
            result = true;
          }
        } catch (err) {
          console.error(err);
        }
      }, Promise.resolve());

      if (result) onSuccess();
    } catch (err) {
      onError();
    }
  };

  const onClickSortRcuIdAToB = async () => {
    console.log('Between Start!');

    console.log('ROOM INFO', roomInfo);

    const rooms = Object?.keys(roomsWithFloor)
      ?.map((key) => {
        const sortedRoomsInFloor = roomsWithFloor[key]?.sort(
          (a, b) =>
            parseInt(a?.name?.replace(/[^0-9]/g, '')) -
            parseInt(b?.name?.replace(/[^0-9]/g, '')),
        );

        return sortedRoomsInFloor;
      })
      ?.flat();

    const firstIndex = rooms?.findIndex(
      (value) => value?.id === roomInfo?.startRoomId,
    );

    const lastIndex = rooms?.findIndex(
      (value) => value?.id === roomInfo?.lastRoomId,
    );

    const filteredRooms = rooms?.slice(firstIndex, lastIndex + 1);

    let rcuIds: number[] = [];
    let rcuId = Number(roomInfo?.startRCUId);
    let rcuLastId = Number(roomInfo?.lastRCUId);

    for (rcuId; rcuId <= rcuLastId; rcuId++) {
      rcuIds.push(rcuId);
    }

    console.log('RCU IDS', rcuIds);

    try {
      console.log('start!');

      let result = false;

      await filteredRooms.reduce(async (promise, room, idx, arr) => {
        await promise;

        console.log(room?.name, 'room changing...');

        try {
          await updateRoom({
            id: room?.id,
            number: rcuIds[idx],
          });

          console.log('room changing complete!');

          if (idx === arr.length - 1) {
            result = true;
          }
        } catch (e) {
          console.error(e);
        }
      }, Promise.resolve());

      if (result) onSuccess();
    } catch (err) {
      console.error(err);
      onError();
    }

    console.log(roomInfo?.startRCUId, roomInfo?.lastRCUId);
    console.log('ALL ROOMS', rooms);
    console.log('FILTERED ROOMS', filteredRooms);
  };

  const onClickSyncKeyAndCardDataAll = async () => {
    const rooms = roomsWithRoomType
      ?.map((roomType) => roomType?.rooms)
      .flat()
      .map((room) => {
        let readAllCardData = isSerializedData(room?.cardData)
          ? deserialize1024(room?.cardData)
          : null;

        let readSectorZero = isSerializedData(room?.cardData)
          ? deserialize1024(room?.cardData)?.[0]
          : null;

        let readToStr = !isEmpty(readSectorZero)
          ? readSectorZero
              ?.map((i) =>
                i.toString(16).length === 1
                  ? `0${i.toString(16)}`
                  : i.toString(16),
              )
              .join('')
          : null;

        // prettier-ignore
        const strToUUID = !isEmpty(readToStr)
        ? `${readToStr?.slice(0, 8)}-${readToStr?.slice(8, 12)}-${readToStr?.slice(12, 16)}-${readToStr?.slice(16, 20)}-${readToStr?.slice(20, 32)}$full`
          : null;

        // console.log('====================================');
        // console.log('읽은 카드 데이터 Block 0', readSectorZero);
        // console.log('읽은 데이터 : ', readToStr);
        // console.log('읽은 데이터 To UUID : ', strToUUID);

        let uuid = uuidv4();

        // console.log('새로운 데이터 : ', uuid);

        const zeroToThree =
          uuid
            ?.split('-')
            ?.join('')
            .match(/.{1,2}/g)
            ?.map((i) => parseInt(i, 16))
            .slice(0, 4) || [];

        const slicedUUIDNumber = [
          ...zeroToThree,
          zeroToThree[0] ^ zeroToThree[1] ^ zeroToThree[2] ^ zeroToThree[3],
        ];

        let conNumToStr = slicedUUIDNumber?.map((i) =>
          i.toString(16).length === 1 ? `0${i.toString(16)}` : i.toString(16),
        );

        let slicedUUIDStr = `${conNumToStr[0]}${conNumToStr[1]}${conNumToStr[2]}${conNumToStr[3]}-${conNumToStr[4]}`;

        const resultUUIDNum = slicedUUIDNumber.concat(readSectorZero?.slice(5));

        // console.log(
        //   'UUID Number : ',
        //   slicedUUIDNumber.concat(readSectorZero?.slice(5)),
        // );

        const resultUUIDStr =
          !isEmpty(strToUUID) && slicedUUIDStr + strToUUID?.slice(11);

        // console.log('UUID String : ', resultUUIDStr);

        // console.log('읽은 모든 카드 데이터 : ', readAllCardData);

        let resultData = readAllCardData;

        if (!isEmpty(readAllCardData)) {
          resultData[0] = resultUUIDNum;
        }

        // console.log(resultData);

        return {
          id: room?.id,
          name: room?.name,
          key: !isEmpty(resultUUIDStr) ? resultUUIDStr : null,
          cardData: !isEmpty(resultData) ? serialize1024(resultData) : null,
        };
      });

    await rooms.reduce(async (promise, room) => {
      await promise;

      if (!isEmpty(room?.key) && !isEmpty(room?.cardData)) {
        console.log(room?.name, 'Update Start.');

        const result = await updateRoom({
          id: room?.id,
          key: room?.key,
          cardData: room?.cardData,
        });

        console.log(result);

        console.log(room?.name, 'Update Complete');
      }
    }, Promise.resolve());

    console.log(rooms);
  };

  const onClickSyncKeyAndCardDataOne = async ({
    roomInfo,
  }: {
    roomInfo: any;
  }) => {
    const { id, name, key, cardData } = roomInfo;

    const cardDataToHex = numArrToHexStrArr(JSON.parse(cardData)?.[0]);

    const HexToKey = hexStrArrToUUID(cardDataToHex);

    const result = await updateRoom({
      id: id,
      key: HexToKey,
    });

    if (result) onSuccess();
  };

  const onClickConvertRcuIdToDoorLockId = async () => {
    const rooms = Object?.keys(roomsWithFloor)?.map((key) => {
      const sortedRoomsInFloor = roomsWithFloor[key]?.sort(
        (a, b) =>
          parseInt(a?.name?.replace(/[^0-9]/g, '')) -
          parseInt(b?.name?.replace(/[^0-9]/g, '')),
      );

      return sortedRoomsInFloor;
    });

    const sortedRooms = rooms.flat().map((room, roomIdx) => ({
      id: room?.id,
      name: room?.name,
      number: room?.number,
      order: `${room?.number}$${extractFloor(room?.name)}`,
    }));

    let result = false;

    try {
      await sortedRooms.reduce(async (promise, room, idx, arr) => {
        await promise;

        try {
          await updateRoom({
            id: room?.id,
            order: room?.order,
          });

          if (idx === arr.length - 1) {
            result = true;
          }
        } catch (err) {
          console.error(err);
        }
      }, Promise.resolve());

      if (result) onSuccess();
    } catch (err) {
      onError();
    }
  };

  const onClickMatchRoomWithRcuId = async () => {
    const rooms = Object?.keys(roomsWithFloor)?.map((key) => {
      const sortedRoomsInFloor = roomsWithFloor[key]?.sort(
        (a, b) =>
          parseInt(a?.name?.replace(/[^0-9]/g, '')) -
          parseInt(b?.name?.replace(/[^0-9]/g, '')),
      );

      return sortedRoomsInFloor;
    });

    const sortedRooms = rooms.flat().map((room) => ({
      id: room?.id,
      name: room?.name,
      number: !isNaN(parseInt(room?.name?.replace(/[^0-9]/g, '')))
        ? parseInt(room?.name?.replace(/[^0-9]/g, ''), 10)
        : 0,
    }));

    let result = false;

    try {
      await sortedRooms.reduce(async (promise, room, idx, arr) => {
        await promise;

        try {
          await updateRoom({
            id: room?.id,
            number: room?.number,
          });
          console.log('room changing complete!');
          if (idx === arr.length - 1) {
            result = true;
          }
        } catch (err) {
          console.error(err);
        }
      }, Promise.resolve());

      onSuccess();
    } catch (err) {
      onError();
    }
  };

  const downloadTextFile = (fileName = '', data) => {
    const el = document.createElement('a');
    const file = new Blob(data, {
      type: 'text/plain',
    });
    el.href = URL.createObjectURL(file);
    el.download = `${fileName}.txt`;
    document.body.appendChild(el);
    el.click();
  };

  const onClickDownloadEncryptionKeys = () => {
    const allRoomsEncryptionKeys: any[] = roomsWithRoomType
      ?.map((roomType) => roomType?.rooms)
      ?.flat()
      ?.map((room) =>
        room?.encryptionKey
          ?.split(',')
          ?.map((key) =>
            Number(key).toString(16).length > 1
              ? Number(key).toString(16).toUpperCase()
              : `0${Number(key).toString(16).toUpperCase()}`,
          )
          ?.join(''),
      );

    const filteredKeys = allRoomsEncryptionKeys
      ?.filter((key) => key && key?.length > 6)
      ?.map((key) => `${key}\n`);

    downloadTextFile(`${accommodation?.name}`, filteredKeys);
  };

  const onClickDownloadRooms = async () => {
    const allRoomType: RoomType[] = roomsWithRoomType;

    const allRooms = allRoomType
      ?.map((roomType) => roomType?.rooms)
      ?.flat()
      ?.filter((room) => !!room?.cardData)
      ?.map((room) => {
        return {
          name: room?.name,
          cardData: isSerializedData(room?.cardData)
            ? deserialize1024(room?.cardData)
            : [],
        };
      });

    console.log(allRooms);

    const decArrToHexStr = (decArr: number[]) => {
      let decs = '';

      decArr.forEach((dec) => {
        decs +=
          dec?.toString(16)?.length === 1
            ? `0${dec?.toString(16)?.toUpperCase()}`
            : dec?.toString(16)?.toUpperCase();
      });

      return decs;
    };

    allRooms.reduce(async (promise, room) => {
      await promise;

      let concatCardData = ``;

      room?.cardData?.map((data: number[], idx: number) => {
        // Array
        // concatCardData += `[${data}],\n`;

        // MCT
        if (idx % 4 === 0) {
          concatCardData += `+sector: ${idx / 4}\n`;
        }
        concatCardData += `${decArrToHexStr(data)}\n`;
      });

      downloadTextFile(`${room?.name}`, [concatCardData]);

      await wait(150);
    }, Promise.resolve());
  };

  const onClickSaveModifyRoom = async () => {
    let cardDataArr: any;
    let serializedCardData;
    let updateCardData;

    let key = roomInfo?.key;
    let encryptionKey = roomInfo?.encryptionKey;

    console.log('Update Room Data Start.');
    console.log(roomInfo?.cardDataType, 'Data Found.');
    console.log(roomInfo?.cardData);

    if (roomInfo?.cardDataType === 'Legacy') {
      cardDataArr = roomInfo?.cardData;

      console.log('Card Data Type : ', roomInfo?.cardDataType);
      console.log('Legacy Data : ', cardDataArr);

      updateCardData = cardDataArr;
    }

    if (roomInfo?.cardDataType === 'MCT') {
      cardDataArr = roomInfo?.cardData
        ?.replace(/(\+Sector: [0-9][0-9]|\+Sector: [0-9])/gi, '')
        ?.split('\n')
        ?.filter((val) => val !== '')
        ?.map((i) => i?.match(/.{1,2}/g)?.map((j) => parseInt(j, 16)));

      const filterRoomKey = cardDataArr?.[0]
        ?.map((i) =>
          i.toString(16).length === 1
            ? `0${i.toString(16).toLowerCase()}`
            : i.toString(16)?.toLowerCase(),
        )
        .toString()
        .replaceAll(',', '');

      console.log(filterRoomKey);

      key = getUUIDFormat(filterRoomKey) + '$full';

      updateCardData = serialize1024(cardDataArr);

      const slicedLastBlocks = cardDataArr
        ?.map((block, idx) => (Math.floor(idx % 4) === 3 ? block : null))
        ?.filter((val) => val);
      const slicedEachBlocks = slicedLastBlocks
        ?.map((block) => block?.slice(0, 6))
        ?.filter(
          (block) =>
            JSON.stringify(block) !==
            JSON.stringify([255, 255, 255, 255, 255, 255]),
        );
      const slicedEachBlocksToStr = slicedEachBlocks?.map((block) =>
        JSON.stringify(block),
      );
      const foundUniqueStr = slicedEachBlocksToStr?.filter(
        (block, idx) => slicedEachBlocksToStr.indexOf(block) === idx,
      );
      const tempStr = foundUniqueStr?.[0]?.slice(1);
      encryptionKey = tempStr?.slice(0, -1);
    }

    if (roomInfo?.cardDataType === 'HEX') {
      const originHexData = roomInfo?.cardData;
      const convertHexToStr = originHexData
        ?.replaceAll(' ', '')
        ?.match(/.{32}/g);
      const convertStrToNum = convertHexToStr?.map((block) => {
        return block?.match(/.{1,2}/g)?.map((str) => parseInt(str, 16));
      });

      const filterRoomKey = convertHexToStr?.[0]?.toLowerCase() || '';

      console.log(filterRoomKey);

      key = getUUIDFormat(filterRoomKey) + '$full';

      updateCardData = serialize1024(convertStrToNum);

      const slicedLastBlocks = convertStrToNum
        ?.map((block, idx) => (Math.floor(idx % 4) === 3 ? block : null))
        ?.filter((val) => val);
      const slicedEachBlocks = slicedLastBlocks
        ?.map((block) => block?.slice(0, 6))
        ?.filter(
          (block) =>
            JSON.stringify(block) !==
            JSON.stringify([255, 255, 255, 255, 255, 255]),
        );
      const slicedEachBlocksToStr = slicedEachBlocks?.map((block) =>
        JSON.stringify(block),
      );
      const foundUniqueStr = slicedEachBlocksToStr?.filter(
        (block, idx) => slicedEachBlocksToStr.indexOf(block) === idx,
      );
      const tempStr = foundUniqueStr?.[0]?.slice(1);
      encryptionKey = tempStr?.slice(0, -1) || '';
    }

    if (
      roomInfo?.cardDataType === '512' ||
      roomInfo?.cardDataType === 'DoubleArrayTo512'
    ) {
      cardDataArr = JSON.parse(roomInfo?.cardData || '');
      updateCardData = serialize512(cardDataArr);
    }

    if (
      roomInfo?.cardDataType === '1024' ||
      roomInfo?.cardDataType === 'DoubleArrayTo1K'
    ) {
      cardDataArr = JSON.parse(roomInfo?.cardData || '');
      updateCardData = serialize1024(cardDataArr);
    }

    console.log('Card Data Type : ', roomInfo?.cardDataType);
    console.log('Card Data : ', cardDataArr);
    console.log('Update Card Data : ', updateCardData);

    try {
      if (roomInfo?.name !== accommodation?.name) {
        await updateRoom({
          id: roomInfo?.id,
          name: roomInfo?.name,
          key: key,
          encryptionKey: encryptionKey || 'null',
          number: roomInfo?.number,
          order: roomInfo?.order || '',
          cardData: updateCardData,
        });
      }

      if (roomInfo?.name === accommodation?.name) {
        let customInput;

        if (roomInfo.specialType === 'master') {
          customInput = {
            id: accommodation?.id,
            cardData: updateCardData,
          };
        }
        if (roomInfo.specialType === 'clean') {
          customInput = {
            id: accommodation?.id,
            cleanCardData: updateCardData,
          };
        }
        await updateAccommodation(customInput);
      }
      onSuccess();
    } catch (err) {
      onError();
    }
  };

  const decodeCardData = (cardData: any) => {
    if (isLegacyCardData(cardData)) {
      // console.log('Legacy Data Found');
      // console.log(cardData);
      return { cardType: 'Legacy', cardData };
    }
    if (isSerializedData(cardData)) {
      cardData = JSON.stringify(deserialize1024(cardData));
      // console.log('Serialized Data Found.');
      // console.log(cardData);
      return { cardType: '1024', cardData };
    }
    return { cardType: 'unknown', cardData };
  };

  const onClickSyncMasterKeyCard = () => {
    let cardData = accommodation?.cardData;
    let cardDataType = decodeCardData(cardData)?.cardType;

    setRoomInfo({
      specialType: 'master',
      id: accommodation?.id,
      originName: accommodation?.name,
      name: accommodation?.name,
      originKey: accommodation?.id,
      key: accommodation?.id,
      cardDataType: cardDataType,
      originCardDataType: cardDataType,
      cardData: cardData,
      originCardData: accommodation?.cardData,
    });

    return true;
  };

  const onClickSyncCleanKeyCard = () => {
    let cardData = accommodation?.cleanCardData;
    let cardDataType = decodeCardData(cardData)?.cardType;

    setRoomInfo({
      specialType: 'clean',
      id: accommodation?.id,
      originName: accommodation?.name,
      name: accommodation?.name,
      originKey: accommodation?.id,
      key: accommodation?.id,
      cardDataType: cardDataType,
      originCardDataType: cardDataType,
      cardData: cardData,
      originCardData: accommodation?.cardData,
    });

    return true;
  };

  const splitStrToUID = (str) => {
    return `${str?.slice(0, 8)}-${str?.slice(8, 12)}-${str?.slice(
      12,
      16,
    )}-${str?.slice(16, 20)}-${str?.slice(20, 32)}`;
  };

  const generateNewUID = ({ type = 'full' }: { type: 'half' | 'full' }) => {
    let uuidStr = uuidv4();

    const splitedUUIDStr = uuidStr
      .split('-')
      .join('')
      .match(/.{1,2}/g);

    console.log(splitedUUIDStr);

    console.log(splitedUUIDStr?.map((hex) => parseInt(`0x${hex}`, 16)));

    const splitedUUIDNum =
      splitedUUIDStr?.map((hex) => parseInt(`0x${hex}`, 16)) || [];

    console.log(splitedUUIDNum);

    const xor =
      splitedUUIDNum[0] ^
      splitedUUIDNum[1] ^
      splitedUUIDNum[2] ^
      splitedUUIDNum[3];

    const combinedUuidNum = [...splitedUUIDNum?.slice(0, 4), xor];

    console.log(combinedUuidNum);

    const combinedUuidStr = combinedUuidNum
      .map((n) =>
        n.toString(16).length > 1 ? n.toString(16) : `0${n.toString(16)}`,
      )
      .join('');

    console.log(combinedUuidStr);

    const manufacturerStr = '0804006263646566676869';

    console.log(manufacturerStr);

    const resultUuid = combinedUuidStr + manufacturerStr;

    console.log(resultUuid);
    console.log(splitStrToUID(resultUuid));

    return type === 'half'
      ? splitStrToUID(resultUuid)
      : `${splitStrToUID(resultUuid)}$full`;
  };

  const onClickResetKeyCardAll = async () => {
    const rooms = roomsWithRoomType[0]?.rooms;

    console.log(generateNewUID({ type: 'full' }));

    // console.log('ALL ROOMS', rooms);

    // const reworkedRooms = rooms?.map((room: Room) => {
    //   const { id, name, key, encryptionKey, cardData } = room;

    //   return { id, name, key, encryptionKey, cardData };
    // });

    // console.log('ALL ROOMS', reworkedRooms);

    // for (const room of reworkedRooms) {
    //   await updateRoom({
    //     id: room?.id,
    //     // name: room?.name?.slice(0, -1),
    //   });
    // }
  };

  const onClickSearchDuplicateData = async () => {
    const rooms = roomsWithRoomType.map((roomType) => roomType.rooms).flat();

    const allDatas = rooms.map((room) => {
      return {
        name: room?.name,
        cardData: decodeCardData(room?.cardData).cardData,
      };
    });

    const notSyncedRooms = allDatas.filter((data) => !data.cardData);

    console.log('NOT SYNCED', notSyncedRooms);

    const syncedRooms = allDatas.filter((data) => !!data.cardData);

    console.log('SYNCED', syncedRooms);

    let groupedData = {};

    for (const item of syncedRooms) {
      const { name, cardData } = item;
      if (groupedData.hasOwnProperty(cardData)) {
        groupedData[cardData].push(name);
      } else {
        groupedData[cardData] = [name];
      }
    }

    // console.log(groupedData);

    const groupedRooms: string[][] = Object.values(groupedData);

    const duplicatedRooms = groupedRooms.filter((val) => val.length > 1);

    console.log(duplicatedRooms);

    setDuplicatedData(duplicatedRooms);
  };

  return {
    roomInfo,
    roomsWithRoomType,
    roomsWithFloor,
    duplicatedData,
    setRoomInfo,
    setDuplicatedData,
    createRoom,
    updateRoom,
    deleteRoom,
    onClickCreateRoomAToB,
    onClickSortRcuId,
    onClickSortRcuIdAToB,
    onClickSyncKeyAndCardDataAll,
    onClickSyncKeyAndCardDataOne,
    onClickConvertRcuIdToDoorLockId,
    onClickMatchRoomWithRcuId,
    onClickDownloadEncryptionKeys,
    onClickDownloadRooms,
    onClickSaveModifyRoom,
    onClickSyncMasterKeyCard,
    onClickSyncCleanKeyCard,
    onClickResetKeyCardAll,
    onClickSearchDuplicateData,
  };
};

export default useRoomControl;
