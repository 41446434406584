import { Fragment, useEffect, useState } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Flex,
  FlexCenteredCol,
  FlexCenteredRow,
  FlexCol,
  FlexRow,
} from '../../components/Layout';
import { buildAttachmentCdnSrc } from '../../commons/constants';
import styled from 'styled-components';

const RowLine = styled.div`
  border: 1px solid #eee;
  margin: 8px;
`;

const CustomFlex = styled(Flex)`
  margin: 8px 0;
  padding: 16px 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #d3d3d8;
`;

const CustomInnerFlex = styled(Flex)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomFormControl = styled(FormControl)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomTextField = styled(TextField)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const CustomSelect = styled(Select)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  background-color: #eee;
`;

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    console.log(e.target);
  };
  return { value, onChange };
};

const KioskHomeArticles = ({
  accommodation,
  homeInputValues,
  handleHomeChange,
  handleImageChange,
  kioskArticle,
  createKioskHomeArticle,
  updateKioskHomeArticle,
}: {
  accommodation: any;
  homeInputValues: any;
  handleHomeChange: (prop: any) => (event: any) => void;
  handleImageChange: (event: any) => void;
  kioskArticle: any;
  createKioskHomeArticle: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  updateKioskHomeArticle: () => Promise<void>;
}) => {
  const { id: homeId, attachments: homeAttachments } =
    !!kioskArticle && kioskArticle;

  return (
    <Fragment>
      <Typography
        variant="h5"
        style={{ height: '48px', margin: '24px 0 0 32px' }}
      >
        홈 화면 설정
      </Typography>

      {!kioskArticle ? (
        <FlexCenteredCol
          style={{
            alignItems: 'center',
            height: '200px',
          }}
        >
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            홈 화면 설정이 없습니다.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: '96px',
              height: '40px',
              marginTop: '64px',
            }}
            onClick={async () => {
              await createKioskHomeArticle({
                variables: {
                  accommodationId: accommodation?.id,
                },
              });
            }}
          >
            <Typography>+ 생성</Typography>
          </Button>
        </FlexCenteredCol>
      ) : (
        <>
          <CustomFlex
            style={{
              flexDirection: 'column',
            }}
          >
            <FlexRow style={{ width: '100%' }}>
              <FlexCol style={{ flex: 1, marginRight: '16px' }}>
                <Typography>홈 화면 상단 문구</Typography>
                <FormControl>
                  <CustomTextField
                    size="small"
                    value={homeInputValues.mainTitle || ''}
                    onChange={handleHomeChange('mainTitle')}
                    variant="outlined"
                  />
                </FormControl>
              </FlexCol>

              <FlexCol style={{ flex: 1 }}>
                <Typography>홈 화면 하단 문구</Typography>
                <FormControl>
                  <CustomTextField
                    size="small"
                    value={homeInputValues.subTitle || ''}
                    onChange={handleHomeChange('subTitle')}
                    variant="outlined"
                  />
                </FormControl>
              </FlexCol>
            </FlexRow>

            <FlexCol style={{ marginTop: '20px' }}>
              <Typography>제목 1 폰트 크기</Typography>
              <FlexRow>
                <FormControl style={{ width: '100%' }}>
                  <CustomTextField
                    size="small"
                    value={
                      homeInputValues?.content?.['mainTitleSize'] ||
                      homeInputValues?.mainTitleSize
                    }
                    onChange={handleHomeChange('mainTitleSize')}
                    variant="outlined"
                  />
                </FormControl>
              </FlexRow>
            </FlexCol>

            <FlexCol style={{ marginTop: '20px' }}>
              <Typography>제목 2 폰트 크기</Typography>
              <FlexRow>
                <FormControl style={{ width: '100%' }}>
                  <CustomTextField
                    size="small"
                    value={
                      homeInputValues?.content?.['subTitleSize'] ||
                      homeInputValues?.subTitleSize
                    }
                    onChange={handleHomeChange('subTitleSize')}
                    variant="outlined"
                  />
                </FormControl>
              </FlexRow>
            </FlexCol>

            <FlexCol style={{ marginTop: '20px' }}>
              <Typography>
                홈 화면 사진 목록 ( 27 : 17 | 1080 * 680 에 최적화 )
              </Typography>
              <FlexCenteredRow
                style={{
                  marginTop: '1vh',
                  padding: '24px',
                }}
              >
                <img
                  src={buildAttachmentCdnSrc(homeId, homeAttachments)}
                  width="33%"
                  alt="home-layout"
                />
              </FlexCenteredRow>
              <FlexCenteredRow style={{ width: '100%', marginTop: '16px' }}>
                <input type="file" onChange={handleImageChange} />
              </FlexCenteredRow>
            </FlexCol>
          </CustomFlex>

          <FlexCenteredRow style={{ alignItems: 'center', height: '96px' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '184px', height: '56px' }}
              onClick={updateKioskHomeArticle}
            >
              <Typography>홈 화면 변경</Typography>
            </Button>
          </FlexCenteredRow>
        </>
      )}
    </Fragment>
  );
};

export default KioskHomeArticles;
