import { useQuery } from '@apollo/client';
import {
  ListObjectsCommand,
  ListObjectsCommandOutput,
} from '@aws-sdk/client-s3';
import React, { useEffect, useState } from 'react';
import {
  FlexCenteredCol,
  FlexCol,
  FlexRow,
  FlexSpaceBetweenCol,
  WrapperContainer,
} from '../../components/Layout';
import Loading from '../../components/Loading';
import SessionOut from '../../components/SessionOut';
import { GET_ALL_ACCOMMODATION_KIOSKS } from '../../graphql/query';
import client from '../../helpers/S3Client';
import {
  Accommodation,
  AccommodationConnection,
  Kiosk,
  Maybe,
} from '../../typedefs/gql';
import { AccommodationItem } from './components/AccommodationItem';
import KioskLists from './components/KioskLists';
import LogLists from './components/LogLists';

type Props = {};

const LogDownload = (props: Props) => {
  const [state, setState] = useState<ListObjectsCommandOutput>();

  const { loading, data, error, refetch } = useQuery<{
    getAccommodations: AccommodationConnection;
  }>(GET_ALL_ACCOMMODATION_KIOSKS);

  const [selected, setSelected] = useState<Maybe<string>>(null);

  const [selectedKiosk, setSelectedKiosk] = useState<Maybe<Kiosk>>(null);

  const listObjectsCommand = new ListObjectsCommand({
    Bucket: 'vendit-kiosk-logs',
    Delimiter: '/',
  });

  const onMount = async () => {
    const response = await client.send(listObjectsCommand);
    if (response) {
      setState(response);
    }
  };

  const onClick = (id?: Maybe<string>) => {
    setSelected(id || null);
  };
  const onKioskClick = (kiosk?: Maybe<Kiosk>) => {
    setSelectedKiosk(kiosk || null);
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    setSelectedKiosk(null);
  }, [selected]);
  return (
    <WrapperContainer>
      {error ? (
        <SessionOut />
      ) : loading ? (
        <Loading />
      ) : (
        <FlexRow style={{ height: 'inherit' }}>
          <FlexCol style={{ overflow: 'scroll' }}>
            {state?.CommonPrefixes?.map((accommodationId) => {
              const accommodation = data?.getAccommodations?.edges?.find(
                (acc) => acc?.node?.id + '/' === accommodationId?.Prefix,
              )?.node;
              return (
                <AccommodationItem
                  accommodation={accommodation}
                  selected={selected === accommodation?.id}
                  key={accommodationId?.Prefix}
                  onClick={onClick}
                />
              );
            })}
          </FlexCol>
          <FlexCol style={{ overflow: 'scroll' }}>
            <KioskLists
              selectedId={selected}
              selectedKioskId={selectedKiosk?.id}
              kiosks={
                data?.getAccommodations?.edges?.find(
                  (acc) => acc?.node?.id === selected,
                )?.node?.kiosks
              }
              onKioskClick={onKioskClick}
            />
          </FlexCol>
          <FlexCol style={{ overflow: 'scroll' }}>
            <LogLists
              bucketPrefix={
                selectedKiosk?.id
                  ? `${selected}/${selectedKiosk?.id}`
                  : undefined
              }
            />
          </FlexCol>
        </FlexRow>
      )}
    </WrapperContainer>
  );
};

export default LogDownload;
