import jwt from 'jsonwebtoken';
import { FetchResult } from 'apollo-fetch';
import { CONSTANTS } from '../commons/constants';
import moment from 'moment';
import { apolloFetch } from './api';

type VenditUserData = {
  id: string;
  identity: string;
  phone?: string;
  sessionHash: string;
  name: string;
  nickname: string;
  email: string;
  thumbnail?: string;
  accessLevel: string;
  isOtpLocked: boolean;
  isAuthorizedPhone: boolean;
  isAuthorizedEmail: boolean;
  revision: number;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  iat: number;
  exp: number;
};

export class AuthHandler {
  selectedStorage: Storage | undefined;

  user: any | null = null;

  __sessionCache__signedIn: boolean | undefined;

  constructor() {
    const savedAccessToken = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);

    if (savedAccessToken) {
      this.selectedStorage = localStorage;
      this.user = jwt.decode(savedAccessToken);
    } else {
      const savedSessionStorageToken = sessionStorage.getItem(
        CONSTANTS.ACCESS_TOKEN,
      );
      if (savedSessionStorageToken) {
        this.selectedStorage = sessionStorage;
        this.user = jwt.decode(savedSessionStorageToken);
      }
    }
  }

  get refreshToken() {
    if (!this.selectedStorage) return null;
    return this.selectedStorage.getItem(CONSTANTS.REFRESH_TOKEN);
  }

  get accessToken() {
    if (!this.selectedStorage) return null;
    return this.selectedStorage.getItem(CONSTANTS.ACCESS_TOKEN);
  }

  // openOtpPopup = () => window.requestOTPAuthenticate?.();

  // validateAccessToken = async () => {};

  signIn = (accessToken, refreshToken, usePermenant) => {
    this.__sessionCache__signedIn = true;
    this.user = jwt.decode(accessToken);
    this.selectedStorage = (usePermenant && localStorage) || sessionStorage;

    if (this.selectedStorage) {
      this.selectedStorage.setItem(CONSTANTS.ACCESS_TOKEN, accessToken);

      localStorage.setItem('lastTime', moment().toISOString());
      localStorage.setItem('expireAt', `${60 * 30}`);

      if (refreshToken) {
        this.selectedStorage.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
      }
    }
  };

  signOut = () => {
    this.selectedStorage?.removeItem(CONSTANTS.ACCESS_TOKEN);
    this.selectedStorage?.removeItem(CONSTANTS.REFRESH_TOKEN);
    this.user = null;
    window.location.href = CONSTANTS.PATH.SIGN_IN;
    window.location.reload();
  };

  anonymousSignIn = async (token?: string) => {
    try {
      this.selectedStorage = sessionStorage;
      const refreshResult = await apolloFetch({
        query: `
          query($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
              accessToken
              refreshToken
            }
          }
        `,
        variables: {
          refreshToken: token,
        },
      });

      const {
        data: {
          refreshToken: { accessToken, refreshToken },
        },
      } = refreshResult;

      this.selectedStorage.setItem(CONSTANTS.ACCESS_TOKEN, accessToken);
      this.selectedStorage.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);

      return accessToken;
    } catch (error) {
      console.log(error);
    }

    return true;
  };

  fetchNewAccessToken = async (): Promise<any> => {
    const result: FetchResult = await apolloFetch({
      query: `
        query($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            accessToken
            refreshToken
          }
        }
      `,
      variables: {
        refreshToken: this.refreshToken,
      },
    });

    return result;
  };

  refreshAccessToken = async (token?: string, refreshTokenString?: string) => {
    if (refreshTokenString) {
      this.selectedStorage?.setItem(
        CONSTANTS.REFRESH_TOKEN,
        refreshTokenString,
      );
    }
    if (token) {
      this.selectedStorage?.setItem(CONSTANTS.ACCESS_TOKEN, token);
      this.user = jwt.decode(token);
      return token;
    }

    try {
      const response = await this.fetchNewAccessToken();
      const {
        refreshToken: { accessToken, refreshToken },
      } = response.data;

      this.selectedStorage?.setItem(CONSTANTS.ACCESS_TOKEN, accessToken);
      this.selectedStorage?.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
      this.user = jwt.decode(accessToken);

      return accessToken;
    } catch (error) {
      console.error('[ERR]refreshAccessToken:', error);

      if (this.accessToken) {
        this.signOut();
      }
    }

    return true;
  };

  static validateToken = (minutesBeforeExpiry: number = 10) => {
    const { user } = authHandler;
    const now = Math.floor(Date.now() / 1000);

    if (user && user.exp > now) {
      const timeUntilExpiry = user.exp - now;
      const minutesUntilExpiry = Math.floor(timeUntilExpiry / 60);

      if (minutesUntilExpiry <= minutesBeforeExpiry && minutesUntilExpiry > 0) {
        console.log(`Token will expire in ${minutesUntilExpiry} minutes.`);
        return false;
      }
      return true;
    }

    if (user && user.exp <= now) {
      console.log('Token has expired.');
    } else {
      console.log('User not authenticated or token information missing.');
    }

    return false;
  };
}

const authHandler = new AuthHandler();

window.authHandler = authHandler;

window.CONSTANTS = CONSTANTS;

export default authHandler;
