import { Typography } from '@material-ui/core';
import { FlexCenteredCol } from './Layout';

const SessionOut = () => {
  return (
    <FlexCenteredCol
      style={{
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#ffffff',
        zIndex: 998,
      }}
    >
      <Typography variant="h4">세션이 종료되었습니다.</Typography>
      <Typography
        variant="h4"
        style={{ marginTop: '16px', marginBottom: '96px' }}
      >
        로그아웃 하신 후 재접속하세요.
      </Typography>
    </FlexCenteredCol>
  );
};

export default SessionOut;
