import { Button, Input } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import React from 'react';
import { FlexRow } from './Layout';

const Search = ({ onSubmit }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event.target.elements.filter.value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <FlexRow style={{ alignItems: 'center' }}>
        <Input
          endAdornment={<SearchOutlined />}
          name="filter"
          style={{
            minWidth: '240px',
            height: '40px',
            borderRadius: '4px',
            fontSize: '1.2rem',
            padding: '0 8px',
          }}
        />
        {/* <Button
          color="default"
          variant="outlined"
          style={{
            width: '120px',
            height: '48px',
            margin: '0 0 0 4px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
          }}
        >
          <span style={{ textAlign: 'center', fontSize: '1.2rem' }}>검색</span>
        </Button> */}
      </FlexRow>
    </form>
  );
};

export default Search;
