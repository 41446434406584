import { Typography } from '@material-ui/core';
import React from 'react';
import { FlexCenteredCol, FlexCol, FlexRow } from '../components/Layout';

const NotFound = () => {
  return (
    <FlexCol
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 0 32px 0',
        }}
      >
        페이지를 찾을 수 없습니다
      </Typography>
    </FlexCol>
  );
};

export default NotFound;
