import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { API_HOST } from '../commons/constants';
import authHandler, { AuthHandler } from './authHandler';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

type T = any;

let apolloClient: ApolloClient<T>;

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (apolloClient && graphQLErrors?.[0].message === 'jwt expired') {
    return fromPromise(authHandler.refreshAccessToken())
      .filter((value) => Boolean(value))
      .flatMap(() => forward(operation));
  }
});

const refreshLink = new TokenRefreshLink<{
  accessToken: string;
  refreshToken: string;
}>({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: async (operation) => AuthHandler.validateToken(),
  fetchAccessToken: async () => authHandler.fetchNewAccessToken(),
  handleFetch: (tokens) => {
    const { accessToken, refreshToken } = tokens;
    authHandler.refreshAccessToken(accessToken, refreshToken);
  },
  handleError: (err, operation) => {},
  handleResponse: (res) => {},
});

const authLink = new ApolloLink((operation, forward) => {
  const token = authHandler.accessToken;

  operation.setContext(({ headers }) => ({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
  }));

  return forward(operation);
});

const httpLink = createHttpLink({
  uri: `${API_HOST}/graphql`,
  credentials: 'include',
});

const uploadLink = createUploadLink({
  uri: `${API_HOST}/graphql`,
});

const additiveLink = from([refreshLink, authLink, uploadLink, httpLink]);

apolloClient = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
});

export { apolloClient as client };
