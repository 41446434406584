//@ts-nocheck

export const isEmpty = (val: any) => {
  if (
    val === '' ||
    val === null ||
    val === undefined ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  ) {
    return true;
  }
  return false;
};

export const serialize512 = (originData: number[][]): string =>
  originData
    .map((chunk) =>
      chunk.reduce(
        (acc, cur) =>
          acc[acc.length - 1].length < 2
            ? [...acc.slice(0, acc.length - 1), [...acc[acc.length - 1], cur]]
            : [...acc, [cur]],
        [[]],
      ),
    )
    .reduce((acc, chunk) => [...acc, ...chunk], [])
    .map((mb2) =>
      mb2.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), ''),
    )
    .map((mb2) => String.fromCharCode(parseInt(mb2, 16)))
    .join('');

export const deserialize512 = (serialized) =>
  serialized.match(/(.|[\r\n]){1,8}/g).map((chunk) =>
    chunk
      .split('')
      .map((mb2) =>
        mb2
          .charCodeAt(0)
          .toString(16)
          .padStart(4, '0')
          .match(/.{1,2}/g)
          .map((byte) => parseInt(byte, 16)),
      )
      .reduce((acc: any, cur: any) => [...acc, ...cur], []),
  );

export const serialize1024 = (originData) =>
  originData
    .map((arr) =>
      arr
        .map((binary) => String.fromCharCode(binary))
        .reduce((acc, cur) => acc + cur, ''),
    )
    .reduce((acc: any, cur: any) => acc + cur, '');

export const deserialize1024 = (serializedData) =>
  serializedData
    .match(/(.|[\r\n]){1,16}/g)
    .map((chunk) => chunk.split('').map((char) => char.charCodeAt(0)));

export const isLegacyCardData = (cardData) => {
  return !isEmpty(cardData) && cardData.includes('block') ? true : false;
};

export const isSerializedData = (cardData) => {
  return !isEmpty(cardData) && !isLegacyCardData(cardData) ? true : false;
};

export const getUUIDFormat = (str: string) => {
  return `${str.slice(0, 8)}-${str.slice(8, 12)}-${str.slice(
    12,
    16,
  )}-${str.slice(16, 20)}-${str.slice(20)}`;
};

export const convertKioskConfig = (config: any) => {
  switch (config) {
    case 'addonSleepsMaximum':
      return '최대숙박인원';
    case 'autoRebootInterval':
      return '자동 재부팅 주기';
    case 'autoRebootTime':
      return '자동 재부팅 시간';
    case 'cardReaderComPort':
      return 'IC 카드 리더기 포트';
    case 'cardType':
      return '카드 키 발급 타입';
    case 'cashInjectorPort':
      return '현급 투입기 포트';
    case 'ccuComPort':
      return 'CCU 포트 | IP';
    case 'ccuType':
      return 'CCU 타입';
    case 'collectContactStrategy':
      return '연락처 수집 정책';
    case 'firstCashDispensorAmount':
      return '1번 현금 방출기 금액';
    case 'firstCashDispensorPort':
      return '1번 현금 방출기 포트';
    case 'firstUpperCameraDeviceId':
      return '메인 카메라 ID';
    case 'icCardReaderNumber':
      return 'VAN 단말기 번호';
    case 'thermalPrinterComPort':
      return '영수증 프린터 포트';
    case 'idleMusic':
      return '음악 재생';
    case 'ioBoardComPort':
      return 'I / O 보드 포트';
    case 'mainMenuHeading':
      return '메인 화면 제목';
    case 'mainMenuLayout':
      return '메인 화면 버튼 구성';
    case 'printReceiptStrategy':
      return '영수증 출력';
    case 'qrCameraDeviceId':
      return 'QR 카메라 ID';
    case 'rfidWriterComPort':
      return '카드 방출기 포트';
    case 'secondCashDispensorAmount':
      return '2번 현금 방출기 금액';
    case 'secondCashDispensorPort':
      return '2번 현금 방출기 포트';
    case 'secondUpperCameraDeviceId':
      return '서브 카메라 ID';
    case 'theme':
      return '테마';
    case 'useBlockMinor':
      return '성인 인증';
    case 'useCheckPassport':
      return '여권 인증';
    case 'useCheckVehicle':
      return '차량 번호 수집';
    case 'useDetectGhostClean':
      return '유령 청소 탐지';
    case 'useExtendReservationExpireAt':
      return '예약 시간 연장';
    case 'useLateCheckout':
      return '레이트 체크아웃 사용';
    case 'usePreventAutoClean':
      return '자동 청소 방지';
    case 'useRoomNumberConfirm':
      return '객실 번호 확인 옵션';
    case 'kakaoAPIKey':
      return '카카오 API 키';
    case 'maxKeyCardAmount':
      return '카드 키 최대 발급 갯수';
    case 'useAuthCancel':
      return '성인 인증 취소 활성화';
    case 'collectSleepsStrategy':
      return '투숙 인원 수집 정책';
    case 'restrictBlockAddresses':
      return '발급 제한할 섹터 및 블록 선택';
    case 'keyCardServerAddress':
      return '카드 키 발급 서버 주소';
    case 'keyCardEncoder':
      return '카드 키 발급 엔코더';
    case 'billDispenserStrategy':
      return '현금 방출기 사용 정책';
    case 'icCardReaderType':
      return 'IC 카드 리더기 타입';
    case 'qrReaderType':
      return 'QR 리더기 타입';
    case 'qrReaderComPort':
      return 'QR 리더기 포트';
    case 'idAuthenticatorType':
      return '신분증 식별기 타입';
    case 'onSiteAdditionalPayList':
      return '추가 결제';
    case 'onSiteInstantPayList':
      return '즉시 결제';
    case 'relayComPort':
      return '릴레이 포트';
    case 'relayConnectList':
      return '릴레이 연결 순서';
    case 'useUnderAgeLimit':
      return '"19세 미만 사용 금지" 문구 표시';
    default:
      return '';
  }
};

export const mctToArr = (cardData: string) =>
  JSON.stringify(
    cardData
      ?.replace(/(\+Sector: [0-9][0-9]|\+Sector: [0-9])/gi, '')
      ?.split('\n')
      ?.map((i: string) => i.replaceAll('  ', ''))
      ?.filter((val) => val !== '')
      ?.map((i) => i.match(/.{1,2}/g).map((j) => parseInt(j, 16))),
  );

export const getSearchTheMR = (cardData: string) => {
  const result = theMrCards.find(
    (theMrCardData) => mctToArr(theMrCardData) === cardData,
  );

  console.log('isSame', result);

  return result;
};

export const extractFloor = (roomName: string) => {
  const roomNumber = roomName.split(/[^0-9]/).filter((val) => val)[0];
  return roomNumber?.slice(0, roomNumber.length - 2) || 0;
};

export class Debounce {
  constructor() {
    this.debounceRejector = null;
  }

  asyncDebounce = async (timeout) => {
    if (this.debounceRejector) this.debounceRejector();

    return new Promise((resolve) => {
      this.debounceRejector = () => resolve(false);

      setTimeout(() => resolve(true), timeout);
    });
  };
}

export const destructEdges = (connectionData) =>
  connectionData?.[Object.keys(connectionData)[0]]?.edges?.map(
    (edge) => edge.node,
  ) || [];
